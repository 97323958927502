import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    editRemarks,
    fetchRepoLogs,
    fetchSingleRepo,
    setRemarksUpdated,
} from "../../redux/reducers/repo";
import { useParams } from "react-router-dom";
import Repo from "../../interfaces/repo";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
} from "../../helpers/date";
import { RepoLogs } from "../../interfaces/repo_logs";
import { Modal, Button } from "react-bootstrap";
import { User } from "../../interfaces/user";

const ViewRepo: React.FC = () => {
    let dispatch: Dispatch<any> = useDispatch();
    const { repoId } = useParams();
    let repo: Repo = useSelector((state: any) => state.repo.repo);
    let logs: RepoLogs[] = useSelector((state: any) => state.repo.logs);
    const userData: User = useSelector((state: any) => state.user.userData);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [remarks, setRemarks] = useState("");
    let remarksUpdated: boolean = useSelector(
        (state: any) => state.repo.remarksUpdated
    );

    useEffect(() => {
        dispatch(fetchSingleRepo({ _id: repoId }));
    }, [repoId]);

    useEffect(() => {
        if (repoId) {
            dispatch(fetchRepoLogs(repoId));
        }
    }, [dispatch, repoId]);

    useEffect(() => {
        if (remarksUpdated) {
            dispatch(fetchSingleRepo({ _id: repoId }));
            setRemarksUpdated(false);
            setIsOpen(false);
        }
    }, [remarksUpdated]);

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    return (
        <div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Remarks</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            className="form-control"
                            onChange={(event) => {
                                setRemarks(event.target.value);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                dispatch(
                                    editRemarks({
                                        _id: repoId,
                                        repo_remarks: remarks,
                                    })
                                );
                            }}
                        >
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="py-3 mb-4">Repo Record</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <form>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        LAN No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.lan_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Customer Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-user"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.customer_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Zone :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-map"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.zone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Region :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-map-pin"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.region}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Branch :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-smart-home"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.branch}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Portfolio :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-report-analytics"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.portfolio}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        POS :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.pos}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Other Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.other_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        RC No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-certificate"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.rc_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Chassis No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-file-certificate"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.chassis_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Engine Number :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-engine"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.engine_number}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Make :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-layers-intersect"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.make}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Model :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-star"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.model}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Year of Manufacturing :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.mfg_year?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Revised Focus Band :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.revised_focus_band}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Total EMI Collectable :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.total_emi_collectable?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        As on date DPD :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.as_on_date_dpd?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Total Gross Collection :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.total_gross_collection?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        LRN Date :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {formatDateToDDMMYYYY(
                                                    repo.lrn_date
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Sec 17/ Sec 9 available :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.sec_17_9 ? "Yes" : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Sec 138 available :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.sec_138?.length > 0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Any litigation against case available :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.arbitration_status
                                                    ?.length > 0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />

                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Repo Vehicle or Surrender Vehicle :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.type}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Via Sec 17/ Sec 9 :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.via_sec?.length > 0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4 mx-n4" />
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Uploaded LRN :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.is_lrn_upload === "y"
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <label className="col-sm-4 col-form-label"></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-4">
                                                {repo.lrn && (
                                                    <a
                                                        href={repo.lrn?.toString()}
                                                        target="_blank"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div className="badge bg-label-primary p-2 me-3 rounded">
                                                            <i className="ti ti-file-text ti-sm"></i>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                            <div className="me-2">
                                                                <h6 className="mb-0">
                                                                    LRN
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                {repo.pre_police_intimation && (
                                                    <a
                                                        href={repo.pre_police_intimation?.toString()}
                                                        target="_blank"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div className="badge bg-label-primary p-2 me-3 rounded">
                                                            <i className="ti ti-file-text ti-sm"></i>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                            <div className="me-2">
                                                                <h6 className="mb-0">
                                                                    Pre Police
                                                                    Intimation
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                {repo.post_police_intimation && (
                                                    <a
                                                        href={repo.post_police_intimation?.toString()}
                                                        target="_blank"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div className="badge bg-label-primary p-2 me-3 rounded">
                                                            <i className="ti ti-file-text ti-sm"></i>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                            <div className="me-2">
                                                                <h6 className="mb-0">
                                                                    Post Police
                                                                    Intimation
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                {repo.inventory && (
                                                    <a
                                                        href={repo.inventory?.toString()}
                                                        target="_blank"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div className="badge bg-label-primary p-2 me-3 rounded">
                                                            <i className="ti ti-file-text ti-sm"></i>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                            <div className="me-2">
                                                                <h6 className="mb-0">
                                                                    Inventory
                                                                    Upload
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}
                                            </div>
                                            <div className="col-md-4">
                                                {repo.surrender_letter && (
                                                    <a
                                                        href={repo.surrender_letter?.toString()}
                                                        target="_blank"
                                                        className="d-flex align-items-center"
                                                    >
                                                        <div className="badge bg-label-primary p-2 me-3 rounded">
                                                            <i className="ti ti-file-text ti-sm"></i>
                                                        </div>
                                                        <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                            <div className="me-2">
                                                                <h6 className="mb-0">
                                                                    Surrender
                                                                    Letter
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Date of Repo :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {formatDateToDDMMYYYY(
                                                    repo.repo_date
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Surrender Date :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {formatDateToDDMMYYYY(
                                                    repo.surrender_date
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Original RC Book Availability :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.rc_location?.length > 0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Branch or Stockyard :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.rc_location || " - "}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4 mx-n4" />
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Empanelled Yard :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.is_empanelled_yard === "y"
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Parking Yard Name:
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.empanelled_yard}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Parking Yard Address :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.parking_yard_address}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Parking Yard Code :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.parking_yard_code}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Yard Contact Person :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-user"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.yard_contact_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Yard Contact Person Contact No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-phone"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.yard_contact_number?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Yard Rent per day :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.yard_rent?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Empanelled Repo Agency :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.is_empanelled_repo_agency ===
                                                "y"
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Repo Agency Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-building"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.repo_agency_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-1">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Additional Repo Expenses If Any :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repo.is_additional_repo_expenses ===
                                                "y"
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Additon repo expenses amount :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.additional_repo_expenses?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Reasonof additional repo expenses :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {
                                                    repo.additional_expenses_reason
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Normal repo Expenses :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.normal_repo_expenses?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Total Repo Expenses :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repo.total_repo_expenses?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Remarks :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p
                                                className="mb-0 fw-normal"
                                                style={{
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word",
                                                }}
                                            >
                                                {repo.repo_remarks}
                                            </p>
                                            &nbsp;&nbsp;
                                            {userData?.role?.rights?.includes(
                                                "approve_repo"
                                            ) &&
                                                userData?.role?.name !==
                                                    "l2" && (
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                            setIsOpen(true);
                                                        }}
                                                    >
                                                        Edit remarks
                                                    </button>
                                                )}
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4 mx-n4" />
                                {repo.assigned_by && (
                                    <>
                                        <div className="row mb-3">
                                            <label
                                                className="col-sm-4 col-form-label text-sm-end text-primary"
                                                htmlFor="basic-default-name"
                                            >
                                                Assigned By :
                                            </label>
                                            <div className="col-sm-8">
                                                <div className="d-flex justify-content-start align-items-center mt-1">
                                                    <p className="mb-0 fw-normal">
                                                        {repo.assigned_by?.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                className="col-sm-4 col-form-label text-sm-end text-primary"
                                                htmlFor="basic-default-name"
                                            >
                                                Status :
                                            </label>
                                            <div className="col-sm-8">
                                                <div className="d-flex justify-content-start align-items-center mt-1">
                                                    <p className="mb-0 fw-normal">
                                                        {getStatusText(
                                                            repo.status
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                className="col-sm-4 col-form-label text-sm-end text-primary"
                                                htmlFor="basic-default-name"
                                            >
                                                Remarks :
                                            </label>
                                            <div className="col-sm-8">
                                                <div className="d-flex justify-content-start align-items-center mt-1">
                                                    <p className="mb-0 fw-normal">
                                                        {repo.remarks || "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label
                                                className="col-sm-4 col-form-label text-sm-end text-primary"
                                                htmlFor="basic-default-name"
                                            >
                                                Maker Remarks :
                                            </label>
                                            <div className="col-sm-8">
                                                <div className="d-flex justify-content-start align-items-center mt-1">
                                                    <p className="mb-0 fw-normal">
                                                        {repo.maker_remarks ||
                                                            "-"}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                    <h4 className="py-3 mb-1">Audit Trail</h4>
                    <div className="row">
                        {logs.map((log: RepoLogs) => {
                            return (
                                <div className="col-md-3 col-sm-6 col-12 mb-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <h6 className="mb-0">
                                                {formatDateToDDMMYYYYWithTime(
                                                    log.createdAt
                                                )}
                                            </h6>
                                            <p className="mb-0">
                                                {log?.uid?.name}
                                            </p>
                                            <p className="mb-0">
                                                {getStatusText(log?.status)}
                                            </p>
                                            <p className="mb-0">
                                                {log.remarks}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewRepo;
