import React, { Dispatch, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../../interfaces/user";
import { formatDateToDDMMYYYY } from "../../../../helpers/date";
import { Link, useNavigate } from "react-router-dom";
import Lan from "../../../../interfaces/lan";
import {
    exportLan,
    importBulkLan,
    searchLan,
    setLanImported,
} from "../../../../redux/reducers/legal-request-lan";
import InfiniteScroll from "react-infinite-scroll-component";
import { Modal, Button } from "react-bootstrap";
import LanMenu from "../../../../components/LanMenu";

const LegalRequestLanList: React.FC = () => {
    const userData: User = useSelector((state: any) => state.user.userData);
    const lanItems: Lan[] = useSelector(
        (state: any) => state.legalRequestLan.items
    );
    const lanImported: boolean | null = useSelector(
        (state: any) => state.legalRequestLan.imported
    );
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState("");
    const dispatch: Dispatch<any> = useDispatch();
    const navigate = useNavigate();
    const tableRef = useRef<HTMLTableElement | null>(null);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [importing, setImporting] = useState(false);
    const [lanFile, setLanFile] = useState(null);

    const fetchLanData = async () => {
        await dispatch(
            searchLan({
                search: search,
                page: page,
                type: "legal-request",
            })
        );
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        fetchLanData();
    }, [dispatch, search]);

    useEffect(() => {
        if (lanImported === true) {
            setImporting(false);
            alert("Lan Data Imported Successfully!");
            setIsOpen(false);
            window.location.reload();
        } else if (lanImported === null) {
            setImporting(false);
            dispatch(setLanImported(false));
        }
    }, [lanImported]);

    const navigateToAdd = () => {
        navigate("/master/add-legal-request-lan");
    };
    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Bulk Upload LAN</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="file"
                            accept=".csv"
                            className="form-control"
                            onChange={(event: any) => {
                                setLanFile(event.target.files[0]);
                            }}
                        />
                        <span>only .csv files supported</span>
                        <br />
                        {importing && <span>Importing...</span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setImporting(true);
                                dispatch(
                                    importBulkLan({
                                        file: lanFile,
                                        type: "legal-request",
                                    })
                                );
                            }}
                        >
                            Upload
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="card">
                <div className="card-header-elements m-4 ms-auto">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Search LAN"
                            style={{ flex: 1, marginRight: 10 }}
                            onChange={(event: any) => {
                                setSearch(event.target.value);
                                setPage(1);
                            }}
                        />
                        {userData?.role?.rights.includes(
                            "add_legal_request_lan"
                        ) && (
                            <button
                                style={{ marginRight: 5 }}
                                type="button"
                                onClick={() => {
                                    setIsOpen(true);
                                }}
                                className="btn btn-primary waves-effect waves-light"
                            >
                                <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                                Bulk Upload
                            </button>
                        )}
                        {userData?.role?.rights.includes(
                            "add_legal_request_lan"
                        ) && (
                            <button
                                type="button"
                                onClick={navigateToAdd}
                                className="btn btn-primary waves-effect waves-light"
                            >
                                <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                                Add New Entry
                            </button>
                        )}
                        &nbsp;
                        {userData?.role?.rights.includes(
                            "view_legal_request_lan"
                        ) && (
                            <button
                                style={{ marginRight: 5 }}
                                type="button"
                                onClick={() => {
                                    dispatch(exportLan());
                                }}
                                className="btn btn-primary waves-effect waves-light"
                            >
                                <span className="fa-solid fa-file-export me-2"></span>
                                Export
                            </button>
                        )}
                    </div>
                </div>
                <div className="table-responsive text-nowrap">
                    <InfiniteScroll
                        dataLength={lanItems.length}
                        next={fetchLanData}
                        hasMore={true}
                        loader={<h4></h4>}
                    >
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Lan No</th>
                                    <th>Zone</th>
                                    <th>Region</th>
                                    <th>Branch</th>
                                    <th>Customer Name</th>
                                    <th>Rc No</th>
                                    <th>Portfolio</th>
                                    <th>POS</th>
                                    <th>Other Charges</th>
                                    <th>Penal Charges</th>
                                    <th>Repo Charges</th>
                                    <th>Legal Charges</th>
                                    <th>Chassis No</th>
                                    <th>Engine Number</th>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Mfg Year</th>
                                    <th>LRN Date</th>
                                    <th>Sec 17/9</th>
                                    <th>Sec 138</th>
                                    <th>Arbitration Status</th>
                                    <th>Revised Focus Band</th>
                                    <th>Total EMI Collectable</th>
                                    <th>As on date DPD</th>
                                    <th>Total Gross Collection</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {lanItems.map((lan: Lan, index: number) => {
                                    return (
                                        <tr key={lan._id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/master/view-legal-request-lan/${lan._id}`}
                                                >
                                                    {lan.lan_no}
                                                </Link>
                                            </td>
                                            <td>{lan.zone}</td>
                                            <td>{lan.region}</td>
                                            <td>{lan.branch}</td>
                                            <td>{lan.customer_name}</td>
                                            <td>{lan.rc_no}</td>
                                            <td>{lan.portfolio}</td>
                                            <td>{lan.pos}</td>
                                            <td>{lan.other_charges}</td>
                                            <td>{lan.penal_charges}</td>
                                            <td>{lan.repo_charges}</td>
                                            <td>{lan.legal_charges}</td>
                                            <td>{lan.chassis_no}</td>
                                            <td>{lan.engine_number}</td>
                                            <td>{lan.make}</td>
                                            <td>{lan.model}</td>
                                            <td>{lan.mfg_year?.toString()}</td>
                                            <td>
                                                {formatDateToDDMMYYYY(
                                                    lan.lrn_date
                                                )}
                                            </td>
                                            <td>
                                                {formatDateToDDMMYYYY(
                                                    lan.sec_17_9 as any
                                                )}
                                            </td>
                                            <td>{lan.sec_138}</td>
                                            <td>{lan.arbitration_status}</td>
                                            <td>{lan.revised_focus_band}</td>
                                            <td>{lan.total_emi_collectable}</td>
                                            <td>{lan.as_on_date_dpd}</td>
                                            <td>
                                                {lan.total_gross_collection}
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        type="button"
                                                        className="btn p-0 dropdown-toggle hide-arrow"
                                                        data-bs-toggle="dropdown"
                                                    >
                                                        <i className="ti ti-dots-vertical"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        {userData?.role?.rights.includes(
                                                            "add_legal_request_lan"
                                                        ) && (
                                                            <Link
                                                                to={`/master/edit-legal-request-lan/${lan._id}`}
                                                                className="dropdown-item"
                                                            >
                                                                <i className="ti ti-pencil me-1"></i>{" "}
                                                                Edit
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </>
    );
};

export default LegalRequestLanList;
