import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateToDDMMYYYY } from "../../helpers/date";
import { Modal, Button } from "react-bootstrap";
import { User } from "../../interfaces/user";
import AIWISale from "../../interfaces/aiwi_sale";
import { AIWISaleLogs } from "../../interfaces/aiwi_sale_logs";
import {
    fetchAiwiSaleLogs,
    fetchSingleAiwiSale,
    updateAiwiSaleStatus,
} from "../../redux/reducers/aiwi-sale";

const ViewAIWISale: React.FC = () => {
    let dispatch: Dispatch<any> = useDispatch();
    const { aiwiSaleId } = useParams();
    const navigate = useNavigate();
    let aiwiSale: AIWISale = useSelector(
        (state: any) => state.aiwiSale.aiwiSale
    );
    const userData: User = useSelector((state: any) => state.user.userData);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [remark, setRemark] = useState("");
    let logs: AIWISaleLogs[] = useSelector((state: any) => state.aiwiSale.logs);

    useEffect(() => {
        dispatch(fetchSingleAiwiSale({ _id: aiwiSaleId }));
        dispatch(fetchAiwiSaleLogs(aiwiSaleId as string));
    }, [aiwiSaleId]);

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    return (
        <div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Reject Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            onChange={(event: any) => {
                                setRemark(event.target.value);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (remark.trim().length > 0) {
                                    dispatch(
                                        updateAiwiSaleStatus({
                                            status: "rejected",
                                            remarks: remark,
                                            _id: aiwiSaleId as string,
                                        })
                                    );

                                    setIsOpen(false);
                                    navigate("/aiwi-sale-listing");
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="py-3 mb-4">AIWI Sale Record</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <form>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        LAN No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.lan_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Customer Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-user"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.customer_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Zone :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-map"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.zone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Region :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-map-pin"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.region}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Branch :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-smart-home"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.branch}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Portfolio :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-report-analytics"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.portfolio}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        POS :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.pos}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Other Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.other_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Penal Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.penal_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Repo Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.repo_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Legal Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.legal_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        RC No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-certificate"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.rc_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Chassis No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-file-certificate"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.chassis_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Engine Number :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-engine"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.engine_number}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Make :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-layers-intersect"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.make}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Model :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-star"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.model}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Year of Manufacturing :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.mfg_year?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        LRN Date
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {formatDateToDDMMYYYY(
                                                    aiwiSale.lrn_date
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Sec 17/ Sec 9 Available
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.sec_17_9?.length > 0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Sec 138 Available
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.sec_138?.length > 0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        AIWI Sale Ticketing
                                    </label>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Remarks :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {aiwiSale.remarks}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        aiwiSale.challan as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Receipt or
                                                                Deposit Challan
                                                                copy*
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        aiwiSale.buyer_quotation as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Buyer Quotation*
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        aiwiSale.buyer_kyc_pan as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Buyer KYC – PAN
                                                                *
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        aiwiSale.buyer_kyc_address as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Buyer KYC –
                                                                Address Proof*
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        aiwiSale.sale_acceptance_letter as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Sale Acceptance
                                                                Letter *
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        aiwiSale.surrender_letter as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Sale Intimation
                                                                letter to
                                                                customer with
                                                                postal
                                                                acknowledgement
                                                                or Surrender
                                                                letter *
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        aiwiSale.sale_loss_approval as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Sale Loss
                                                                Approval *
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        aiwiSale.simulation as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Simulation
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4 mx-n4" />
                                <div className="row justify-content-end">
                                    <div className="col-sm-8">
                                        {userData?.role?.rights?.includes(
                                            "add_aiwi_sale"
                                        ) && (
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    navigate(
                                                        "/edit-aiwi-sale/" +
                                                            aiwiSaleId
                                                    );
                                                }}
                                            >
                                                EDIT
                                            </button>
                                        )}
                                        &nbsp;&nbsp;
                                        {userData?.role?.rights?.includes(
                                            "approve_aiwi_sale"
                                        ) &&
                                            aiwiSale.status?.indexOf(
                                                "approved_l2"
                                            ) < 0 && (
                                                <>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                            dispatch(
                                                                updateAiwiSaleStatus(
                                                                    {
                                                                        status: "approved",
                                                                        _id: aiwiSaleId as string,
                                                                    }
                                                                )
                                                            );

                                                            navigate(
                                                                "/aiwi-sale-listing"
                                                            );
                                                        }}
                                                    >
                                                        APPROVE
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => {
                                                            setIsOpen(true);
                                                        }}
                                                    >
                                                        QUERY
                                                    </button>
                                                </>
                                            )}
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Payment Receipting Detail
                                    </label>

                                    <div className="col-sm-8">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sr no</th>
                                                        <th>LAN no</th>
                                                        <th>Receipt date</th>
                                                        <th>UNO Receipt No</th>
                                                        <th>Receipt amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {aiwiSale?.payment_details?.map(
                                                        (
                                                            details: PaymentDetails,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {index +
                                                                            1}
                                                                        .
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            details.lan_no
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <span className="badge bg-label-primary me-1">
                                                                            {formatDateToDDMMYYYY(
                                                                                details.receipt_date
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            details.uno_receipt_no
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        ₹{" "}
                                                                        {
                                                                            details.receipt_amount
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="py-3 mb-1">Logs</h4>
            <div className="row">
                {logs.map((log: AIWISaleLogs) => {
                    return (
                        <div className="col-md-3 col-sm-6 col-12 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="mb-0">
                                        {formatDateToDDMMYYYY(log.createdAt)}
                                    </h6>
                                    <p className="mb-0">{log?.uid?.name}</p>
                                    <p className="mb-0">
                                        {getStatusText(log?.status)}
                                    </p>
                                    <p className="mb-0">{log.remarks}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ViewAIWISale;
