import React, { Dispatch, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import AutocompleteField from "../../components/AutoCompleteField";
import { useDispatch, useSelector } from "react-redux";
import { searchLan, selectLanItems } from "../../redux/reducers/lan";
import Lan from "../../interfaces/lan";
import { fetchProfile } from "../../redux/reducers/user";
import { User } from "../../interfaces/user";
import { Modal, Button } from "react-bootstrap";
import {
    addLocalBid,
    fetchSingleLocalBid,
    setLocalBid,
} from "../../redux/reducers/local_bid";
import { LocalBid } from "../../interfaces/local_bid";

const AddLocalBid: React.FC = () => {
    const navigate = useNavigate();
    const lanItems = useSelector(selectLanItems);
    const userData: User = useSelector((state: any) => state.user.userData);
    const localBid = useSelector((state: any) => state.localBid.localBid);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({});

    const dispatch: Dispatch<any> = useDispatch();

    const { bidId } = useParams();

    useEffect(() => {
        if (bidId) {
            dispatch(
                fetchSingleLocalBid({
                    _id: bidId,
                })
            );
        } else {
            dispatch(setLocalBid({}));
        }
    }, [bidId, dispatch]);

    useEffect(() => {
        const values: any = {} as LocalBid;
        Object.keys(localBid as keyof LocalBid).forEach((key) => {
            values[key] = localBid[key];
        });
        setInitialFormValues(values);
    }, [localBid]);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    const onSubmit = (values: LocalBid) => {
        dispatch(addLocalBid(values));
        navigate("/local-bid-listing");
    };

    const validate = (values: LocalBid) => {
        const errors: Partial<Record<keyof LocalBid, string>> = {};
        const requiredFields: (keyof LocalBid)[] = [
            "lan_no",
            "rc_no",
            "bidder_name",
            "bidder_contact",
            "bid_amount",
            "emd_amount",
            "emd_details",
            "remarks",
            "quotation",
            "emd_mode",
            "bidder_pan",
        ];

        requiredFields.forEach(
            (field) => !values[field] && (errors[field] = "Required")
        );

        if (values.bidder_name && !/^[a-zA-Z\s']+$/.test(values.bidder_name)) {
            errors.bidder_name =
                "Bidder name can only contain alphabets, spaces, and apostrophes";
        }

        if (values.bidder_contact && !/^\d{10}$/.test(values.bidder_contact)) {
            errors.bidder_contact = "Bidder contact must be exactly 10 digits";
        }

        if (
            values.bidder_pan &&
            !/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(values.bidder_pan)
        ) {
            errors.bidder_pan = "Invalid PAN number format";
        }
        return errors;
    };

    const setFormValue = (form: any, key: string, value: any) => {
        form.change(key, value);
    };
    const handleLanInputChange = (value: any) => {
        dispatch(
            searchLan({
                search: value,
                type: "local-bid",
            })
        );
    };
    const handleRcInputChange = (value: any) => {
        dispatch(
            searchLan({
                search: value,
                type: "local-bid",
                subType: "rc_no",
            })
        );
    };

    const onLanSelected = (form: any, value: Lan) => {
        setFormValue(form, "lan_no", value.lan_no);
        setFormValue(form, "rc_no", value.rc_no);
    };

    const onRcSelected = (form: any, value: Lan) => {
        setFormValue(form, "lan_no", value.lan_no);
        setFormValue(form, "rc_no", value.rc_no);
    };

    return (
        <>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <Form
                                onSubmit={onSubmit}
                                validate={validate}
                                initialValues={{
                                    emd_mode: "demand_draft",
                                }}
                                render={({
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Search LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            component={
                                                                AutocompleteField
                                                            }
                                                            name="search_lan_no"
                                                            icon="ti ti-hash"
                                                            placeholder="Search LAN No"
                                                            aria-label="Search LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            suggestions={
                                                                lanItems
                                                            }
                                                            renderItem={(
                                                                item: any
                                                            ) => (
                                                                <div className="suggestion-item">
                                                                    {
                                                                        item.lan_no
                                                                    }
                                                                </div>
                                                            )}
                                                            getKey={(
                                                                item: any
                                                            ) => item._id}
                                                            onInputChange={
                                                                handleLanInputChange
                                                            }
                                                            onSelected={(
                                                                value: any
                                                            ) => {
                                                                onLanSelected(
                                                                    form,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Search RC No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            component={
                                                                AutocompleteField
                                                            }
                                                            name="search_rc_no"
                                                            icon="ti ti-hash"
                                                            placeholder="Search RC No"
                                                            aria-label="Search RC No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            suggestions={
                                                                lanItems
                                                            }
                                                            renderItem={(
                                                                item: any
                                                            ) => (
                                                                <div className="suggestion-item">
                                                                    {item.rc_no}
                                                                </div>
                                                            )}
                                                            getKey={(
                                                                item: any
                                                            ) => item._id}
                                                            onInputChange={
                                                                handleRcInputChange
                                                            }
                                                            onSelected={(
                                                                value: any
                                                            ) => {
                                                                onRcSelected(
                                                                    form,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lan_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="LAN No"
                                                            aria-label="LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lan_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    RC No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="rc_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="RC No"
                                                            aria-label="RC No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="rc_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <hr className="my-4 mx-n4" />

                                            <div className="row mb-5">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Local Bid
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="row row-gap-4">
                                                        <div className="col-md-12">
                                                            <div className="table-responsive text-nowrap">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Particulars
                                                                            </th>
                                                                            <th>
                                                                                Information
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Bidder
                                                                                Name
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group input-group-merge">
                                                                                    <span
                                                                                        id="basic-icon-default-fullname2"
                                                                                        className="input-group-text"
                                                                                    >
                                                                                        <i className="ti ti-user"></i>
                                                                                    </span>
                                                                                    <Field
                                                                                        component="input"
                                                                                        name="bidder_name"
                                                                                        className="form-control"
                                                                                        id="basic-icon-default-fullname"
                                                                                        placeholder="Bidder Name"
                                                                                        aria-label="Bidder Name"
                                                                                        aria-describedby="basic-icon-default-fullname2"
                                                                                    />
                                                                                </div>
                                                                                <Field
                                                                                    name="bidder_name"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error &&
                                                                                            meta.touched ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Bidder
                                                                                Contact
                                                                                No
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group input-group-merge">
                                                                                    <span
                                                                                        id="basic-icon-default-fullname2"
                                                                                        className="input-group-text"
                                                                                    >
                                                                                        <i className="ti ti-phone"></i>
                                                                                    </span>
                                                                                    <Field
                                                                                        component="input"
                                                                                        name="bidder_contact"
                                                                                        className="form-control"
                                                                                        id="basic-icon-default-fullname"
                                                                                        placeholder="Bidder Contact"
                                                                                        aria-label="Bidder Contact"
                                                                                        aria-describedby="basic-icon-default-fullname2"
                                                                                        type="number"
                                                                                        onKeyDown={(
                                                                                            e: any
                                                                                        ) => {
                                                                                            if (
                                                                                                e.key ===
                                                                                                    "-" ||
                                                                                                e.key ===
                                                                                                    "+" ||
                                                                                                e.key ===
                                                                                                    "e"
                                                                                            ) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <Field
                                                                                    name="bidder_contact"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error &&
                                                                                            meta.touched ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Mode
                                                                                of
                                                                                EMD
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group input-group-merge">
                                                                                    <span
                                                                                        id="basic-icon-default-fullname2"
                                                                                        className="input-group-text"
                                                                                    >
                                                                                        {/* <i className="ti ti-phone"></i> */}
                                                                                    </span>
                                                                                    <Field
                                                                                        component="select"
                                                                                        name="emd_mode"
                                                                                        className="form-control"
                                                                                        id="basic-icon-default-fullname"
                                                                                        placeholder="Mode of EMD"
                                                                                        aria-label="Mode of EMD"
                                                                                        aria-describedby="basic-icon-default-fullname2"
                                                                                    >
                                                                                        <option value="demand_draft">
                                                                                            Demand
                                                                                            Draft
                                                                                        </option>
                                                                                        <option value="online">
                                                                                            Online
                                                                                        </option>
                                                                                    </Field>
                                                                                </div>
                                                                                <Field
                                                                                    name="emd_mode"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error &&
                                                                                            meta.touched ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Bidder
                                                                                PAN
                                                                                Number
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group input-group-merge">
                                                                                    <span
                                                                                        id="basic-icon-default-fullname2"
                                                                                        className="input-group-text"
                                                                                    >
                                                                                        {/* <i className="ti ti-user"></i> */}
                                                                                    </span>
                                                                                    <Field
                                                                                        name="bidder_pan"
                                                                                        render={({
                                                                                            input,
                                                                                        }) => (
                                                                                            <input
                                                                                                {...input}
                                                                                                className="form-control"
                                                                                                id="basic-icon-default-fullname"
                                                                                                placeholder="Bidder Pan"
                                                                                                aria-label="Bidder Pan"
                                                                                                aria-describedby="basic-icon-default-fullname2"
                                                                                                onChange={(
                                                                                                    e
                                                                                                ) => {
                                                                                                    input.onChange(
                                                                                                        e.target.value.toUpperCase()
                                                                                                    ); // Capitalize all letters
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </div>
                                                                                <Field
                                                                                    name="bidder_pan"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error &&
                                                                                            meta.touched ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Bid
                                                                                Amount
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group input-group-merge">
                                                                                    <span
                                                                                        id="basic-icon-default-fullname2"
                                                                                        className="input-group-text"
                                                                                    >
                                                                                        <i className="ti ti-cash"></i>
                                                                                    </span>
                                                                                    <Field
                                                                                        component="input"
                                                                                        name="bid_amount"
                                                                                        className="form-control"
                                                                                        id="basic-icon-default-fullname"
                                                                                        placeholder="Bid Amount"
                                                                                        aria-label="Bid Amount"
                                                                                        aria-describedby="basic-icon-default-fullname2"
                                                                                        type="number"
                                                                                        onKeyDown={(
                                                                                            e: any
                                                                                        ) => {
                                                                                            if (
                                                                                                e.key ===
                                                                                                    "-" ||
                                                                                                e.key ===
                                                                                                    "+" ||
                                                                                                e.key ===
                                                                                                    "e"
                                                                                            ) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <Field
                                                                                    name="bid_amount"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error &&
                                                                                            meta.touched ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                EMD
                                                                                Amount
                                                                            </td>
                                                                            <td>
                                                                                <div className="input-group input-group-merge">
                                                                                    <span
                                                                                        id="basic-icon-default-fullname2"
                                                                                        className="input-group-text"
                                                                                    >
                                                                                        <i className="ti ti-cash"></i>
                                                                                    </span>
                                                                                    <Field
                                                                                        component="input"
                                                                                        name="emd_amount"
                                                                                        className="form-control"
                                                                                        id="basic-icon-default-fullname"
                                                                                        placeholder="EMD Amount"
                                                                                        aria-label="EMD Amount"
                                                                                        aria-describedby="basic-icon-default-fullname2"
                                                                                        type="number"
                                                                                        onKeyDown={(
                                                                                            e: any
                                                                                        ) => {
                                                                                            if (
                                                                                                e.key ===
                                                                                                    "-" ||
                                                                                                e.key ===
                                                                                                    "+" ||
                                                                                                e.key ===
                                                                                                    "e"
                                                                                            ) {
                                                                                                e.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <Field
                                                                                    name="emd_amount"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error &&
                                                                                            meta.touched ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                EMD
                                                                                Details
                                                                                (Payment
                                                                                Reference
                                                                                Number)
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    component="input"
                                                                                    name="emd_details"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder="EMD Details"
                                                                                    aria-label="EMD Details"
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                />
                                                                                <Field
                                                                                    name="emd_details"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error &&
                                                                                            meta.touched ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Remarks
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    component="input"
                                                                                    name="remarks"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder="Remarks"
                                                                                    aria-label="Remarks"
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                />
                                                                                <Field
                                                                                    name="remarks"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error &&
                                                                                            meta.touched ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Upload
                                                                    Quotation{" "}
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <Field name="quotation">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />

                                            <div className="row justify-content-end">
                                                <div className="col-sm-9">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        SAVE DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AddLocalBid;
