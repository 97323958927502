import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Link, useNavigate } from "react-router-dom";
import { User } from "../../interfaces/user";
import { formatDateToDDMMYYYY } from "../../helpers/date";
import Select from "react-select";
import { fetchRegions } from "../../redux/reducers/lan";
import { fetchAiwiSale } from "../../redux/reducers/aiwi-sale";
import AIWISale from "../../interfaces/aiwi_sale";

const AIWISaleListing: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const aiwiSaleItems = useSelector((state: any) => state.aiwiSale.items);
    const userData: User = useSelector((state: any) => state.user.userData);
    const regions: string[] = useSelector((state: any) => state.lan.regions);

    const navigateToAdd = () => {
        navigate("/add-aiwi-sale");
    };

    useEffect(() => {
        dispatch(fetchAiwiSale({}));
        dispatch(fetchRegions());
    }, [dispatch]);

    const handleRegionChange = (values: any) => {
        let regions = values.map((value: any) => value.value);
        regions = regions.join(",");

        dispatch(fetchAiwiSale({ regions: regions }));
    };

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    return (
        <>
            <div className="card">
                <div className="card-header-elements m-4 ms-auto">
                    <Select
                        onChange={handleRegionChange}
                        isMulti
                        closeMenuOnSelect={false}
                        options={regions.map((region) => ({
                            value: region,
                            label: region,
                        }))}
                        placeholder="Filter Regions"
                    />
                    {userData?.role?.rights.includes("add_aiwi_sale") && (
                        <button
                            type="button"
                            onClick={navigateToAdd}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Add New Entry
                        </button>
                    )}
                    {userData?.role?.rights.includes("view_aiwi_sale") && (
                        <button
                            type="button"
                            onClick={() => {
                                dispatch(fetchAiwiSale({ export: true }));
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="fa-solid fa-file-export me-2"></span>
                            Export
                        </button>
                    )}
                </div>
                <div
                    className="table-responsive text-nowrap"
                    style={{ overflowX: "auto" }}
                >
                    <table className="table table-striped freezeHead">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>LAN No</th>
                                <th>Creation Date</th>
                                <th>Customer Name</th>
                                <th>Branch</th>
                                <th>LRN Date</th>
                                <th>Portfolio</th>
                                <th>Region</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {aiwiSaleItems.map(
                                (aiwiSale: AIWISale, index: number) => {
                                    return (
                                        <tr key={aiwiSale._id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/view-aiwi-sale/${aiwiSale._id}`}
                                                >
                                                    {aiwiSale.lan_no}
                                                </Link>
                                            </td>
                                            <td>
                                                {formatDateToDDMMYYYY(
                                                    aiwiSale.createdAt
                                                )}
                                            </td>
                                            <td>{aiwiSale.customer_name}</td>
                                            <td>{aiwiSale.branch}</td>
                                            <td>
                                                {formatDateToDDMMYYYY(
                                                    aiwiSale.lrn_date
                                                )}
                                            </td>
                                            <td>{aiwiSale.portfolio}</td>
                                            <td>{aiwiSale.region}</td>
                                            <td>
                                                {getStatusText(aiwiSale.status)}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default AIWISaleListing;
