import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../interfaces/user";
import { formatDateToDDMMYYYY } from "../../../helpers/date";
import { Link, useNavigate } from "react-router-dom";
import Agency from "../../../interfaces/agency";
import {
    clearAgencyList,
    exportRepoAgency,
    importBulkAgency,
    searchAgency,
    setAgencyImported,
} from "../../../redux/reducers/agency";
import { Modal, Button } from "react-bootstrap";

const RepoAgencyList: React.FC = () => {
    const userData: User = useSelector((state: any) => state.user.userData);
    const navigate = useNavigate();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [importing, setImporting] = useState(false);
    const [agencyFile, setAgencyFile] = useState(null);
    const agencyImported: boolean | null = useSelector(
        (state: any) => state.agency.imported
    );
    const agencyItems: Agency[] = useSelector(
        (state: any) => state.agency.items
    );
    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        dispatch(
            searchAgency({
                search: "",
            })
        );
        return () => {
            dispatch(clearAgencyList());
        };
    }, [dispatch]);

    const navigateToAdd = () => {
        navigate("/master/add-agency");
    };

    useEffect(() => {
        if (agencyImported === true) {
            setImporting(false);
            alert("Agency Data Imported Successfully!");
            setIsOpen(false);
            window.location.reload();
        } else if (agencyImported === null) {
            setImporting(false);
            dispatch(setAgencyImported(false));
        }
    }, [agencyImported]);

    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Bulk Upload Agency</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="file"
                            accept=".csv"
                            className="form-control"
                            onChange={(event: any) => {
                                setAgencyFile(event.target.files[0]);
                            }}
                        />
                        <span>only .csv files supported</span>
                        <br />
                        {importing && <span>Importing...</span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setImporting(true);
                                dispatch(
                                    importBulkAgency({ file: agencyFile })
                                );
                            }}
                        >
                            Upload
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="card">
                <div className="card-header-elements m-4 ms-auto">
                    {userData?.role?.rights.includes("add_agency") && (
                        <button
                            style={{ marginRight: 5 }}
                            type="button"
                            onClick={() => {
                                setIsOpen(true);
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Bulk Upload
                        </button>
                    )}
                    {userData?.role?.rights.includes("add_agency") && (
                        <button
                            type="button"
                            onClick={navigateToAdd}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Add New Entry
                        </button>
                    )}
                    {userData?.role?.rights.includes("add_agency") && (
                        <button
                            style={{ marginRight: 5 }}
                            type="button"
                            onClick={() => {
                                dispatch(exportRepoAgency());
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="fa-solid fa-file-export me-2"></span>
                            Export
                        </button>
                    )}
                </div>
                <div className="table-responsive text-nowrap">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Uno Code</th>
                                <th>Onex Code</th>
                                <th>Omnifin</th>
                                <th>Type</th>
                                <th>Name</th>
                                <th>Branch</th>
                                <th>Region</th>
                                <th>Zone</th>
                                <th>State</th>
                                <th>Status</th>
                                <th>Created Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {agencyItems.map(
                                (agency: Agency, index: number) => {
                                    return (
                                        <tr key={agency._id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/master/view-agency/${agency._id}`}
                                                >
                                                    {agency.uno_code}
                                                </Link>
                                            </td>
                                            <td>{agency.onex_code}</td>
                                            <td>{agency.omnifin}</td>
                                            <td>{agency.type}</td>
                                            <td>{agency.name}</td>
                                            <td>{agency.branch}</td>
                                            <td>{agency.region}</td>
                                            <td>{agency.zone}</td>
                                            <td>{agency.state}</td>
                                            <td>
                                                {agency.is_active
                                                    ? "Active"
                                                    : "In Active"}
                                            </td>
                                            <td>
                                                {formatDateToDDMMYYYY(
                                                    agency.createdDate
                                                )}
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        type="button"
                                                        className="btn p-0 dropdown-toggle hide-arrow"
                                                        data-bs-toggle="dropdown"
                                                    >
                                                        <i className="ti ti-dots-vertical"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        {userData?.role?.rights.includes(
                                                            "add_agency"
                                                        ) && (
                                                            <Link
                                                                to={`/master/edit-agency/${agency._id}`}
                                                                className="dropdown-item"
                                                            >
                                                                <i className="ti ti-pencil me-1"></i>{" "}
                                                                Edit
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default RepoAgencyList;
