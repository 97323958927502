import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { User } from "../interfaces/user";
import { useMediaQuery } from "react-responsive";

const Menu: React.FC = () => {
    const token = useSelector((state: any) => state.user.token);
    const userData: User = useSelector((state: any) => state.user.userData);
    const location = useLocation();
    const isMobile = useMediaQuery({ maxWidth: 768 });

    const isLinkActive = (link: string) => {
        return location.pathname === link;
    };

    if (!token) {
        return <div></div>;
    } else {
        return (
            <>
                <aside
                    style={
                        isMobile ? { marginTop: "10px", overflowY: "auto" } : {}
                    }
                    id="layout-menu"
                    className={`${
                        isMobile
                            ? "menu-mobile flex-grow-0"
                            : "layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0"
                    }`}
                >
                    <div className="container d-flex h-100">
                        <ul className="menu-inner d-flex">
                            <li
                                className={`menu-item ${
                                    isLinkActive("/") ? "active" : ""
                                }`}
                            >
                                <Link to="/" className="menu-link menu-toggle">
                                    <i className="menu-icon tf-icons ti ti-smart-home"></i>
                                    <div>Dashboard</div>
                                </Link>
                            </li>
                            {userData?.role?.rights?.includes("view_repo") && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/repo-listing")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/repo-listing"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>Repo & Surrender</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes(
                                "view_repo_release"
                            ) && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/repo-release-listing")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/repo-release-listing"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>Repo Release Ticketing</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes(
                                "view_local_bid"
                            ) && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/local-bid-listing")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/local-bid-listing"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>Local Bid</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes(
                                "view_ots_approval"
                            ) && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/ots-approval-listing")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/ots-approval-listing"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>OTS-Approval</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes(
                                "view_ots_closure"
                            ) && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/ots-closure-listing")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/ots-closure-listing"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>OTS-Closure</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes(
                                "view_aiwi_sale"
                            ) && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/aiwi-sale-listing")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/aiwi-sale-listing"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>AIWI Sale Ticketing</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes(
                                "view_legal_request"
                            ) && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/legal-request-listing")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/legal-request-listing"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>Legal Request</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes("view_yard") && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/master/yard")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/master/yard"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>Yard Master</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes(
                                "view_agency"
                            ) && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/master/agency")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/master/agency"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>Repo Agency Master</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes("view_lan") && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/master/lan")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/master/lan"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>Lan Master</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes("view_user") && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive("/master/user")
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/master/user"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>User Master</div>
                                    </Link>
                                </li>
                            )}
                            {userData?.role?.rights?.includes(
                                "view_legal_request_lan"
                            ) && (
                                <li
                                    className={`menu-item ${
                                        isLinkActive(
                                            "/master/legal-request-lan"
                                        )
                                            ? "active"
                                            : ""
                                    }`}
                                >
                                    <Link
                                        to="/master/legal-request-lan"
                                        className="menu-link menu-toggle"
                                    >
                                        <i className="menu-icon tf-icons ti ti-files"></i>
                                        <div>Legal Request Master</div>
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                </aside>
            </>
        );
    }
};

export default Menu;
