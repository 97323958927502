import React, { Dispatch, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { formatDateToYYYYMMDD } from "../../../helpers/date";
import Agency from "../../../interfaces/agency";
import {
    addAgency,
    fetchSingleAgency,
    setAgency,
    setAgencyAdded,
    updateAgency,
} from "../../../redux/reducers/agency";

const RepoAgencyAdd: React.FC = () => {
    const { agencyId } = useParams();
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const agencyAdded = useSelector((state: any) => state.agency.added);
    const agency: Agency = useSelector((state: any) => state.agency.agency);

    const [initialValues, setInitialValues] = useState({} as Agency);

    const onSubmit = (values: Agency) => {
        if (agencyId) {
            dispatch(updateAgency(values));
        } else {
            dispatch(addAgency(values));
        }
    };

    useEffect(() => {
        if (agencyId) {
            dispatch(fetchSingleAgency({ _id: agencyId }));
        } else {
            dispatch(setAgency({}));
        }
    }, [agencyId, dispatch]);

    useEffect(() => {
        if (agencyAdded) {
            dispatch(setAgencyAdded(false));
            navigate("/master/agency");
        }
    }, [agencyAdded]);

    useEffect(() => {
        if (agency) {
            let values = { ...agency };
            values.createdDate = formatDateToYYYYMMDD(
                values.createdDate
            ) as any;
            if (values.is_active !== undefined) {
                values.is_active = values.is_active ? "true" : ("false" as any);
            }

            setInitialValues(values);
        }
    }, [agency]);

    const validate = (values: Agency) => {
        const errors: Partial<Record<keyof Agency, string>> = {};

        const requiredFields: (keyof Agency)[] = [
            "uno_code",
            "onex_code",
            "omnifin",
            "type",
            "name",
            "branch",
            "region",
            "zone",
            "state",
            "createdDate",
            "is_active",
        ];

        requiredFields.forEach(
            (field) => !values[field] && (errors[field] = "Required")
        );

        return errors;
    };

    return (
        <div>
            <h4 className="py-3 mb-4">
                {agencyId ? "Edit Agency" : "Add Agency"}
            </h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <Form
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Uno Code
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="uno_code"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Uno Code"
                                                            aria-label="Uno Code"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="uno_code"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Onex code
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="onex_code"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Onex Code"
                                                            aria-label="Onex Code"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="onex_code"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Omnifin
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="omnifin"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Omnifin"
                                                            aria-label="Omnifin"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="omnifin"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Type
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="type"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Type"
                                                            aria-label="Type"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="type"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Name"
                                                            aria-label="Name"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Branch
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="branch"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Branch"
                                                            aria-label="Branch"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="branch"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Region
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="region"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Region"
                                                            aria-label="Region"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="region"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Zone
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="zone"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Zone"
                                                            aria-label="Zone"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="zone"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    State
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="state"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="State"
                                                            aria-label="State"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="state"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Created Date
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="createdDate"
                                                            type="date"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Created Date"
                                                            aria-label="Created Date"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="createdDate"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Status
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="form-check form-check-inline mt-2">
                                                        <Field
                                                            component="input"
                                                            name="is_active"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio1"
                                                            value="true"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio1"
                                                        >
                                                            Active
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            component="input"
                                                            name="is_active"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio2"
                                                            value="false"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio2"
                                                        >
                                                            In Active
                                                        </label>
                                                    </div>
                                                    <Field
                                                        name="is_active"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />

                                            <div className="row justify-content-end">
                                                <div className="col-sm-9">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        SAVE DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-backdrop fade"></div>
        </div>
    );
};
export default RepoAgencyAdd;
