import React from 'react';
import { useSelector } from 'react-redux';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const loggedInUser = useSelector((state: any) => state.user.loggedInUser);
  if(!loggedInUser){
      return(<div></div>);
  }
  else{
    return (
      <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl">
          <div className="footer-container d-flex align-items-center justify-content-between py-2 flex-md-row flex-column">
            <div>
              © {currentYear}, Developed by <a href="#" target="_blank" className="fw-medium">Amuze</a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;