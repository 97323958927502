import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
} from "../../helpers/date";
import { Modal, Button } from "react-bootstrap";
import { User } from "../../interfaces/user";
import OtsApproval from "../../interfaces/ots_approval";
import {
    fetchOtsApprovalLogs,
    fetchSingleOtsApproval,
    updateOtsApprovalStatus,
} from "../../redux/reducers/ots-approval";
import { OtsApprovalLogs } from "../../interfaces/ots_approval_logs";

const ViewOtsApproval: React.FC = () => {
    let dispatch: Dispatch<any> = useDispatch();
    const { otsId } = useParams();
    const navigate = useNavigate();
    let otsApproval: OtsApproval = useSelector(
        (state: any) => state.otsApproval.otsApproval
    );
    const userData: User = useSelector((state: any) => state.user.userData);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [remark, setRemark] = useState("");
    let logs: OtsApprovalLogs[] = useSelector(
        (state: any) => state.otsApproval.logs
    );

    useEffect(() => {
        dispatch(fetchSingleOtsApproval({ _id: otsId }));
        dispatch(fetchOtsApprovalLogs(otsId as string));
    }, [otsId]);

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    return (
        <div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Reject Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            onChange={(event: any) => {
                                setRemark(event.target.value);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (remark.trim().length > 0) {
                                    dispatch(
                                        updateOtsApprovalStatus({
                                            status: "rejected",
                                            remarks: remark,
                                            _id: otsId as string,
                                        })
                                    );

                                    setIsOpen(false);
                                    navigate("/ots-approval-listing");
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="py-3 mb-4">OTS Approval Record</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <form>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        LAN No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.lan_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Customer Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-user"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.customer_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Zone :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-map"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.zone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Region :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-map-pin"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.region}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Branch :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-smart-home"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.branch}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Portfolio :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-report-analytics"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.portfolio}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        POS :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.pos}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Other Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.other_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Penal Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.penal_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Repo Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.repo_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Legal Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.legal_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        RC No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-certificate"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.rc_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Chassis No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-file-certificate"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.chassis_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Engine Number :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-engine"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.engine_number}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Make :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-layers-intersect"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.make}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Model :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-star"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.model}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Year of Manufacturing :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.mfg_year?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        LRN Date
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {formatDateToDDMMYYYY(
                                                    otsApproval.lrn_date
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Sec 17/ Sec 9 Available
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.sec_17_9?.length >
                                                0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Sec 138 Available
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.sec_138?.length > 0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        OTS - Approval
                                    </label>

                                    <div className="col-sm-8">
                                        <div className="col-sm-12 col-form-label">
                                            Simulation / Foreclosure generation
                                            Date :
                                            <div className="d-flex justify-content-start align-items-center mt-1">
                                                <div className="me-2">
                                                    <i className="ti ti-calendar-stats"></i>
                                                </div>
                                                <p className="mb-0 fw-normal">
                                                    {formatDateToDDMMYYYY(
                                                        otsApproval.foreclosure_generation_date
                                                    )}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="table-responsive text-nowrap mt-4">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Outstanding
                                                            <br />
                                                            Header
                                                        </th>
                                                        <th>
                                                            Total
                                                            <br />
                                                            Outstanding
                                                        </th>
                                                        <th>Collection</th>
                                                        <th>Waiver</th>
                                                        <th>Waiver %</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            Principal Overdue
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.principal_overdue_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.principal_overdue_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.principal_overdue_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.principal_overdue_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Interest Overdue
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.interest_overdue_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.interest_overdue_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.interest_overdue_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.interest_overdue_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Future Principal
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.future_principal_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.future_principal_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.future_principal_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.future_principal_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Broken Period
                                                            Interest
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.broken_period_interest_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.broken_period_interest_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.broken_period_interest_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.broken_period_interest_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>BL Amount</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.bl_amount_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.bl_amount_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.bl_amount_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.bl_amount_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Cheque Bounce
                                                            Charges
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.cheque_bounce_charges_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.cheque_bounce_charges_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.cheque_bounce_charges_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.cheque_bounce_charges_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Penal Charges</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.penal_charges_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.penal_charges_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.penal_charges_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.penal_charges_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Repo Charges</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.repo_charges_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.repo_charges_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.repo_charges_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.repo_charges_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Legal Charges</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.legal_charges_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.legal_charges_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.legal_charges_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.legal_charges_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other Charges</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.other_charges_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.other_charges_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.other_charges_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.other_charges_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.total_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.total_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.total_waiver
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                otsApproval.total_waiver_percentage
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>BVL - Rupees</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.bvl_amount
                                                            }
                                                        </td>
                                                        <td>BVL - % </td>
                                                        <td>
                                                            {" "}
                                                            {
                                                                otsApproval.bvl
                                                            }{" "}
                                                        </td>
                                                        <td> </td>
                                                    </tr>
                                                    <tr>
                                                        <td>AVL - Rupees</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                otsApproval.avl_amount
                                                            }
                                                        </td>
                                                        <td>AVL - % </td>
                                                        <td>
                                                            {otsApproval.avl}
                                                        </td>
                                                        <td> </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Justification :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {otsApproval.justification}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        otsApproval.attachment as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Attachment
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4 mx-n4" />
                                <div className="row justify-content-end">
                                    <div className="col-sm-8">
                                        {userData?.role?.rights?.includes(
                                            "add_ots_approval"
                                        ) && (
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    navigate(
                                                        "/edit-ots-approval/" +
                                                            otsId
                                                    );
                                                }}
                                            >
                                                EDIT
                                            </button>
                                        )}
                                        &nbsp;&nbsp;
                                        {otsApproval.status == "approved_l1" &&
                                            userData?.role?.name == "l2" && (
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        dispatch(
                                                            updateOtsApprovalStatus(
                                                                {
                                                                    status: "accepted",
                                                                    _id: otsId as string,
                                                                }
                                                            )
                                                        );
                                                        navigate(
                                                            "/ots-approval-listing"
                                                        );
                                                    }}
                                                >
                                                    ACCEPT
                                                </button>
                                            )}
                                        &nbsp;&nbsp;
                                        {userData?.role?.rights?.includes(
                                            "approve_ots_approval"
                                        ) &&
                                            otsApproval.status?.indexOf(
                                                "approved_l2"
                                            ) < 0 && (
                                                <>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                            dispatch(
                                                                updateOtsApprovalStatus(
                                                                    {
                                                                        status: "approved",
                                                                        _id: otsId as string,
                                                                    }
                                                                )
                                                            );

                                                            navigate(
                                                                "/ots-approval-listing"
                                                            );
                                                        }}
                                                    >
                                                        APPROVE
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => {
                                                            setIsOpen(true);
                                                        }}
                                                    >
                                                        QUERY
                                                    </button>
                                                </>
                                            )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="py-3 mb-1">Logs</h4>
            <div className="row">
                {logs.map((log: OtsApprovalLogs) => {
                    return (
                        <div className="col-md-3 col-sm-6 col-12 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="mb-0">
                                        {formatDateToDDMMYYYYWithTime(
                                            log.createdAt
                                        )}
                                    </h6>
                                    <p className="mb-0">{log?.uid?.name}</p>
                                    <p className="mb-0">
                                        {getStatusText(log?.status)}
                                    </p>
                                    <p className="mb-0">{log.remarks}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ViewOtsApproval;
