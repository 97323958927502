import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const LanMenu: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (path: string) => location.pathname === path;
    return (
        <div className="mt-3 mb-3">
            <div className="btn-group" role="group" aria-label="Basic example">
                <button
                    onClick={() => navigate("/master/lan")}
                    className={`btn ${
                        isActive("/master/lan")
                            ? "btn-primary"
                            : "btn-outline-secondary"
                    }`}
                >
                    Repo & Surrender Master
                </button>
                <button
                    onClick={() => navigate("/master/local-bid-lan")}
                    className={`btn ${
                        isActive("/master/local-bid-lan")
                            ? "btn-primary"
                            : "btn-outline-secondary"
                    }`}
                >
                    Local Bid Master
                </button>
                <button
                    onClick={() => navigate("/master/ots-approval-lan")}
                    className={`btn ${
                        isActive("/master/ots-approval-lan")
                            ? "btn-primary"
                            : "btn-outline-secondary"
                    }`}
                >
                    Ots Approval Master
                </button>
                {/* <button
                    onClick={() => navigate("/master/aiwi-lan")}
                    className={`btn ${
                        isActive("/master/aiwi-lan")
                            ? "btn-primary"
                            : "btn-outline-secondary"
                    }`}
                >
                    AIWI Sale Ticketing Master
                </button> */}
            </div>
        </div>
    );
};

export default LanMenu;
