import React, { useState, ChangeEvent } from "react";
import { FieldRenderProps } from "react-final-form";

interface AutocompleteFieldProps<T> extends FieldRenderProps<T, HTMLElement> {
    suggestions: T[];
    label: string;
    placeholder: string;
    renderItem: (item: T) => React.ReactNode;
    getKey: (item: T) => string | number;
    onInputChange: (searchTerm: string) => void;
    icon: string;
    onSelected: (suggestion: T) => void;
}

const AutocompleteField = <T,>({
    input,
    meta,
    suggestions,
    placeholder,
    disabled,
    icon,
    renderItem,
    getKey,
    onInputChange,
    onSelected,
}: AutocompleteFieldProps<T>) => {
    const [open, setOpen] = useState(false);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        input.onChange(searchTerm);
        setOpen(searchTerm.trim() !== "");
        onInputChange(searchTerm);
    };

    const handleSuggestionClick = (suggestion: T) => {
        input.onChange(suggestion);
        onSelected(suggestion);
        setOpen(false);
    };

    const inputValue = typeof input.value === "string" ? input.value : "";

    return (
        <>
            <div className="input-group input-group-merge">
                <span
                    id="basic-icon-default-fullname2"
                    className="input-group-text"
                >
                    <i className={icon}></i>
                </span>
                <input
                    type="text"
                    disabled={disabled}
                    className="form-control"
                    id="autocomplete-field"
                    placeholder={placeholder}
                    onChange={handleInputChange}
                    value={inputValue}
                />
            </div>
            {open && (
                <div className="autocomplete-suggestions">
                    {suggestions.map((item) => (
                        <div
                            key={getKey(item)}
                            onClick={() => handleSuggestionClick(item)}
                        >
                            {renderItem(item)}
                        </div>
                    ))}
                </div>
            )}
            {meta.error && meta.touched && (
                <div className="error">{meta.error}</div>
            )}
        </>
    );
};

export default AutocompleteField;
