import React, { Dispatch, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import AutocompleteField from "../../components/AutoCompleteField";
import { useDispatch, useSelector } from "react-redux";
import { searchLan, selectLanItems } from "../../redux/reducers/lan";
import Lan from "../../interfaces/lan";
import MaskedInput from "react-input-mask";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
    formatDateToYYYYMMDD,
} from "../../helpers/date";
import { fetchProfile } from "../../redux/reducers/user";
import { User } from "../../interfaces/user";
import { Modal, Button } from "react-bootstrap";
import {
    addOtsClosure,
    fetchOtsClosureLogs,
    fetchSingleOtsClosure,
    setOtsClosure,
    updateOtsClosure,
} from "../../redux/reducers/ots-closure";
import OtsClosure from "../../interfaces/ots_closure";
import { OtsClosureLogs } from "../../interfaces/ots_closure_logs";

const AddOtsClosure: React.FC = () => {
    const navigate = useNavigate();
    const lanItems = useSelector(selectLanItems);
    const userData: User = useSelector((state: any) => state.user.userData);
    const otsClosure = useSelector((state: any) => state.otsClosure.otsClosure);
    const [modalIsOpen, setIsOpen] = useState(false);

    const [lanNo, setLanNo] = useState("");
    const [receiptDate, setReceiptDate] = useState("");
    const [unoReceiptNo, setUnoReceiptNo] = useState("");
    const [receiptAmount, setReceiptAmount] = useState("");

    const [paymentDetailsArr, setPaymentDetailsArr] = useState<
        PaymentDetails[]
    >([]);

    let logs: OtsClosureLogs[] = useSelector(
        (state: any) => state.otsClosure.logs
    );

    const dispatch: Dispatch<any> = useDispatch();

    const { otsId } = useParams();

    const [initialFormValues, setInitialFormValues] = useState({
        normal_repo_expenses: 0,
        additional_repo_expenses: 0,
        total_repo_expenses: 0,
    });

    const currentDate = new Date().toISOString().split("T")[0];

    useEffect(() => {
        if (otsId) {
            dispatch(
                fetchSingleOtsClosure({
                    _id: otsId,
                })
            );
            dispatch(fetchOtsClosureLogs(otsId as string));
        } else {
            dispatch(setOtsClosure({}));
        }
    }, [otsId, dispatch]);

    useEffect(() => {
        const values: any = {} as OtsClosure;
        Object.keys(otsClosure as keyof OtsClosure).forEach((key) => {
            values[key] = otsClosure[key];
        });
        values.lrn_date = formatDateToYYYYMMDD(values.lrn_date);
        setPaymentDetailsArr(values.payment_details || []);
        setInitialFormValues(values);
    }, [otsClosure]);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    const onSubmit = (values: OtsClosure) => {
        values.payment_details = paymentDetailsArr;
        if (otsId) {
            dispatch(updateOtsClosure(values));
        } else {
            dispatch(addOtsClosure(values));
        }
        navigate("/ots-closure-listing");
    };

    const validate = (values: OtsClosure) => {
        const errors: Partial<Record<keyof OtsClosure, string>> = {};

        const requiredFields: (keyof OtsClosure)[] = [
            "lan_no",
            "penal_charges",
            "repo_charges",
            "legal_charges",
            "foreclosure_generation_date",
            "principal_overdue_outstanding",
            "principal_overdue_collection",
            "interest_overdue_outstanding",
            "interest_overdue_collection",
            "future_principal_outstanding",
            "future_principal_collection",
            "broken_period_interest_outstanding",
            "broken_period_interest_collection",
            "bl_amount_outstanding",
            "bl_amount_collection",
            "cheque_bounce_charges_outstanding",
            "cheque_bounce_charges_collection",
            "penal_charges_outstanding",
            "penal_charges_collection",
            "repo_charges_outstanding",
            "repo_charges_collection",
            "legal_charges_outstanding",
            "legal_charges_collection",
            "other_charges_outstanding",
            "other_charges_collection",
            "fc_letter",
            "challan",
            "waiver",
            "simulation",
        ];

        requiredFields.forEach((field) => {
            if (values[field] === undefined || values[field] === null) {
                errors[field] = "Required";
            }
        });

        const collectionValidation = [
            ["principal_overdue_collection", "principal_overdue_outstanding"],
            ["interest_overdue_collection", "interest_overdue_outstanding"],
            ["future_principal_collection", "future_principal_outstanding"],
            [
                "broken_period_interest_collection",
                "broken_period_interest_outstanding",
            ],
            ["bl_amount_collection", "bl_amount_outstanding"],
            [
                "cheque_bounce_charges_collection",
                "cheque_bounce_charges_outstanding",
            ],
            ["penal_charges_collection", "penal_charges_outstanding"],
            ["legal_charges_collection", "legal_charges_outstanding"],
            ["repo_charges_collection", "repo_charges_outstanding"],
            ["other_charges_collection", "other_charges_outstanding"],
        ] as const;

        collectionValidation.forEach(([collection, outstanding]) => {
            const collectionValue = parseFloat(values[collection] as any);
            const outstandingValue = parseFloat(values[outstanding] as any);

            if (
                !isNaN(collectionValue) &&
                !isNaN(outstandingValue) &&
                collectionValue > outstandingValue
            ) {
                errors[collection] =
                    "Should be less than or equal to outstanding amount";
            }
        });

        return errors;
    };

    const setFormValue = (form: any, key: string, value: any) => {
        form.change(key, value);
    };
    const handleLanInputChange = (value: any) => {
        dispatch(
            searchLan({
                search: value,
                type: "ots-closure",
            })
        );
    };

    const onLanSelected = (form: any, value: Lan) => {
        setFormValue(form, "customer_name", value.customer_name);
        setFormValue(form, "lan_no", value.lan_no);
        setFormValue(form, "zone", value.zone);
        setFormValue(form, "region", value.region);
        setFormValue(form, "branch", value.branch);
        setFormValue(form, "portfolio", value.portfolio);
        setFormValue(form, "pos", value.pos);
        setFormValue(form, "other_charges", value.other_charges);
        setFormValue(form, "rc_no", value.rc_no);
        setFormValue(form, "chassis_no", value.chassis_no);
        setFormValue(form, "engine_number", value.engine_number);
        setFormValue(form, "make", value.make);
        setFormValue(form, "model", value.model);
        setFormValue(form, "mfg_year", value.mfg_year);
        setFormValue(form, "repo_charges", value.repo_charges);
        setFormValue(form, "penal_charges", value.penal_charges);
        setFormValue(form, "legal_charges", value.legal_charges);
        setFormValue(form, "revised_focus_band", value.revised_focus_band);
        setFormValue(
            form,
            "total_emi_collectable",
            value.total_emi_collectable
        );
        setFormValue(form, "as_on_date_dpd", value.as_on_date_dpd);
        setFormValue(
            form,
            "total_gross_collection",
            value.total_gross_collection
        );
        setFormValue(form, "sec_17_9", value.sec_17_9 != null ? "Yes" : "No");
        setFormValue(form, "sec_138", value.sec_138?.length > 0 ? "Yes" : "No");
        setFormValue(
            form,
            "lrn_date",
            value.lrn_date ? formatDateToYYYYMMDD(value.lrn_date) : ""
        );
        setFormValue(
            form,
            "arbitration_status",
            value.arbitration_status?.length > 0 ? "Yes" : "No"
        );
    };

    const handleAmountChange = (form: any, key: string, value: number) => {
        form.change(key, value);
        /*************** Principal Overdue Calculations******************/
        const principalOverdueOutstanding =
            parseInt(form.getState().values.principal_overdue_outstanding) || 0;
        const principalOverdueCollection =
            parseInt(form.getState().values.principal_overdue_collection) || 0;
        const principalOverdueWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((principalOverdueOutstanding -
                        principalOverdueCollection) /
                        principalOverdueOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "principal_overdue_waiver_percentage",
            principalOverdueWaiverPercentage || 0
        );

        form.change(
            "principal_overdue_waiver",
            principalOverdueOutstanding - principalOverdueCollection
        );
        /****************Principal Overdue Calculations******************/

        /*************** Interest Overdue Calculations******************/
        const interestOverdueOutstanding =
            parseInt(form.getState().values.interest_overdue_outstanding) || 0;
        const interestOverdueCollection =
            parseInt(form.getState().values.interest_overdue_collection) || 0;
        const interestOverdueWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((interestOverdueOutstanding - interestOverdueCollection) /
                        interestOverdueOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "interest_overdue_waiver_percentage",
            interestOverdueWaiverPercentage || 0
        );

        form.change(
            "interest_overdue_waiver",
            interestOverdueOutstanding - interestOverdueCollection
        );
        /****************Interest Overdue Calculations******************/

        /*************** Future Principal Calculations******************/
        const futurePrincipalOutstanding =
            parseInt(form.getState().values.future_principal_outstanding) || 0;
        const futurePrincipalCollection =
            parseInt(form.getState().values.future_principal_collection) || 0;
        const futurePrincipalWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((futurePrincipalOutstanding - futurePrincipalCollection) /
                        futurePrincipalOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "future_principal_waiver_percentage",
            futurePrincipalWaiverPercentage || 0
        );

        form.change(
            "future_principal_waiver",
            futurePrincipalOutstanding - futurePrincipalCollection
        );
        /****************Future Principal Calculations******************/

        /*************** Broken Period Interest Calculations******************/
        const brokenPeriodInterestOutstanding =
            parseInt(
                form.getState().values.broken_period_interest_outstanding
            ) || 0;
        const brokenPeriodInterestCollection =
            parseInt(
                form.getState().values.broken_period_interest_collection
            ) || 0;
        const brokenPeriodInterestWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((brokenPeriodInterestOutstanding -
                        brokenPeriodInterestCollection) /
                        brokenPeriodInterestOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "broken_period_interest_waiver_percentage",
            brokenPeriodInterestWaiverPercentage || 0
        );

        form.change(
            "broken_period_interest_waiver",
            brokenPeriodInterestOutstanding - brokenPeriodInterestCollection
        );
        /****************Broken Period Interest Calculations******************/

        /*************** BL Amount Calculations******************/
        const blAmountOutstanding =
            parseInt(form.getState().values.bl_amount_outstanding) || 0;
        const blAmountCollection =
            parseInt(form.getState().values.bl_amount_collection) || 0;
        const blAmountWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((blAmountOutstanding - blAmountCollection) /
                        blAmountOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "bl_amount_waiver_percentage",
            blAmountWaiverPercentage || 0
        );

        form.change(
            "bl_amount_waiver",
            blAmountOutstanding - blAmountCollection
        );
        /*************** BL Amount Calculations******************/

        /*************** Cheque Bounce Charges Calculations******************/
        const chequeBounceChargesOutstanding =
            parseInt(
                form.getState().values.cheque_bounce_charges_outstanding
            ) || 0;
        const chequeBounceChargesCollection =
            parseInt(form.getState().values.cheque_bounce_charges_collection) ||
            0;
        const chequeBounceChargesWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((chequeBounceChargesOutstanding -
                        chequeBounceChargesCollection) /
                        chequeBounceChargesOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "cheque_bounce_charges_waiver_percentage",
            chequeBounceChargesWaiverPercentage || 0
        );

        form.change(
            "cheque_bounce_charges_waiver",
            chequeBounceChargesOutstanding - chequeBounceChargesCollection
        );
        /*************** Cheque Bounce Charges Calculations******************/

        /*************** Penal Charges Calculations******************/
        const penalChargesOutstanding =
            parseInt(form.getState().values.penal_charges_outstanding) || 0;
        const penalChargesCollection =
            parseInt(form.getState().values.penal_charges_collection) || 0;
        const penalChargesWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((penalChargesOutstanding - penalChargesCollection) /
                        penalChargesOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "penal_charges_waiver_percentage",
            penalChargesWaiverPercentage || 0
        );

        form.change(
            "penal_charges_waiver",
            penalChargesOutstanding - penalChargesCollection
        );
        /*************** Penal Charges Calculations******************/

        /*************** Repo Charges Calculations******************/
        const repoChargesOutstanding =
            parseInt(form.getState().values.repo_charges_outstanding) || 0;
        const repoChargesCollection =
            parseInt(form.getState().values.repo_charges_collection) || 0;
        const repoChargesWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((repoChargesOutstanding - repoChargesCollection) /
                        repoChargesOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "repo_charges_waiver_percentage",
            repoChargesWaiverPercentage || 0
        );

        form.change(
            "repo_charges_waiver",
            repoChargesOutstanding - repoChargesCollection
        );
        /*************** Repo Charges Calculations******************/

        /*************** Legal Charges Calculations******************/
        const legalChargesOutstanding =
            parseInt(form.getState().values.legal_charges_outstanding) || 0;
        const legalChargesCollection =
            parseInt(form.getState().values.legal_charges_collection) || 0;
        const legalChargesWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((legalChargesOutstanding - legalChargesCollection) /
                        legalChargesOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "legal_charges_waiver_percentage",
            legalChargesWaiverPercentage || 0
        );

        form.change(
            "legal_charges_waiver",
            legalChargesOutstanding - legalChargesCollection
        );
        /*************** Legal Charges Calculations******************/

        /*************** Other Charges Calculations******************/
        const otherChargesOutstanding =
            parseInt(form.getState().values.other_charges_outstanding) || 0;
        const otherChargesCollection =
            parseInt(form.getState().values.other_charges_collection) || 0;
        const otherChargesWaiverPercentage =
            parseFloat(
                Math.max(
                    0,
                    ((otherChargesOutstanding - otherChargesCollection) /
                        otherChargesOutstanding) *
                        100
                ).toFixed(2)
            ) || 0;
        form.change(
            "other_charges_waiver_percentage",
            otherChargesWaiverPercentage || 0
        );

        form.change(
            "other_charges_waiver",
            otherChargesOutstanding - otherChargesCollection
        );
        /*************** Other Charges Calculations******************/

        let totalOutstanding =
            principalOverdueOutstanding +
            interestOverdueOutstanding +
            futurePrincipalOutstanding +
            brokenPeriodInterestOutstanding +
            blAmountOutstanding +
            chequeBounceChargesOutstanding +
            repoChargesOutstanding +
            legalChargesOutstanding +
            otherChargesOutstanding +
            penalChargesOutstanding;

        let totalCollection =
            principalOverdueCollection +
            interestOverdueCollection +
            futurePrincipalCollection +
            brokenPeriodInterestCollection +
            blAmountCollection +
            chequeBounceChargesCollection +
            repoChargesCollection +
            legalChargesCollection +
            otherChargesCollection +
            penalChargesCollection;

        let totalWaiver =
            principalOverdueOutstanding -
            principalOverdueCollection +
            (interestOverdueOutstanding - interestOverdueCollection) +
            (futurePrincipalOutstanding - futurePrincipalCollection) +
            (brokenPeriodInterestOutstanding - brokenPeriodInterestCollection) +
            (blAmountOutstanding - blAmountCollection) +
            (chequeBounceChargesOutstanding - chequeBounceChargesCollection) +
            (penalChargesOutstanding - penalChargesCollection) +
            (legalChargesOutstanding - legalChargesCollection) +
            (otherChargesOutstanding - otherChargesCollection) +
            (repoChargesOutstanding - repoChargesCollection);

        let totalWaiverPercentage =
            parseFloat(
                Math.max(0, (totalWaiver / totalOutstanding) * 100).toFixed(2)
            ) || 0;

        form.change("total_outstanding", totalOutstanding);
        form.change("total_collection", totalCollection);
        form.change("total_waiver", totalWaiver);
        form.change("total_waiver_percentage", totalWaiverPercentage || 0);

        let bvl =
            (principalOverdueOutstanding -
                principalOverdueCollection +
                (interestOverdueOutstanding - interestOverdueCollection) +
                (futurePrincipalOutstanding - futurePrincipalCollection) +
                (brokenPeriodInterestOutstanding -
                    brokenPeriodInterestCollection)) /
            (principalOverdueOutstanding +
                interestOverdueOutstanding +
                futurePrincipalOutstanding +
                brokenPeriodInterestOutstanding);

        let bvlAmount =
            principalOverdueOutstanding +
            interestOverdueOutstanding +
            futurePrincipalOutstanding +
            brokenPeriodInterestOutstanding;

        form.change("bvl", ((bvl || 0) * 100).toFixed(2));
        form.change(
            "avl",
            ((totalWaiver / totalOutstanding || 0) * 100).toFixed(2)
        );
        form.change("avl_amount", totalOutstanding.toFixed(2) || 0);
        form.change("bvl_amount", bvlAmount.toFixed(2) || 0);
    };

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Payment Receipt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-2">
                            <div className="col mb-3">
                                <label
                                    htmlFor="nameBasic"
                                    className="form-label"
                                >
                                    LAN No
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    id="nameBasic"
                                    className="form-control"
                                    placeholder="Enter LAN No"
                                    value={lanNo}
                                    onChange={(event) => {
                                        setLanNo(event.target.value);
                                    }}
                                />
                            </div>
                            <div className="col mb-3">
                                <label
                                    htmlFor="nameBasic"
                                    className="form-label"
                                >
                                    Receipt date
                                </label>
                                <input
                                    type="date"
                                    id="multicol-birthdate"
                                    className="form-control dob-picker"
                                    placeholder="YYYY-MM-DD"
                                    value={receiptDate}
                                    max={currentDate}
                                    onChange={(event) => {
                                        setReceiptDate(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row g-2">
                            <div className="col mb-0">
                                <label
                                    htmlFor="emailBasic"
                                    className="form-label"
                                >
                                    UNO Receipt No
                                </label>
                                <input
                                    type="text"
                                    id="nameBasic"
                                    className="form-control"
                                    placeholder="Enter UNO Receipt No"
                                    value={unoReceiptNo}
                                    onChange={(event) => {
                                        setUnoReceiptNo(event.target.value);
                                    }}
                                />
                            </div>
                            <div className="col mb-0">
                                <label
                                    htmlFor="emailBasic"
                                    className="form-label"
                                >
                                    Receipt amount
                                </label>
                                <div className="input-group">
                                    <span className="input-group-text">₹</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={receiptAmount}
                                        onChange={(event) => {
                                            setReceiptAmount(
                                                event.target.value
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                const details: PaymentDetails = {
                                    lan_no: lanNo,
                                    receipt_amount: parseInt(receiptAmount),
                                    receipt_date: new Date(receiptDate),
                                    uno_receipt_no: unoReceiptNo,
                                };
                                setPaymentDetailsArr([
                                    ...paymentDetailsArr,
                                    details,
                                ]);
                                setLanNo("");
                                setReceiptAmount("");
                                setReceiptDate("");
                                setUnoReceiptNo("");
                                setIsOpen(false);
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="py-3 mb-4">Add New Entry</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <Form
                                initialValues={initialFormValues}
                                onSubmit={onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Search LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={
                                                                otsId == null
                                                                    ? false
                                                                    : true
                                                            }
                                                            component={
                                                                AutocompleteField
                                                            }
                                                            name="search_lan_no"
                                                            icon="ti ti-hash"
                                                            placeholder="Search LAN No"
                                                            aria-label="Search LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            suggestions={
                                                                lanItems
                                                            }
                                                            renderItem={(
                                                                item: any
                                                            ) => (
                                                                <div className="suggestion-item">
                                                                    {
                                                                        item.lan_no
                                                                    }
                                                                </div>
                                                            )}
                                                            getKey={(
                                                                item: any
                                                            ) => item._id}
                                                            onInputChange={
                                                                handleLanInputChange
                                                            }
                                                            onSelected={(
                                                                value: any
                                                            ) => {
                                                                onLanSelected(
                                                                    form,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lan_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="LAN No"
                                                            aria-label="LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lan_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Customer Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="customer_name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Customer Name"
                                                            aria-label="Customer Name"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="customer_name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Zone
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="zone"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Zone"
                                                            aria-label="Zone"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="zone"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Region
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map-pin"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="region"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Region"
                                                            aria-label="Region"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="region"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Branch
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-smart-home"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="branch"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Branch"
                                                            aria-label="Branch"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="branch"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Portfolio
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="portfolio"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Portfolio"
                                                            aria-label="Portfolio"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="portfolio"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    POS
                                                </label>
                                                <div className="col-sm-2">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="pos"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="POS"
                                                            aria-label="POS"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="pos"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Penal Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="penal_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="penal_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Repo Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="repo_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="repo_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Legal Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="legal_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="legal_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Other Charges
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            initialValue={0}
                                                            disabled={true}
                                                            component="input"
                                                            name="other_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Other Charges"
                                                            aria-label="Other Charges"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="other_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    RC No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="rc_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="RC No"
                                                            aria-label="RC No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="rc_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Chassis No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-file-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="chassis_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Chassis No"
                                                            aria-label="Chassis No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="chassis_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Engine Number
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-engine"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="engine_number"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Engine Number"
                                                            aria-label="Engine Number"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="engine_number"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Make
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-layers-intersect"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="make"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Make"
                                                            aria-label="Make"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="make"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Model
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-star"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="model"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Model"
                                                            aria-label="Model"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="model"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Year of Manufacturing
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>

                                                        <Field
                                                            component="input"
                                                            name="mfg_year"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Year of Manufacturing"
                                                            aria-label="Year of Manufacturing"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        >
                                                            {({ input }) => (
                                                                <MaskedInput
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    className="form-control"
                                                                    {...input}
                                                                    mask="9999"
                                                                    placeholder="YYYY"
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Revised Focus Band
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="revised_focus_band"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Revised Focus Band"
                                                            aria-label="Revised Focus Band"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total EMI Collectable
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_emi_collectable"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total EMI Collectable"
                                                            aria-label="Total EMI Collectable"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    As on date DPD
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="as_on_date_dpd"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="As on date DPD"
                                                            aria-label="As on date DPD"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total Gross Collection
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_gross_collection"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total Gross Collection"
                                                            aria-label="Total Gross Collection"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    LRN Date
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lrn_date"
                                                            type="date"
                                                            id="multicol-birthdate"
                                                            className="form-control dob-picker"
                                                            placeholder="YYYY-MM-DD"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lrn_date"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Sec 17/ Sec 9 available:
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="sec_17_9"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Sec 138 available
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="sec_138"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Any litigation against case
                                                    available
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="arbitration_status"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-5">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    OTS - Closure
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="row row-gap-4">
                                                        <div className="col-md-12">
                                                            <label
                                                                className="col-form-label text-sm-end"
                                                                htmlFor="basic-default-name"
                                                            >
                                                                Simulation /
                                                                Foreclosure
                                                                generation Date
                                                            </label>
                                                            <Field
                                                                type="date"
                                                                component="input"
                                                                name="foreclosure_generation_date"
                                                                className="form-control dob-picker"
                                                                id="basic-icon-default-fullname"
                                                                placeholder=""
                                                                aria-label=""
                                                                aria-describedby="basic-icon-default-fullname2"
                                                            ></Field>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="table-responsive text-nowrap">
                                                                <table className="table table-bordered">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>
                                                                                Outstanding
                                                                                <br />
                                                                                Header
                                                                            </th>
                                                                            <th>
                                                                                Total
                                                                                <br />
                                                                                Outstanding
                                                                            </th>
                                                                            <th>
                                                                                Collection
                                                                            </th>
                                                                            <th>
                                                                                Waiver
                                                                            </th>
                                                                            <th>
                                                                                Waiver
                                                                                %
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>
                                                                                Principal
                                                                                Overdue
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="principal_overdue_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "principal_overdue_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="principal_overdue_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="principal_overdue_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "principal_overdue_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="principal_overdue_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="principal_overdue_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "principal_overdue_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    component="input"
                                                                                    name="principal_overdue_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "principal_overdue_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Interest
                                                                                Overdue
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="interest_overdue_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "interest_overdue_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="interest_overdue_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="interest_overdue_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "interest_overdue_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="interest_overdue_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="interest_overdue_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "interest_overdue_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="interest_overdue_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "interest_overdue_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Future
                                                                                Principal
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="future_principal_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "future_principal_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="future_principal_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="future_principal_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "future_principal_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="future_principal_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    component="input"
                                                                                    name="future_principal_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "future_principal_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="future_principal_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "future_principal_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Broken
                                                                                Period
                                                                                Interest
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="broken_period_interest_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "broken_period_interest_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="broken_period_interest_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="broken_period_interest_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "broken_period_interest_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="broken_period_interest_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="broken_period_interest_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "broken_period_interest_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="broken_period_interest_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "broken_period_interest_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                BL
                                                                                Amount
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="bl_amount_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "bl_amount_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="bl_amount_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="bl_amount_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "bl_amount_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="bl_amount_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="bl_amount_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "bl_amount_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="bl_amount_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "bl_amount_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Cheque
                                                                                Bounce
                                                                                Charges
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="cheque_bounce_charges_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "cheque_bounce_charges_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="cheque_bounce_charges_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="cheque_bounce_charges_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "cheque_bounce_charges_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="cheque_bounce_charges_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="cheque_bounce_charges_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "cheque_bounce_charges_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="cheque_bounce_charges_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "cheque_bounce_charges_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Penal
                                                                                Charges
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="penal_charges_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "penal_charges_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="penal_charges_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="penal_charges_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "penal_charges_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="penal_charges_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="penal_charges_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "penal_charges_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    component="input"
                                                                                    name="penal_charges_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "penal_charges_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Repo
                                                                                Charges
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="repo_charges_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "repo_charges_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="repo_charges_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="repo_charges_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "repo_charges_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="repo_charges_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="repo_charges_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "repo_charges_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    component="input"
                                                                                    name="repo_charges_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "repo_charges_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Legal
                                                                                Charges
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="legal_charges_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "legal_charges_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="legal_charges_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="legal_charges_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "legal_charges_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="legal_charges_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="legal_charges_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "legal_charges_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    component="input"
                                                                                    name="legal_charges_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "legal_charges_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Other
                                                                                Charges
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_outstanding"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="other_charges_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                                <Field
                                                                                    name="other_charges_collection"
                                                                                    render={({
                                                                                        meta,
                                                                                    }) => (
                                                                                        <div className="error">
                                                                                            {meta.error ? (
                                                                                                <span>
                                                                                                    {
                                                                                                        meta.error
                                                                                                    }
                                                                                                </span>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="other_charges_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    component="input"
                                                                                    name="other_charges_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "other_charges_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                Total
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    component="input"
                                                                                    name="total_outstanding"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "total_outstanding",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="total_collection"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "total_collection",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    component="input"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    name="total_waiver"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "total_waiver",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    type="number"
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    component="input"
                                                                                    name="total_waiver_percentage"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                    onChange={(
                                                                                        event: any
                                                                                    ) => {
                                                                                        handleAmountChange(
                                                                                            form,
                                                                                            "total_waiver_percentage",
                                                                                            event
                                                                                                .target
                                                                                                .value
                                                                                        );
                                                                                    }}
                                                                                    onKeyDown={(
                                                                                        e: any
                                                                                    ) => {
                                                                                        if (
                                                                                            e.key ===
                                                                                                "-" ||
                                                                                            e.key ===
                                                                                                "+" ||
                                                                                            e.key ===
                                                                                                "e"
                                                                                        ) {
                                                                                            e.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                ></Field>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                BVL
                                                                                -
                                                                                Rupees
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="bvl_amount"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                BVL
                                                                                -
                                                                                %
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="bvl"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                {" "}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                                AVL
                                                                                -
                                                                                Rupees
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="avl_amount"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                AVL
                                                                                -
                                                                                %
                                                                            </td>
                                                                            <td>
                                                                                <Field
                                                                                    style={{
                                                                                        maxWidth:
                                                                                            "100px",
                                                                                    }}
                                                                                    disabled={
                                                                                        true
                                                                                    }
                                                                                    type="number"
                                                                                    component="input"
                                                                                    name="avl"
                                                                                    className="form-control"
                                                                                    id="basic-icon-default-fullname"
                                                                                    placeholder=""
                                                                                    aria-label=""
                                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                                ></Field>
                                                                            </td>
                                                                            <td>
                                                                                {" "}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                        <div className="col-12">
                                                            <label
                                                                className="col-form-label text-sm-end"
                                                                htmlFor="multicol-birthdate"
                                                            >
                                                                Remarks
                                                            </label>

                                                            <Field
                                                                component="input"
                                                                name="remarks"
                                                                className="form-control"
                                                                id="basic-icon-default-fullname"
                                                                placeholder=""
                                                                aria-label=""
                                                                aria-describedby="basic-icon-default-fullname2"
                                                            />
                                                        </div>
                                                        {otsId && (
                                                            <>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        otsClosure.fc_letter as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                FC
                                                                                                Letter
                                                                                                with
                                                                                                Customer
                                                                                                Signature
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        otsClosure.challan as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Receipt
                                                                                                or
                                                                                                Deposit
                                                                                                Challan
                                                                                                Copy
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        otsClosure.waiver as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Waiver
                                                                                                Approval
                                                                                                Mail
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        otsClosure.simulation as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Simulation
                                                                                                with
                                                                                                Customer
                                                                                                Signature
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    FC Letter
                                                                    with
                                                                    Customer
                                                                    Signature*
                                                                </label>
                                                                <Field name="fc_letter">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="fc_letter"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Receipt or
                                                                    Deposit
                                                                    Challan
                                                                    Copy*
                                                                </label>
                                                                <Field name="challan">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="challan"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Waiver
                                                                    Approval
                                                                    Mail*
                                                                </label>
                                                                <br />
                                                                <Field name="waiver">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="waiver"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Simulation
                                                                    with
                                                                    Customer
                                                                    Signature*
                                                                </label>
                                                                <Field name="simulation">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="simulation"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Payment Receipting Detail
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="table-responsive text-nowrap">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Sr no
                                                                    </th>
                                                                    <th>
                                                                        LAN no
                                                                    </th>
                                                                    <th>
                                                                        Receipt
                                                                        date
                                                                    </th>
                                                                    <th>
                                                                        UNO
                                                                        Receipt
                                                                        No
                                                                    </th>
                                                                    <th>
                                                                        Receipt
                                                                        amount
                                                                    </th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {paymentDetailsArr.map(
                                                                    (
                                                                        details: PaymentDetails,
                                                                        index: number
                                                                    ) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    {index +
                                                                                        1}

                                                                                    .
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        details.lan_no
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <span className="badge bg-label-primary me-1">
                                                                                        {formatDateToDDMMYYYY(
                                                                                            details.receipt_date
                                                                                        )}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        details.uno_receipt_no
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    ₹
                                                                                    {
                                                                                        details.receipt_amount
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-icon btn-danger"
                                                                                        onClick={() => {
                                                                                            const updatedDetails =
                                                                                                [
                                                                                                    ...paymentDetailsArr,
                                                                                                ];
                                                                                            updatedDetails.splice(
                                                                                                index,
                                                                                                1
                                                                                            );
                                                                                            setPaymentDetailsArr(
                                                                                                updatedDetails
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span className="ti ti-x"></span>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-icon btn-primary mt-2"
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                paymentDetailsArr.length <
                                                                10
                                                            ) {
                                                                setLanNo(
                                                                    form.getState()
                                                                        .values
                                                                        .lan_no
                                                                );
                                                                setIsOpen(true);
                                                            }
                                                        }}
                                                    >
                                                        <span className="ti ti-plus"></span>
                                                    </button>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />

                                            <div className="row justify-content-end">
                                                <div className="col-sm-9">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        SAVE DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
                {otsId && (
                    <>
                        <h4 className="py-3 mb-1">Logs</h4>
                        <div className="row">
                            {logs.map((log: OtsClosureLogs) => {
                                return (
                                    <div className="col-md-3 col-sm-6 col-12 mb-4">
                                        <div className="card">
                                            <div className="card-body">
                                                <h6 className="mb-0">
                                                    {formatDateToDDMMYYYYWithTime(
                                                        log.createdAt
                                                    )}
                                                </h6>
                                                <p className="mb-0">
                                                    {log?.uid?.name}
                                                </p>
                                                <p className="mb-0">
                                                    {getStatusText(log?.status)}
                                                </p>
                                                <p className="mb-0">
                                                    {log.remarks}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
export default AddOtsClosure;
