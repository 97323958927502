import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import ErrorResponse from '../../interfaces/ErrorResponse';
import Yard from '../../interfaces/yard';

interface YardState {
  items: Yard[];
  loading: boolean | null;
  error: string | null;
  suggestions:Array<string>;
  yard: Yard;
  added: boolean | null,
  imported: null | boolean
}

export interface searchParam{
    search?: string,
    _id?: string
}


export const searchYard = createAsyncThunk('/searchYard', async (params:searchParam, { rejectWithValue }) => {
  try {
    const response = await api.get('/master/yard',{params: params});
    return response.data as Yard[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleYard = createAsyncThunk('/fetchSingleYard', async (params:searchParam, { rejectWithValue }) => {
  try {
    const response = await api.get('/master/yard',{params: params});
    return response.data as Yard[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addYard = createAsyncThunk('/addYard', async (values:Yard, { rejectWithValue }) => {
  try {
    const response = await api.post('/master/yard',values);
    return response.data as Yard;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateYard = createAsyncThunk('/updateYard', async (values:Yard, { rejectWithValue }) => {
  try {
    const response = await api.put('/master/yard',values);
    return response.data as Yard;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

interface bulkYardParams{
  file:any
}

export const importBulkYard = createAsyncThunk('/importBulkYard', async (value:bulkYardParams, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('yard', value.file);
    const response = await api.post('/master/import-yard', formData);
    return response.data as any;
  } catch (error:any) {
    return rejectWithValue(error.response.data.error);
  }
});

export const exportYard = createAsyncThunk(
  'exportYard',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/master/export-yard', {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'yard_data.xlsx');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      return { success: true };
    } catch (error:any) {
      console.error('Error downloading Yard data:', error);
      return rejectWithValue({
        message: error.response?.data || 'An error occurred while downloading RepoAgency data',
      });
    }
  }
);


const yardSlice = createSlice({
  name: 'yard',
  initialState: {
    items: [],
    loading: null,
    error: null,
    suggestions:[],
    yard:{} as Yard,
    added: null,
    imported: false
  } as YardState,
  reducers: {
    setYard:(state,action) =>{
        state.yard = action.payload;
    },
    clearYardList:(state) =>{
      state.items = [];
    },
    setYardAdded: (state, action) =>{
      state.added = action.payload;
    },
    setYardImported:(state,action) =>{
      state.imported = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchYard.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchYard.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(searchYard.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(fetchSingleYard.fulfilled, (state, action) => {
        state.loading = false;
        state.yard = action.payload[0];
      })
      .addCase(addYard.fulfilled, (state, action) => {
        state.items.unshift(action.payload);
        state.added = true;
      })
      .addCase(updateYard.fulfilled, (state, action) => {
        state.added = true;
      })
      .addCase(importBulkYard.fulfilled, (state, action) => {
        state.imported = true;
      })
      .addCase(importBulkYard.rejected, (state, action) => {
        state.imported = null;
        alert(action.payload || 'Invalid records found');
      })
  },
});

export const { setYard, clearYardList, setYardAdded, setYardImported } = yardSlice.actions;

export const selectYardItems = (state:{yard: YardState}) => state.yard.items;

export default yardSlice.reducer;
