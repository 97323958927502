import React, { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchProfile, setToken } from "../../redux/reducers/user";

const LoginSuccess: React.FC = () => {
    const token = useSelector((state: any) => state.user.token);
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();

    function getTokenFromUrl() {
        const params = new URLSearchParams(window.location.search);
        return params.get("token");
    }

    useEffect(() => {
        const token = getTokenFromUrl();
        if (token) {
            localStorage.setItem("token", token);
            dispatch(setToken(token));
        }
    }, []);

    useEffect(() => {
        if (token) {
            dispatch(fetchProfile());
            navigate("/");
        }
    }, [token]);

    return <div></div>;
};

export default LoginSuccess;
