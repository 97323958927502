import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import ErrorResponse from '../../interfaces/ErrorResponse';
import AIWISale from '../../interfaces/aiwi_sale';
import { AIWISaleLogs } from '../../interfaces/aiwi_sale_logs';

export interface AIWISaleState {
  items: AIWISale[];
  loading: boolean | null;
  error: string | null;
  aiwiSale: AIWISale,
  added: boolean | null;
  logs: AIWISaleLogs[];
}

interface searchParams{
  _id?: String
}

interface statusParams{
  status: 'approved' | 'rejected',
  _id: string,
  remarks?:string 
}

interface fetchAiwiSaleParams{
  regions?: string,
  export?:boolean
}

export const fetchAiwiSale = createAsyncThunk('/fetchAiwiSale', async (params: fetchAiwiSaleParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/aiwi-sale', {params: params});
    if(params.export){
      downloadCSV(response.data);
    }
    else{
      return response.data as AIWISale[];
    }
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleAiwiSale = createAsyncThunk('/fetchSingleAiwiSale', async (params:searchParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/aiwi-sale',{params:params});
    return response.data as AIWISale[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addAiwiSale = createAsyncThunk('/addAiwiSale', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else if (Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
        } 
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/aiwi-sale', formData);
    return response.data as AIWISale;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateAiwiSale = createAsyncThunk('/updateAiwiSale', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        }
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/aiwi-sale/update', formData);
    return response.data as AIWISale;
  } catch (error) {
    console.log(error);
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateAiwiSaleStatus = createAsyncThunk('/updateAiwiSaleStatus', async (values:statusParams, { rejectWithValue }) => {
  try {
    const response = await api.put('/aiwi-sale/update-status', values);
    return response.data as AIWISale;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchAiwiSaleLogs = createAsyncThunk('/fetchAiwiSaleLogs', async (id:string, { rejectWithValue }) => {
  try {
    const response = await api.get('/aiwi-sale/logs/' + id);
    return response.data as AIWISaleLogs[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

function downloadCSV(csvContent:any) {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'AIWI Sale Ticketing.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}


const aiwiSaleSlice = createSlice({
  name: 'aiwi-sale',
  initialState: {
    items: [],
    loading: null,
    error: null,
    aiwiSale: {} as AIWISale,
    added: null,
    logs: [],
    remarksUpdated: null
  } as AIWISaleState,
  reducers: {
    setAiwiSale: (state,action) =>{
      state.aiwiSale = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAiwiSale.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAiwiSale.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload){
          state.items = action.payload;
        }
      })
      .addCase(fetchAiwiSale.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(addAiwiSale.fulfilled, (state, action)=>{
        state.items.unshift(action.payload);
      }).addCase(addAiwiSale.rejected, (state, action) => {
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
        alert(state.error);
      }).addCase(fetchSingleAiwiSale.fulfilled, (state, action)=>{
        state.aiwiSale = action.payload[0];
      }).addCase(updateAiwiSaleStatus.fulfilled, (state,action)=>{
        let updatedAiwiSale = action.payload;
        state.items = state.items.map((aiwiSale: AIWISale) =>
          aiwiSale._id === updatedAiwiSale._id ? updatedAiwiSale : aiwiSale
        );
      }).addCase(updateAiwiSale.fulfilled, (state,action)=>{
        let updatedAiwiSale = action.payload;
        state.items = state.items.map((aiwiSale: AIWISale) =>
          aiwiSale._id === updatedAiwiSale._id ? updatedAiwiSale : aiwiSale
        );
      }).addCase(fetchAiwiSaleLogs.fulfilled, (state,action)=>{
        state.logs = action.payload;
      });
  },
});

export const { setAiwiSale } = aiwiSaleSlice.actions;
export default aiwiSaleSlice.reducer;