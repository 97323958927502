import React, { Dispatch, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../interfaces/user";
import OtpInput from "react-otp-input";
import {
    sendPasswordOtp,
    setSendOtpMsg,
    setVerifyPassword,
    setVerifyPasswordMsg,
    verifyPasswordOtp,
} from "../../redux/reducers/user";
import { useNavigate } from "react-router-dom";

const ChangePassword: React.FC = () => {
    const userData: User = useSelector((state: any) => state.user.userData);
    const navigate = useNavigate();
    const otpSentMsg: string = useSelector(
        (state: any) => state.user.otpSentMsg
    );
    const verifyPasswordMsg: string = useSelector(
        (state: any) => state.user.verifyPasswordMsg
    );
    const verifyPassword: string = useSelector(
        (state: any) => state.user.verifyPassword
    );
    const [otp, setOtp] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(setSendOtpMsg(""));
            dispatch(setVerifyPassword(false));
            dispatch(setVerifyPasswordMsg(""));
        };
    }, []);

    useEffect(() => {
        if (verifyPassword) {
            navigate("/");
        }
    }, [verifyPassword]);

    return (
        <div className="container-xxl">
            <div
                className="authentication-wrapper authentication-basic container-p-y"
                style={{ maxWidth: "400px; margin:auto" }}
            >
                <div className="authentication-inner py-4">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="mb-1 pt-2">Reset Password 🔒</h4>
                            <p className="mb-4">
                                for{" "}
                                <span className="fw-medium">
                                    {userData.email}
                                </span>
                            </p>
                            {/* <form
                                id="formAuthentication"
                                action="https://demos.pixinvent.com/vuexy-html-admin-template/html/horizontal-menu-template/auth-login-basic.html"
                                method="POST"
                            > */}
                            <div className="mb-3 form-password-toggle">
                                <label
                                    className="form-label"
                                    htmlFor="password"
                                >
                                    Old Password
                                </label>
                                <div className="input-group input-group-merge">
                                    <input
                                        type="password"
                                        id="password"
                                        onChange={(event: any) => {
                                            setOldPassword(event.target.value);
                                        }}
                                        className="form-control"
                                        name="password"
                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                        aria-describedby="password"
                                    />
                                    {/* <span className="input-group-text cursor-pointer">
                                            <i className="ti ti-eye-off"></i>
                                        </span> */}
                                </div>
                            </div>
                            <div className="mb-3 form-password-toggle">
                                <label
                                    className="form-label"
                                    htmlFor="confirm-password"
                                >
                                    New Password
                                </label>
                                <div className="input-group input-group-merge">
                                    <input
                                        onChange={(event: any) => {
                                            setPassword(event.target.value);
                                        }}
                                        type="password"
                                        id="confirm-password"
                                        className="form-control"
                                        name="confirm-password"
                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                        aria-describedby="password"
                                    />
                                    {/* <span className="input-group-text cursor-pointer">
                                            <i className="ti ti-eye-off"></i>
                                        </span> */}
                                </div>
                            </div>
                            <button
                                className="btn btn-primary d-grid w-100 mb-3"
                                onClick={() => {
                                    dispatch(sendPasswordOtp());
                                }}
                            >
                                Send OTP
                            </button>
                            <div style={{ textAlign: "center" }}>
                                {otpSentMsg}
                            </div>
                            <h4 className="mb-1 pt-2">
                                Two Step Verification 💬
                            </h4>
                            <p className="text-start mb-4">
                                We sent a verification code to your Email ID.
                                Enter the code from the Email in the field
                                below.
                                <span className="fw-medium d-block mt-2">
                                    {userData.email}
                                </span>
                            </p>
                            <p className="mb-0 fw-medium">
                                Type your 6 digit security code
                            </p>
                            <div className="mb-3">
                                <div className="auth-input-wrapper d-flex align-items-center justify-content-sm-between numeral-mask-wrapper">
                                    <OtpInput
                                        value={otp}
                                        onChange={setOtp}
                                        numInputs={6}
                                        inputType="number"
                                        renderSeparator={<span>-</span>}
                                        renderInput={(props) => (
                                            <input
                                                {...props}
                                                style={{ width: "2em" }}
                                                type="tel"
                                                className="form-control auth-input h-px-50 text-center numeral-mask mx-1 my-2"
                                            />
                                        )}
                                    />
                                </div>
                                <input type="hidden" name="otp" />
                            </div>
                            <button
                                className="btn btn-primary d-grid w-100 mb-3"
                                onClick={() => {
                                    dispatch(
                                        verifyPasswordOtp({
                                            oldPassword: oldPassword,
                                            password: password,
                                            otp: otp,
                                        })
                                    );
                                }}
                            >
                                Verify & Confirm Password
                            </button>
                            <div style={{ color: "red", textAlign: "center" }}>
                                {verifyPasswordMsg}
                            </div>
                            <div className="text-center">
                                Didn't get the code?
                                <a
                                    href="javascript:void(0);"
                                    onClick={() => {
                                        dispatch(sendPasswordOtp());
                                    }}
                                >
                                    Resend
                                </a>
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChangePassword;
