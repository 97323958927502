import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import ErrorResponse from '../../interfaces/ErrorResponse';
import RepoRelease from '../../interfaces/repo_release';
import { RepoReleaseLogs } from '../../interfaces/repo_release_logs';

export interface RepoReleaseState {
  items: RepoRelease[];
  loading: boolean | null;
  error: string | null;
  repoRelease: RepoRelease,
  added: boolean | null;
  logs: RepoReleaseLogs[];
}

interface searchParams{
  _id?: String
}

interface statusParams{
  status: 'approved' | 'rejected',
  _id: string,
  remarks?:string 
}

interface fetchRepoReleaseParams{
  regions?: string,
  export?:boolean,
  page?: number
}

export const fetchRepoRelease = createAsyncThunk('/fetchRepoRelease', async (params: fetchRepoReleaseParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo-release', {params: params});
    if(params.export){
      downloadCSV(response.data);
    }
    else{
      return response.data as RepoRelease[];
    }
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchRepoReleaseExport = createAsyncThunk('/fetchRepoReleaseExport', async (params: fetchRepoReleaseParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo-release', {params: params});
    downloadCSV(response.data);
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleRepoRelease = createAsyncThunk('/fetchSingleRepoRelease', async (params:searchParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo-release',{params:params});
    return response.data as RepoRelease[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addRepoRelease = createAsyncThunk('/addRepoRelease', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else if (Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
        } 
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/repo-release', formData);
    return response.data as RepoRelease;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateRepoRelease = createAsyncThunk('/updateRepoRelease', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        }
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/repo-release/update', formData);
    return response.data as RepoRelease;
  } catch (error) {
    console.log(error);
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateRepoReleaseStatus = createAsyncThunk('/updateRepoReleaseStatus', async (values:statusParams, { rejectWithValue }) => {
  try {
    const response = await api.put('/repo-release/update-status', values);
    return response.data as RepoRelease;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchRepoReleaseLogs = createAsyncThunk('/fetchRepoReleaseLogs', async (id:string, { rejectWithValue }) => {
  try {
    const response = await api.get('/repo-release/logs/' + id);
    return response.data as RepoReleaseLogs[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

function downloadCSV(csvContent:any) {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'Repo Release Ticketing.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}


const repoReleaseSlice = createSlice({
  name: 'repo-release',
  initialState: {
    items: [],
    loading: null,
    error: null,
    repoRelease: {} as RepoRelease,
    added: null,
    logs: [],
    remarksUpdated: null
  } as RepoReleaseState,
  reducers: {
    setRepoRelease: (state,action) =>{
      state.repoRelease = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRepoRelease.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRepoRelease.fulfilled, (state, action) => {
        state.loading = false;
       
        if(action.payload){
          if (action.meta.arg.page && action.meta.arg.page > 1) {
            state.items.push(...action.payload);
          } else {
            state.items = action.payload;
          }
        }
      })
      .addCase(fetchRepoRelease.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(addRepoRelease.fulfilled, (state, action)=>{
        state.items.unshift(action.payload);
      }).addCase(addRepoRelease.rejected, (state, action) => {
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
        alert(state.error);
      }).addCase(fetchSingleRepoRelease.fulfilled, (state, action)=>{
        state.repoRelease = action.payload[0];
      }).addCase(updateRepoReleaseStatus.fulfilled, (state,action)=>{
        let updatedRepoRelease = action.payload;
        state.items = state.items.map((repo: RepoRelease) =>
          repo._id === updatedRepoRelease._id ? updatedRepoRelease : repo
        );
      }).addCase(updateRepoRelease.fulfilled, (state,action)=>{
        let updatedRepoRelease = action.payload;
        state.items = state.items.map((repo: RepoRelease) =>
          repo._id === updatedRepoRelease._id ? updatedRepoRelease : repo
        );
      }).addCase(fetchRepoReleaseLogs.fulfilled, (state,action)=>{
        state.logs = action.payload;
      });
  },
});

export const { setRepoRelease } = repoReleaseSlice.actions;
export default repoReleaseSlice.reducer;