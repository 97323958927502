import React, { Dispatch, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Lan from "../../../../interfaces/lan";
import {
    addLan,
    fetchSingleLan,
    searchLan,
    setLan,
    setLanAdded,
    updateLan,
} from "../../../../redux/reducers/local-bid-lan";

const LocalBidLanAdd: React.FC = () => {
    const { lanId } = useParams();
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const lanAdded = useSelector((state: any) => state.localBidLan.added);
    const lan: Lan = useSelector((state: any) => state.localBidLan.lan);

    const [initialValues, setInitialValues] = useState({} as Lan);

    const onSubmit = (values: Lan) => {
        if (lanId) {
            dispatch(updateLan(values));
        } else {
            dispatch(addLan(values));
        }
    };

    useEffect(() => {
        if (lanId) {
            dispatch(fetchSingleLan({ _id: lanId, type: "local-bid" }));
        } else {
            dispatch(setLan({}));
        }
    }, [lanId, dispatch]);

    useEffect(() => {
        if (lanAdded) {
            dispatch(setLanAdded(false));
            navigate("/master/local-bid-lan");
        }
    }, [lanAdded]);

    useEffect(() => {
        if (lan) {
            let values = { ...lan };
            setInitialValues(values);
        }
    }, [lan]);

    const validate = (values: Lan) => {
        const errors: Partial<Record<keyof Lan, string>> = {};

        const requiredFields: (keyof Lan)[] = ["lan_no", "rc_no"];

        requiredFields.forEach(
            (field) => !values[field] && (errors[field] = "Required")
        );

        return errors;
    };

    return (
        <div>
            <h4 className="py-3 mb-4">{lanId ? "Edit LAN" : "Add LAN"}</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <Form
                                initialValues={initialValues}
                                onSubmit={onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="lan_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="LAN No"
                                                            aria-label="LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lan_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    RC No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        {/* <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span> */}
                                                        <Field
                                                            component="input"
                                                            name="rc_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="RC No"
                                                            aria-label="RC No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="rc_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />

                                            <div className="row justify-content-end">
                                                <div className="col-sm-9">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        SAVE DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-backdrop fade"></div>
        </div>
    );
};
export default LocalBidLanAdd;
