import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import Lan from '../../interfaces/lan';
import ErrorResponse from '../../interfaces/ErrorResponse';

interface LanState {
  items: Lan[];
  loading: boolean | null;
  error: string | null;
  suggestions:Array<string>;
  lan: Lan;
  added: boolean | null,
  regions: string[],
  imported:boolean | null
}

export interface searchParam{
    search?: string,
    _id?:string,
    page?:number,
    type?:string
    subType?:string
}


export const searchLan = createAsyncThunk('/searchLan', async (params:searchParam, { rejectWithValue }) => {
  try {
    const response = await api.get('/master/lan',{params: params});
    return response.data as Lan[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleLan = createAsyncThunk('/fetchSingleLan', async (params:searchParam, { rejectWithValue }) => {
  try {
    const response = await api.get('/master/lan',{params: params});
    return response.data as Lan[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addLan = createAsyncThunk('/addLan', async (values: Lan, { rejectWithValue }) => {
  try {
    const response = await api.post('/master/lan',values);
    return response.data as Lan;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateLan = createAsyncThunk('/updateLan', async (values: Lan, { rejectWithValue }) => {
  try {
    const response = await api.put('/master/lan',values);
    return response.data as Lan;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchRegions = createAsyncThunk('/fetchRegions', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/master/regions');
    return response.data as string[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

interface bulkLanParams{
  file:any
}

export const importBulkLan = createAsyncThunk('/importBulkLan', async (value:bulkLanParams, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('lan', value.file);
    const response = await api.post('/master/import-lan', formData);
    return response.data as any;
  } catch (error:any) {
    return rejectWithValue(error.response.data.error);
  }
});

export const exportLan = createAsyncThunk(
  'exportLan',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/master/export-lan', {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'lan_data.xlsx');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      return { success: true };
    } catch (error:any) {
      console.error('Error downloading LAN data:', error);
      return rejectWithValue({
        message: error.response?.data || 'An error occurred while downloading RepoAgency data',
      });
    }
  }
);


const lanSlice = createSlice({
  name: 'lan',
  initialState: {
    items: [],
    loading: null,
    error: null,
    suggestions:[],
    lan:{} as Lan,
    added: null,
    regions: [],
    imported: null
  } as LanState,
  reducers: {
    setLan:(state,action) =>{
        state.lan = action.payload;
    },
    clearLanList:(state) =>{
      state.items = [];
    },
    setLanAdded:(state,action) =>{
      state.added = action.payload;
    },
    setLanImported:(state,action) =>{
      state.imported = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchLan.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchLan.fulfilled, (state, action) => {
        state.loading = false;
        if (action.meta.arg.page && action.meta.arg.page > 1) {
          state.items.push(...action.payload);
        } else {
          state.items = action.payload;
        }
      })
      .addCase(fetchSingleLan.fulfilled, (state, action) => {
        state.loading = false;
        state.lan = action.payload[0];
      })
      .addCase(searchLan.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(addLan.fulfilled, (state, action) => {
        state.added = true
        state.items.unshift(action.payload);
      })
      .addCase(updateLan.fulfilled, (state, action) => {
        state.added = true
      })
      .addCase(fetchRegions.fulfilled, (state, action) => {
        state.regions = action.payload;
      })
      .addCase(importBulkLan.fulfilled, (state, action) => {
        state.imported = true;
      })
      .addCase(importBulkLan.rejected, (state, action) => {
        state.imported = null;
        alert(action.payload || 'Invalid records found');
      })
  },
});

export const { setLan, clearLanList, setLanAdded, setLanImported } = lanSlice.actions;
export const selectLanItems = (state: { lan: LanState }) => state.lan.items;
export default lanSlice.reducer;
