import React, { useState, useEffect, Dispatch } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, logout, logoutUser } from "../redux/reducers/user";
import { User } from "../interfaces/user";

const Header: React.FC = () => {
    const token = useSelector((state: any) => state.user.token);
    const userData: User = useSelector((state: any) => state.user.userData);
    const dispatch: Dispatch<any> = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        dispatch(logout());
        const newWindow = window.open(
            "https://icf.indostarcapital.com/adfs/ls?wa=wsignout1.0",
            "_blank"
        );

        // Check if the window was successfully created and has not been blocked
        if (newWindow) {
            // Optional: you may add some delay to ensure the logout is successful
            setTimeout(() => {
                newWindow.close();
            }, 1000); // Adjust delay as necessary
        } else {
            // If the popup was blocked, navigate to the URL in the current tab
            window.location.href =
                "https://icf.indostarcapital.com/adfs/ls?wa=wsignout1.0";
        }
    };

    useEffect(() => {
        if (token) {
            dispatch(fetchProfile());
        }
    }, [token]);

    if (!token) {
        return <div></div>;
    } else {
        return (
            <nav
                className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme"
                id="layout-navbar"
            >
                <div className="container">
                    <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-4">
                        <a href="" className="app-brand-link gap-2">
                            <img
                                src="assets/img/indostar-logo.png"
                                width="90"
                                alt=""
                            />
                        </a>
                        <a className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                            <i className="ti ti-x ti-sm align-middle"></i>
                        </a>
                    </div>
                    {/* <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0  d-xl-none  ">
                        <a className="nav-item nav-link px-0 me-xl-4">
                            <i className="ti ti-menu-2 ti-sm"></i>
                        </a>
                    </div> */}
                    {/* <button
                        className="layout-menu-toggle menu-link text-large ms-auto d-xl-none"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#layout-menu"
                    >
                        <i className="ti ti-x ti-sm align-middle"></i>
                    </button> */}
                    {/* <div
                        className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0  d-xl-none"
                        // data-bs-toggle="offcanvas"
                        // data-bs-target="#layout-menu"
                    >
                        <button className="nav-item nav-link px-0 me-xl-4">
                            <i className="ti ti-menu-2 ti-sm"></i>
                        </button>
                    </div> */}
                    <div
                        className="navbar-nav-right d-flex align-items-center"
                        id="navbar-collapse"
                    >
                        <div
                            className="flex-grow-1"
                            style={{ textAlign: "end" }}
                        >
                            <span className="fw-medium d-block">
                                {userData.name}
                            </span>
                            <small className="text-muted">
                                {userData?.role?.displayName}
                            </small>
                        </div>
                        <ul className="navbar-nav flex-row align-items-center ms-auto">
                            <li className="nav-item navbar-dropdown dropdown-user dropdown">
                                <a
                                    className="nav-link dropdown-toggle hide-arrow"
                                    data-bs-toggle="dropdown"
                                >
                                    <div className="avatar avatar-online">
                                        <img
                                            src="assets/img/avatars/1.png"
                                            alt=""
                                            className="h-auto rounded-circle"
                                        />
                                    </div>
                                </a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            href="pages-account-settings-account.html"
                                        >
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar avatar-online">
                                                        <img
                                                            src="assets/img/avatars/1.png"
                                                            alt=""
                                                            className="h-auto rounded-circle"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span className="fw-medium d-block">
                                                        {userData.name}
                                                    </span>
                                                    <small className="text-muted">
                                                        {
                                                            userData?.role
                                                                ?.displayName
                                                        }
                                                    </small>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <div className="dropdown-divider"></div>
                                    </li>
                                    <li>
                                        <Link
                                            to={"/change-password"}
                                            className="dropdown-item"
                                        >
                                            <i className="ti ti-key me-2 ti-sm"></i>
                                            <span className="align-middle">
                                                Change Password
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            onClick={handleLogout}
                                        >
                                            <i className="ti ti-logout me-2 ti-sm"></i>
                                            <span className="align-middle">
                                                Log Out
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
};

export default Header;
