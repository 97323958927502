import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useNavigate } from "react-router-dom";
import { login, setLoginError } from "../../redux/reducers/user";
import ReCAPTCHA from "react-google-recaptcha";

const Login: React.FC = () => {
    const recaptchaRef = useRef<typeof ReCAPTCHA | null>(null);

    const dispatch: Dispatch<any> = useDispatch();
    const token = useSelector((state: any) => state.user.token);
    const loginError = useSelector((state: any) => state.user.loginError);
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState<string>("");
    const [passwordError, setPasswordError] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [captchaValue, setCaptchaValue] = useState(null);
    const [showCaptcha, setShowCaptcha] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate("/");
        }
    }, [token]);

    useEffect(() => {
        if (loginError?.trim().length > 0) {
            setCaptchaValue(null);
            setShowCaptcha(false);
            setTimeout(() => {
                setShowCaptcha(true);
            }, 500);
        }
    }, [loginError]);

    const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Prevents the default form submit behavior

        if (!captchaValue) {
            alert("Captcha Invalid!");
            return;
        }
        setEmailError(email ? "" : "Email required");
        setPasswordError(password ? "" : "Password required");

        if (email && password) {
            dispatch(setLoginError(""));
            await dispatch(login({ email, password, captcha: captchaValue }));
        }
    };

    const onCaptchaChange = (value: any) => {
        setCaptchaValue(value);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if (name === "email") {
            setEmail(value);
        } else if (name === "password") {
            setPassword(value);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="authentication-wrapper authentication-cover authentication-bg">
            <div className="authentication-inner row">
                {/* Left Text */}
                <div className="d-none d-lg-flex col-lg-7 p-0">
                    <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                        <img
                            src="assets/img/illustrations/auth-login-illustration-light.png"
                            alt="auth-login-cover"
                            className="img-fluid my-5 auth-illustration"
                            data-app-light-img="illustrations/auth-login-illustration-light.png"
                            data-app-dark-img="illustrations/auth-login-illustration-dark.html"
                        />

                        <img
                            src="assets/img/illustrations/bg-shape-image-light.png"
                            alt="auth-login-cover"
                            className="platform-bg"
                            data-app-light-img="illustrations/bg-shape-image-light.png"
                            data-app-dark-img="illustrations/bg-shape-image-dark.html"
                        />
                    </div>
                </div>
                {/* /Left Text */}

                {/* Login */}
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                    <div className="w-px-400 mx-auto">
                        {/* Logo */}
                        <div className="app-brand mb-4">
                            <a href="#" className="app-brand-link gap-2">
                                <img
                                    src="assets/img/indostar-logo.png"
                                    width="100"
                                    alt=""
                                />
                            </a>
                        </div>
                        <h3 className="mb-1">Welcome to Indostar 👋</h3>
                        <p className="mb-4">
                            Please sign-in to your account and start the
                            adventure
                        </p>

                        <form
                            id="formAuthentication"
                            className="mb-3"
                            onSubmit={handleLogin}
                        >
                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">
                                    Email
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    id="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    autoFocus
                                />
                                <span
                                    style={{ color: "red", fontSize: "12px" }}
                                >
                                    {emailError}
                                </span>
                            </div>

                            <div className="mb-3 form-password-toggle">
                                <div className="d-flex justify-content-between">
                                    <label
                                        className="form-label"
                                        htmlFor="password"
                                    >
                                        Password
                                    </label>
                                </div>
                                <div className="input-group input-group-merge">
                                    <input
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        id="password"
                                        className="form-control"
                                        onChange={handleInputChange}
                                        name="password"
                                        placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                        aria-describedby="password"
                                    />
                                    <span
                                        className="input-group-text cursor-pointer"
                                        onClick={togglePasswordVisibility}
                                    >
                                        <i
                                            className={`ti ti-eye${
                                                showPassword ? "" : "-off"
                                            }`}
                                        ></i>
                                    </span>
                                </div>
                                <span
                                    style={{ color: "red", fontSize: "12px" }}
                                >
                                    {passwordError}
                                </span>
                            </div>
                            {showCaptcha && (
                                <ReCAPTCHA
                                    sitekey="6LcR250pAAAAABhiF-8emQoGA2kzFHs4bLW_ut5m"
                                    onChange={onCaptchaChange}
                                />
                            )}
                            <button
                                type="submit"
                                className="btn btn-primary d-grid w-100"
                            >
                                Sign in
                            </button>
                        </form>
                        <div className="text-center">OR</div>
                        <button
                            type="button"
                            className="btn btn-primary d-grid w-100"
                            onClick={() => {
                                window.location.href =
                                    process.env.REACT_APP_API_URL + "/login";
                            }}
                        >
                            Sign in with SSO
                        </button>
                        <span
                            style={{
                                color: "red",
                                display: "block",
                                textAlign: "center",
                            }}
                        >
                            {loginError}
                        </span>

                        {/* <p className="text-center">
                            <span>New on our platform?</span>
                            <a href="auth-register-cover.html">
                                <span>Create an account</span>
                            </a>
                        </p>

                        <div className="divider my-4">
                            <div className="divider-text">or</div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <a className="btn btn-icon btn-label-facebook me-3">
                                <i className="tf-icons fa-brands fa-facebook-f fs-5"></i>
                            </a>

                            <a className="btn btn-icon btn-label-google-plus me-3">
                                <i className="tf-icons fa-brands fa-google fs-5"></i>
                            </a>

                            <a className="btn btn-icon btn-label-twitter">
                                <i className="tf-icons fa-brands fa-twitter fs-5"></i>
                            </a>
                        </div> */}
                    </div>
                </div>
                {/* /Login */}
            </div>
        </div>
    );
};

export default Login;
