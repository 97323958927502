import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Link, useNavigate } from "react-router-dom";
import { User } from "../../interfaces/user";
import { formatDateToDDMMYYYY } from "../../helpers/date";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { fetchLocalBid } from "../../redux/reducers/local_bid";
import { LocalBid } from "../../interfaces/local_bid";

const LocalBidListing: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const localBidItems = useSelector((state: any) => state.localBid.items);
    const userData: User = useSelector((state: any) => state.user.userData);

    const [modalIsOpen, setIsOpen] = useState(false);

    const navigateToAdd = () => {
        navigate("/add-local-bid");
    };

    useEffect(() => {
        dispatch(fetchLocalBid({}));
    }, [dispatch]);

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    const getEMDMode = (mode: string) => {
        if (mode == "demand_draft") {
            return "Demand Draft";
        } else if (mode == "online") {
            return "Online";
        }
        return mode;
    };

    return (
        <>
            <div className="card">
                <div className="card-header-elements m-4 ms-auto">
                    {userData?.role?.rights.includes("add_local_bid") && (
                        <button
                            type="button"
                            onClick={navigateToAdd}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Add New Entry
                        </button>
                    )}
                    {userData?.role?.rights.includes("view_repo") && (
                        <button
                            type="button"
                            onClick={() => {
                                dispatch(fetchLocalBid({ export: true }));
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="fa-solid fa-file-export me-2"></span>
                            Export
                        </button>
                    )}
                </div>
                <div
                    className="table-responsive text-nowrap"
                    style={{ overflowX: "auto" }}
                >
                    <table className="table table-striped freezeHead">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>LAN No</th>
                                <th>RC No</th>
                                <th>Bidder Name</th>
                                <th>Bidder Contact</th>
                                <th>Mode of EMD</th>
                                <th>Bidder PAN Number</th>
                                <th>Bid Amount</th>
                                <th>EMD Amount</th>
                                <th>EMD Details (Payment Reference Number)</th>
                                <th>Remarks</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {localBidItems.map(
                                (localBid: LocalBid, index: number) => {
                                    return (
                                        <tr key={localBid._id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/view-local-bid/${localBid._id}`}
                                                >
                                                    {localBid.lan_no}
                                                </Link>
                                            </td>
                                            <td>{localBid.rc_no}</td>
                                            <td>{localBid.bidder_name}</td>
                                            <td>{localBid.bidder_contact}</td>
                                            <td>
                                                {getEMDMode(localBid.emd_mode)}
                                            </td>
                                            <td>{localBid.bidder_pan}</td>
                                            <td>{localBid.bid_amount}</td>
                                            <td>{localBid.emd_amount}</td>
                                            <td>{localBid.emd_details}</td>
                                            <td>{localBid.remarks}</td>
                                            <td>
                                                {getStatusText(localBid.status)}
                                            </td>
                                        </tr>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default LocalBidListing;
