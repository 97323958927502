import React, { Dispatch, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate } from "react-router-dom";
import AutocompleteField from "../../components/AutoCompleteField";
import { useDispatch, useSelector } from "react-redux";
import { searchLan, selectLanItems } from "../../redux/reducers/lan";
import Lan from "../../interfaces/lan";
import { fetchProfile } from "../../redux/reducers/user";
import {
    addLegalRequest,
    setLegalRequest,
} from "../../redux/reducers/legal-request";
import LegalRequest from "../../interfaces/legal_request";

const AddLegalRequest: React.FC = () => {
    const navigate = useNavigate();
    const lanItems = useSelector(selectLanItems);
    const legalRequest = useSelector(
        (state: any) => state.legalRequest.legalRequest
    );

    const dispatch: Dispatch<any> = useDispatch();

    const [initialFormValues, setInitialFormValues] = useState({});

    useEffect(() => {
        const values: any = {} as LegalRequest;
        Object.keys(legalRequest as keyof LegalRequest).forEach((key) => {
            values[key] = legalRequest[key];
        });
        setInitialFormValues(values);
    }, [legalRequest]);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    const onSubmit = (values: LegalRequest) => {
        dispatch(addLegalRequest(values));
        navigate("/legal-request-listing");
    };

    useEffect(() => {
        dispatch(setLegalRequest({}));
    }, []);

    const validate = (values: LegalRequest) => {
        const errors: Partial<Record<keyof LegalRequest, string>> = {};

        const requiredFields: (keyof LegalRequest)[] = [
            "lan_no",
            "borrower_name",
            "borrower_address",
            "borrower_contact",
            "gurantor_name",
            "gurantor_address",
            "gurantor_contact",
            "vehicle_availability",
            "legal_request",
            "priority",
            "remarks",
        ];

        requiredFields.forEach((field) => {
            if (values[field] === undefined || values[field] === null) {
                errors[field] = "Required";
            }
        });

        if (
            values.gurantor_name &&
            !/^[a-zA-Z\s']+$/.test(values.gurantor_name)
        ) {
            errors.gurantor_name =
                "Gurantor name can only contain alphabets, spaces, and apostrophes";
        }

        if (
            values.borrower_contact &&
            !/^\d{10}$/.test(values.borrower_contact.toString())
        ) {
            errors.borrower_contact =
                "Borrower contact must be exactly 10 digits";
        }

        if (
            values.gurantor_contact &&
            !/^\d{10}$/.test(values.gurantor_contact.toString())
        ) {
            errors.gurantor_contact =
                "Gurantor contact must be exactly 10 digits";
        }

        return errors;
    };
    const setFormValue = (form: any, key: string, value: any) => {
        form.change(key, value);
    };
    const handleLanInputChange = (value: any) => {
        dispatch(
            searchLan({
                search: value,
                type: "legal-request",
            })
        );
    };

    const onLanSelected = (form: any, value: Lan) => {
        setFormValue(form, "borrower_name", value.customer_name);
        setFormValue(form, "priority", value.priority);
        setFormValue(form, "lan_no", value.lan_no);
    };

    return (
        <>
            <h4 className="py-3 mb-4">Add New Entry</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <Form
                                initialValues={initialFormValues}
                                onSubmit={onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Search LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            component={
                                                                AutocompleteField
                                                            }
                                                            name="search_lan_no"
                                                            icon="ti ti-hash"
                                                            placeholder="Search LAN No"
                                                            aria-label="Search LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            suggestions={
                                                                lanItems
                                                            }
                                                            renderItem={(
                                                                item: any
                                                            ) => (
                                                                <div className="suggestion-item">
                                                                    {
                                                                        item.lan_no
                                                                    }
                                                                </div>
                                                            )}
                                                            getKey={(
                                                                item: any
                                                            ) => item._id}
                                                            onInputChange={
                                                                handleLanInputChange
                                                            }
                                                            onSelected={(
                                                                value: any
                                                            ) => {
                                                                onLanSelected(
                                                                    form,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lan_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="LAN No"
                                                            aria-label="LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lan_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Borrower Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="borrower_name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Borrower Name"
                                                            aria-label="Borrower Name"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="borrower_name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Borrower Address
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map"></i>
                                                        </span>
                                                        <Field
                                                            component="input"
                                                            name="borrower_address"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Borrower Address"
                                                            aria-label="Borrower Address"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="borrower_address"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Borrower Contact Number
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map-pin"></i>
                                                        </span>
                                                        <Field
                                                            type="number"
                                                            onKeyDown={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    e.key ===
                                                                        "-" ||
                                                                    e.key ===
                                                                        "+" ||
                                                                    e.key ===
                                                                        "e"
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            component="input"
                                                            name="borrower_contact"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Borrower Contact Number"
                                                            aria-label="Borrower Contact Number"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="borrower_contact"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Gurantor Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-smart-home"></i>
                                                        </span>
                                                        <Field
                                                            component="input"
                                                            name="gurantor_name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Gurantor Name"
                                                            aria-label="Gurantor Name"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="gurantor_name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Gurantor Address
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            component="input"
                                                            name="gurantor_address"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Guarantor Address"
                                                            aria-label="Guarantor Address"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="gurantor_address"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Gurantor Contact
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            component="input"
                                                            type="number"
                                                            onKeyDown={(
                                                                e: any
                                                            ) => {
                                                                if (
                                                                    e.key ===
                                                                        "-" ||
                                                                    e.key ===
                                                                        "+" ||
                                                                    e.key ===
                                                                        "e"
                                                                ) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            name="gurantor_contact"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Guarantor Contact"
                                                            aria-label="Guarantor Contact"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="gurantor_contact"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Vehicle Availability
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            component="select"
                                                            name="vehicle_availability"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Vehicle Availability"
                                                            aria-label="Vehicle Availability"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                            >
                                                                Select
                                                            </option>
                                                            <option value="yes">
                                                                Yes
                                                            </option>
                                                            <option value="no">
                                                                No
                                                            </option>
                                                        </Field>
                                                    </div>
                                                    <Field
                                                        name="vehicle_availability"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Legal Request
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            component="select"
                                                            name="legal_request"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Legal Request"
                                                            aria-label="Legal Request"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        >
                                                            <option
                                                                value=""
                                                                disabled
                                                            >
                                                                Select
                                                            </option>
                                                            <option value="Sec 9 Initiation">
                                                                Sec 9 Initiation
                                                            </option>
                                                            <option value="Sec 17 Initiation">
                                                                Sec 17
                                                                Initiation
                                                            </option>
                                                            <option value="Private complaint">
                                                                Private
                                                                complaint
                                                            </option>
                                                            <option value="Sec 138">
                                                                Sec 138
                                                            </option>
                                                        </Field>
                                                    </div>
                                                    <Field
                                                        name="legal_request"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Priority as per OPN POS
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="priority"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Priority as per OPN POS"
                                                            aria-label="Priority as per OPN POS"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="priority"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Remarks
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            component="input"
                                                            name="remarks"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Remarks"
                                                            aria-label="Remarks"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="remarks"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />

                                            <div className="row justify-content-end">
                                                <div className="col-sm-9">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        SAVE DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AddLegalRequest;
