import { combineReducers } from 'redux';
import userReducer from './user';
import repoReducer from './repo';
import lanReducer from './lan';
import aiwiLanReducer from './aiwi-lan';
import localBidLanReducer from './local-bid-lan';
import otsApprovalLanReducer from './ots-approval-lan';
import legalRequestLanReducer from './legal-request-lan';
import yardReducer from './yard';
import agencyReducer from './agency';
import repoReleaseReducer from './repo-release';
import localBid from './local_bid';
import otsApproval from './ots-approval';
import otsClosure from './ots-closure';
import aiwiSale from './aiwi-sale';
import legalRequest from './legal-request';

const rootReducer = combineReducers({
    user: userReducer,
    repo: repoReducer,
    lan: lanReducer,
    localBidLan: localBidLanReducer,
    otsApprovalLan: otsApprovalLanReducer,
    aiwiLan: aiwiLanReducer,
    legalRequestLan: legalRequestLanReducer,
    yard: yardReducer,
    agency: agencyReducer,
    repoRelease: repoReleaseReducer,
    localBid: localBid,
    otsApproval: otsApproval,
    otsClosure: otsClosure,
    aiwiSale: aiwiSale,
    legalRequest: legalRequest
});

export default rootReducer;