import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import AppRouter from './navigation/router';

import Header from './components/Header';
import Footer from './components/Footer';
import Menu from './components/Menu';

const App: React.FC = () => {

  return (
    <Provider store={store}>
    {/* <div className="App"> */}
      <div className="App layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
        <div className="layout-container">
          {/* Layout container */}
          {<Header />}
          <div className="layout-page">
            {/* Content wrapper */}
            <div className="content-wrapper">
              {/* Menu */}
              <Menu />
              {/* / Menu */}
             <AppRouter />
              {/* Footer */}
              <Footer />
              {/* / Footer */}

              <div className="content-backdrop fade"></div>
            </div>
            {/*/ Content wrapper */}
          </div>
          {/*/ Layout container */}
        </div>
      </div>
    {/* </div> */}
  </Provider>
  );
}

export default App;
