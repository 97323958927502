import React, { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDateToDDMMYYYY } from "../../../../helpers/date";
import Lan from "../../../../interfaces/lan";
import { fetchSingleLan } from "../../../../redux/reducers/local-bid-lan";

const LocalBidLanView: React.FC = () => {
    let dispatch: Dispatch<any> = useDispatch();
    const { lanId } = useParams();
    let lan: Lan = useSelector((state: any) => state.localBidLan.lan);

    useEffect(() => {
        dispatch(fetchSingleLan({ _id: lanId, type: "local-bid" }));
    }, [lanId]);

    return (
        <div>
            <h4 className="py-3 mb-4">LAN Record</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <form>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        LAN No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {lan.lan_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        RC No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {lan.rc_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocalBidLanView;
