import React, { Dispatch, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setToken } from '../redux/reducers/user';

const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const token = localStorage.getItem('token');
  if (token) {
    return <div className="container flex-grow-1 container-p-y">{children}</div>;
  }
  else{
    dispatch(setToken(null));
    return <Navigate to="/login" />;
  }
};

export default RequireAuth;