import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../services/instance';
import ErrorResponse from '../../interfaces/ErrorResponse';
import { Role, User } from '../../interfaces/user';

export interface LoginCredentials {
  email: string;
  password: string;
  captcha: any;
}

export const login = createAsyncThunk('user/auth', async (credentials: LoginCredentials, { rejectWithValue }) => {
    try {
      const response = await api.post('/user/auth',credentials);
      return response.data as User;
    } catch (error) {
      return rejectWithValue({
        message: (error as any)?.response?.data || 'An error occurred',
      });
    }
});

export const fetchProfile = createAsyncThunk('/fetchProfile', async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/user/profile');
      return response.data[0] as User;
    } catch (error) {
      return rejectWithValue({
        message: (error as any)?.response?.data || 'An error occurred',
      });
    }
});

interface fetchUserParams{
  _id?: string
}

export const fetchUsers = createAsyncThunk('/fetchUsers', async (params:fetchUserParams, { rejectWithValue }) => {
    try {
      const response = await api.get('/user',{params: params});
      return response.data as User[];
    } catch (error) {
      return rejectWithValue({
        message: (error as any)?.response?.data || 'An error occurred',
      });
    }
});

export const fetchSingleUser = createAsyncThunk('/fetchSingleUser', async (params:fetchUserParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/user',{params: params});
    return response.data as User[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const createUser = createAsyncThunk('/createUser', async (values: User, { rejectWithValue }) => {
  try {
    const response = await api.post('/user/create',values);
    return response.data;
  } catch (error) {
    return rejectWithValue(
    (error as any)?.response?.data || 'An error occurred',
    );
  }
});

export const updateUser = createAsyncThunk('/updateUser', async (values: User, { rejectWithValue }) => {
  try {
    const response = await api.put('/user',values);
    return response.data as User;
  } catch (error) {
    return rejectWithValue(
      (error as any)?.response?.data || 'An error occurred',
    );
  }
});

export const fetchRoles = createAsyncThunk('/fetchRoles', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/master/role',);
    return response.data as Role[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const sendPasswordOtp = createAsyncThunk('/sendPasswordOtp', async (_, { rejectWithValue }) => {
  try {
    const response = await api.post('/user/password-otp-send');
    return response.data;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

interface verifyOtpParams{
  oldPassword:string,
  password:string,
  otp:string
}

export const verifyPasswordOtp = createAsyncThunk('/verifyPasswordOtp', async (values: verifyOtpParams, { rejectWithValue }) => {
  try {
    const response = await api.post('/user/password-otp-verify',values);
    return response.data;
  } catch (error:any) {
    return rejectWithValue(
      error.response?.data as string,
    );
  }
});

export const importBulkUser = createAsyncThunk('/importBulkUser', async (value:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('user', value.file);
    const response = await api.post('/master/import-user', formData);
    return response.data as any;
  } catch (error:any) {
    return rejectWithValue(error.response.data.error);
  }
});

export const logoutUser = createAsyncThunk('logoutUser', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('/logout');
    return response.data as any;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});


  
interface UserState {
  token:string | null,
  loading: boolean,
  userData: User,
  error:string | null,
  loginError:string | null,
  items: User[],
  user: User,
  added: boolean | null,
  roles: Role[],
  otpSentMsg: string,
  otpVerified:string,
  verifyPassword:boolean | null,
  verifyPasswordMsg:string,
  imported:boolean | null
}

const userSlice = createSlice({
    name: 'user',
    initialState: {
      token: null as string | null,
      loading: false,
      userData: {} as User,
      error:null,
      loginError: null,
      items:[] as User[],
      user: {} as User,
      added: null,
      roles: [] as Role[],
      otpSentMsg : '',
      verifyPassword:null,
      verifyPasswordMsg:'',
      imported: null
    } as UserState,
    reducers: {
      setToken: (state, action) => {
        state.token = action.payload;
      },
      logout:(state) =>{
        localStorage.clear();
        state.token = null;
      },
      setUserAdded: (state,action) =>{
        state.added = action.payload;
      },
      setUser:(state,action)=>{
        state.user = action.payload;
      },
      setSendOtpMsg:(state,action)=>{
        state.otpSentMsg = action.payload;
      },
      setVerifyPassword:(state,action)=>{
        state.verifyPassword = action.payload;
      },
      setVerifyPasswordMsg:(state,action)=>{
        state.verifyPasswordMsg = action.payload;
      },
      setLoginError: (state,action) =>{
        state.loginError = action.payload;
      },
      setUserImported:(state,action) =>{
        state.imported = action.payload;
      }
    },
    extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
        state.token = action.payload.token;
        localStorage.setItem('token', state.token || '');
        state.loginError = '';
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.loginError = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.userData = action.payload;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.items = action.payload;
      })
      .addCase(fetchSingleUser.fulfilled, (state, action) => {
        state.user = action.payload[0];
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.added = true;
        state.items.push(action.payload);
      })
      .addCase(createUser.rejected, (state, action) => {
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
        alert(state.error);
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.added = true;
      })
      .addCase(updateUser.rejected, (state, action:any) => {          
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
        alert(state.error);
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.roles = action.payload;
      })
      .addCase(sendPasswordOtp.fulfilled, (state, action) => {
        state.otpSentMsg = 'OTP sent'
      })
      .addCase(verifyPasswordOtp.fulfilled, (state, action) => {
        state.verifyPassword = true;
      })
      .addCase(verifyPasswordOtp.rejected, (state, action) => {
        state.verifyPassword = false;
        state.verifyPasswordMsg = action.payload as string;
      })
      .addCase(importBulkUser.fulfilled, (state, action) => {
        state.imported = true;
      }).addCase(importBulkUser.rejected, (state, action) => {
        state.imported = null;
        alert(action.payload || 'Invalid records found');
      });
    },
});

export const { setToken, logout, setUserAdded,setUser, setSendOtpMsg, setVerifyPassword, setVerifyPasswordMsg, setLoginError, setUserImported } = userSlice.actions;

export default userSlice.reducer;