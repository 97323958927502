import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { fetchRepos } from "../../redux/reducers/repo";
import Repo from "../../interfaces/repo";

function Dashboard() {
    const repos: Repo[] = useSelector((state: any) => state.repo.items);
    const loading: boolean | null = useSelector(
        (state: any) => state.repo.loading
    );

    const dispatch: Dispatch<any> = useDispatch();

    return (
        <div className="row">
            {/* Website Analytics */}
            <div className="col-lg-6 mb-4">
                <div
                    className="swiper-container swiper-container-horizontal swiper swiper-card-advance-bg"
                    id="swiper-with-pagination-cards"
                >
                    <div className="swiper-wrapper">
                        {/* Slide 1 */}
                        <div className="swiper-slide">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="text-white mb-0 mt-2">
                                        Website Analytics
                                    </h5>
                                    <small>Total 28.5% Conversion Rate</small>
                                </div>
                                <div className="row">
                                    <div className="col-lg-7 col-md-9 col-12 order-2 order-md-1">
                                        <h6 className="text-white mt-0 mt-md-3 mb-3">
                                            Traffic
                                        </h6>
                                        <div className="row">
                                            <div className="col-6">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="d-flex mb-4 align-items-center">
                                                        <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                            28%
                                                        </p>
                                                        <p className="mb-0">
                                                            Sessions
                                                        </p>
                                                    </li>
                                                    <li className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                            1.2k
                                                        </p>
                                                        <p className="mb-0">
                                                            Leads
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-6">
                                                <ul className="list-unstyled mb-0">
                                                    <li className="d-flex mb-4 align-items-center">
                                                        <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                            3.1k
                                                        </p>
                                                        <p className="mb-0">
                                                            Page Views
                                                        </p>
                                                    </li>
                                                    <li className="d-flex align-items-center mb-2">
                                                        <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                            12%
                                                        </p>
                                                        <p className="mb-0">
                                                            Conversions
                                                        </p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-3 col-12 order-1 order-md-2 my-4 my-md-0 text-center">
                                        <img
                                            src="assets/img/illustrations/card-website-analytics-1.png"
                                            alt="Website Analytics"
                                            width="170"
                                            className="card-website-analytics-img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Slide 2 */}
                        <div className="swiper-slide">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="text-white mb-0 mt-2">
                                        Website Analytics
                                    </h5>
                                    <small>Total 28.5% Conversion Rate</small>
                                </div>
                                <div className="col-lg-7 col-md-9 col-12 order-2 order-md-1">
                                    <h6 className="text-white mt-0 mt-md-3 mb-3">
                                        Spending
                                    </h6>
                                    <div className="row">
                                        <div className="col-6">
                                            <ul className="list-unstyled mb-0">
                                                <li className="d-flex mb-4 align-items-center">
                                                    <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                        12h
                                                    </p>
                                                    <p className="mb-0">
                                                        Spend
                                                    </p>
                                                </li>
                                                <li className="d-flex align-items-center mb-2">
                                                    <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                        127
                                                    </p>
                                                    <p className="mb-0">
                                                        Order
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="list-unstyled mb-0">
                                                <li className="d-flex mb-4 align-items-center">
                                                    <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                        18
                                                    </p>
                                                    <p className="mb-0">
                                                        Order Size
                                                    </p>
                                                </li>
                                                <li className="d-flex align-items-center mb-2">
                                                    <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                        2.3k
                                                    </p>
                                                    <p className="mb-0">
                                                        Items
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-3 col-12 order-1 order-md-2 my-4 my-md-0 text-center">
                                    <img
                                        src="assets/img/illustrations/card-website-analytics-2.png"
                                        alt="Website Analytics"
                                        width="170"
                                        className="card-website-analytics-img"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Slide 3 */}
                        <div className="swiper-slide">
                            <div className="row">
                                <div className="col-12">
                                    <h5 className="text-white mb-0 mt-2">
                                        Website Analytics
                                    </h5>
                                    <small>Total 28.5% Conversion Rate</small>
                                </div>
                                <div className="col-lg-7 col-md-9 col-12 order-2 order-md-1">
                                    <h6 className="text-white mt-0 mt-md-3 mb-3">
                                        Revenue Sources
                                    </h6>
                                    <div className="row">
                                        <div className="col-6">
                                            <ul className="list-unstyled mb-0">
                                                <li className="d-flex mb-4 align-items-center">
                                                    <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                        268
                                                    </p>
                                                    <p className="mb-0">
                                                        Direct
                                                    </p>
                                                </li>
                                                <li className="d-flex align-items-center mb-2">
                                                    <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                        62
                                                    </p>
                                                    <p className="mb-0">
                                                        Referral
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-6">
                                            <ul className="list-unstyled mb-0">
                                                <li className="d-flex mb-4 align-items-center">
                                                    <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                        890
                                                    </p>
                                                    <p className="mb-0">
                                                        Organic
                                                    </p>
                                                </li>
                                                <li className="d-flex align-items-center mb-2">
                                                    <p className="mb-0 fw-medium me-2 website-analytics-text-bg">
                                                        1.2k
                                                    </p>
                                                    <p className="mb-0">
                                                        Campaign
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-3 col-12 order-1 order-md-2 my-4 my-md-0 text-center">
                                    <img
                                        src="assets/img/illustrations/card-website-analytics-3.png"
                                        alt="Website Analytics"
                                        width="170"
                                        className="card-website-analytics-img"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
            {/* /Website Analytics */}

            {/* Earning Reports */}
            <div className="col-lg-6 mb-4">
                <div className="card h-100">
                    <div className="card-header pb-0 d-flex justify-content-between mb-lg-n4">
                        <div className="card-title mb-0">
                            <h5 className="mb-0">Latest Added</h5>
                        </div>
                        <div className="dropdown">
                            <button
                                className="btn p-0"
                                type="button"
                                id="earningReportsId"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <i className="ti ti-dots-vertical ti-sm text-muted"></i>
                            </button>
                            <div
                                className="dropdown-menu dropdown-menu-end"
                                aria-labelledby="earningReportsId"
                            >
                                <a className="dropdown-item">View More</a>
                            </div>
                        </div>
                    </div>
                    <div className="card-body mt-5">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Unique No</th>
                                        <th>Creation Date</th>
                                        <th>Customer Name</th>
                                        <th>Branch</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {/* Sample rows, replace with your dynamic data */}
                                    <tr>
                                        <td>1</td>
                                        <td>
                                            <a href="">IV1165</a>
                                        </td>
                                        <td>2 Dec 2023</td>
                                        <td>Name Surname</td>
                                        <td>Mumbai</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>
                                            <a href="">IV1165</a>
                                        </td>
                                        <td>2 Dec 2023</td>
                                        <td>Name Surname</td>
                                        <td>Mumbai</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>
                                            <a href="">IV1165</a>
                                        </td>
                                        <td>2 Dec 2023</td>
                                        <td>Name Surname</td>
                                        <td>Mumbai</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>
                                            <a href="">IV1165</a>
                                        </td>
                                        <td>2 Dec 2023</td>
                                        <td>Name Surname</td>
                                        <td>Mumbai</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Earning Reports */}
        </div>
    );
}

export default Dashboard;
