import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Link, useNavigate } from "react-router-dom";
import { User } from "../../interfaces/user";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
} from "../../helpers/date";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { fetchRegions } from "../../redux/reducers/lan";
import {
    fetchRepoRelease,
    fetchRepoReleaseExport,
    updateRepoReleaseStatus,
} from "../../redux/reducers/repo-release";
import RepoRelease from "../../interfaces/repo_release";
import InfiniteScroll from "react-infinite-scroll-component";

const RepoReleaseListing: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const repoReleaseItems = useSelector(
        (state: any) => state.repoRelease.items
    );
    const [page, setPage] = useState(1);
    const userData: User = useSelector((state: any) => state.user.userData);
    const regions: string[] = useSelector((state: any) => state.lan.regions);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [remark, setRemark] = useState("");
    const [selectedRepo, setRepo] = useState({} as RepoRelease);

    const navigateToAdd = () => {
        navigate("/add-repo-release");
    };

    useEffect(() => {
        fetchRepoReleaseData();
        dispatch(fetchRegions());
    }, [dispatch]);

    const fetchRepoReleaseData = async () => {
        dispatch(fetchRepoRelease({ page: page }));
        setPage((prevPage) => prevPage + 1);
    };

    const handleRegionChange = (values: any) => {
        let regions = values.map((value: any) => value.value);
        regions = regions.join(",");

        dispatch(fetchRepoRelease({ regions: regions }));
    };

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Reject Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            onChange={(event: any) => {
                                setRemark(event.target.value);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (remark.trim().length > 0) {
                                    dispatch(
                                        updateRepoReleaseStatus({
                                            status: "rejected",
                                            remarks: remark,
                                            _id: selectedRepo._id,
                                        })
                                    );

                                    setIsOpen(false);
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={showConfirmModal}
                    onHide={() => {
                        setShowConfirmModal(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Approve Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to approve the entry?</Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setShowConfirmModal(false);
                            }}
                        >
                            No
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                dispatch(
                                    updateRepoReleaseStatus({
                                        status: "approved",
                                        _id: selectedRepo._id,
                                    })
                                );

                                setShowConfirmModal(false);
                            }}
                        >
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="card">
                <div className="card-header-elements m-4 ms-auto">
                    <Select
                        onChange={handleRegionChange}
                        isMulti
                        closeMenuOnSelect={false}
                        options={regions.map((region) => ({
                            value: region,
                            label: region,
                        }))}
                        placeholder="Filter Regions"
                    />
                    {userData?.role?.rights.includes("add_repo_release") && (
                        <button
                            type="button"
                            onClick={navigateToAdd}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Add New Entry
                        </button>
                    )}
                    {userData?.role?.rights.includes("view_repo") && (
                        <button
                            type="button"
                            onClick={() => {
                                dispatch(
                                    fetchRepoReleaseExport({ export: true })
                                );
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="fa-solid fa-file-export me-2"></span>
                            Export
                        </button>
                    )}
                </div>
                <div
                    className="table-responsive text-nowrap"
                    style={{ overflowX: "auto" }}
                >
                    <InfiniteScroll
                        dataLength={repoReleaseItems.length}
                        next={fetchRepoReleaseData}
                        hasMore={true}
                        loader={<h4></h4>}
                    >
                        <table className="table table-striped freezeHead">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>LAN No</th>
                                    <th>Creation Date</th>
                                    <th>Customer Name</th>
                                    <th>Branch</th>
                                    <th>LRN Date</th>
                                    <th>Portfolio</th>
                                    <th>Region</th>
                                    <th>Status</th>
                                    {/* <th>Actions</th> */}
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {repoReleaseItems.map(
                                    (repo: RepoRelease, index: number) => {
                                        return (
                                            <tr key={repo._id}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <Link
                                                        to={`/view-repo-release/${repo._id}`}
                                                    >
                                                        {repo.lan_no}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {formatDateToDDMMYYYYWithTime(
                                                        repo.createdAt
                                                    )}
                                                </td>
                                                <td>{repo.customer_name}</td>
                                                <td>{repo.branch}</td>
                                                <td>
                                                    {formatDateToDDMMYYYY(
                                                        repo.lrn_date
                                                    )}
                                                </td>
                                                <td>{repo.portfolio}</td>
                                                <td>{repo.region}</td>
                                                <td>
                                                    {getStatusText(repo.status)}
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </>
    );
};

export default RepoReleaseListing;
