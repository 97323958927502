export const formatDateToDDMMYYYY = (date:Date) =>{
    if(date == null || date == undefined){
        return null 
    }
    return new Date(date).toLocaleDateString('en-IN', {day: '2-digit',month: '2-digit', year: 'numeric'})
}

export const formatDateToYYYYMMDD = (date: Date | null | undefined): string|null => {
    if (date == null || date === undefined) {
      return null;
    }
  
    const year = new Date(date).getFullYear();
    const month = String(new Date(date).getMonth() + 1).padStart(2, '0');
    const day = String(new Date(date).getDate()).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
};

export const formatDateToDDMMYYYYWithTime = (date: Date) => {
    if (date == null || date == undefined) {
        return null;
    }
    return new Date(date).toLocaleString('en-IN', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
};