import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
} from "../../helpers/date";
import { Modal, Button } from "react-bootstrap";
import { User } from "../../interfaces/user";
import RepoRelease from "../../interfaces/repo_release";
import {
    fetchRepoReleaseLogs,
    fetchSingleRepoRelease,
    updateRepoReleaseStatus,
} from "../../redux/reducers/repo-release";
import { RepoReleaseLogs } from "../../interfaces/repo_release_logs";

const ViewRepoRelease: React.FC = () => {
    let dispatch: Dispatch<any> = useDispatch();
    const { repoId } = useParams();
    const navigate = useNavigate();
    let repoRelease: RepoRelease = useSelector(
        (state: any) => state.repoRelease.repoRelease
    );
    const userData: User = useSelector((state: any) => state.user.userData);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [remark, setRemark] = useState("");
    let logs: RepoReleaseLogs[] = useSelector(
        (state: any) => state.repoRelease.logs
    );

    useEffect(() => {
        dispatch(fetchSingleRepoRelease({ _id: repoId }));
        dispatch(fetchRepoReleaseLogs(repoId as string));
    }, [repoId]);

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    return (
        <div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Reject Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            onChange={(event: any) => {
                                setRemark(event.target.value);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (remark.trim().length > 0) {
                                    dispatch(
                                        updateRepoReleaseStatus({
                                            status: "rejected",
                                            remarks: remark,
                                            _id: repoId as string,
                                        })
                                    );

                                    setIsOpen(false);
                                    navigate("/repo-release-listing");
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="py-3 mb-4">Repo Release Record</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <form>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        LAN No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.lan_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Customer Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-user"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.customer_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Zone :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-map"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.zone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Region :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-map-pin"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.region}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Branch :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-smart-home"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.branch}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Portfolio :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-report-analytics"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.portfolio}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        POS :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.pos}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Other Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.other_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Penal Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.penal_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Repo Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.repo_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Legal Charges :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-cash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.legal_charges}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        RC No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-certificate"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.rc_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Chassis No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-file-certificate"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.chassis_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Engine Number :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-engine"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.engine_number}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Make :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-layers-intersect"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.make}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Model :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-star"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.model}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Year of Manufacturing :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.mfg_year?.toString()}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        LRN Date
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-calendar-stats"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {formatDateToDDMMYYYY(
                                                    repoRelease.lrn_date
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Sec 17/ Sec 9 Available
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.sec_17_9?.length >
                                                0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Sec 138 Available
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <p className="mb-0 fw-normal">
                                                {repoRelease.sec_138?.length > 0
                                                    ? "Yes"
                                                    : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Repo Release Ticketing
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            Outstanding
                                                            <br />
                                                            Header
                                                        </th>
                                                        <th>
                                                            Total
                                                            <br />
                                                            Outstanding
                                                        </th>
                                                        <th>Collection</th>
                                                        <th>Waiver %</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>EMI Overdue</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.emi_overdue_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.emi_overdue_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.emi_overdue_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Current Month EMI
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.current_month_emi_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.current_month_emi_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.current_month_emi_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other Charges</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.other_charges_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.other_charges_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.other_charges_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Repo Charges</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.repo_charges_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.repo_charges_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.repo_charges_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Legal Charges</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.legal_charges_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.legal_charges_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.legal_charges_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>CBC Arrear</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.cbc_arrear_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.cbc_arrear_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.cbc_arrear_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other Charges 1</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.other_charges_1_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.other_charges_1_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.other_charges_1_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other Charges 2</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.other_charges_2_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.other_charges_2_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.other_charges_2_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Other Charges 3</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.other_charges_3_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.other_charges_3_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.other_charges_3_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.total_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.total_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.total_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                    <br />
                                                    <tr>
                                                        <td>Advance EMI</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.advance_emi_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.advance_emi_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.advance_emi_waiver
                                                            }
                                                        </td>
                                                    </tr>

                                                    <tr>
                                                        <td>Future POS</td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.future_pos_outstanding
                                                            }
                                                        </td>
                                                        <td>
                                                            ₹{" "}
                                                            {
                                                                repoRelease.future_pos_collection
                                                            }
                                                        </td>
                                                        <td>
                                                            {
                                                                repoRelease.future_pos_waiver
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    ></label>
                                    <div className="col-sm-8">
                                        <div className="row row-gap-4">
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        repoRelease.challan_copy as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Receipt or
                                                                Deposit Challan
                                                                copy
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="col-md-6">
                                                <a
                                                    href={
                                                        repoRelease.approval_nrm as string
                                                    }
                                                    target="_blank"
                                                    className="d-flex align-items-center"
                                                >
                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                        <i className="ti ti-file-text ti-sm"></i>
                                                    </div>
                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                        <div className="me-2">
                                                            <h6 className="mb-0">
                                                                Repo Release
                                                                Approval (NRM)
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="my-4 mx-n4" />
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="multicol-birthdate"
                                    >
                                        Payment Receipting Detail
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Sr no</th>
                                                        <th>LAN no</th>
                                                        <th>Receipt date</th>
                                                        <th>UNO Receipt No</th>
                                                        <th>Receipt amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {repoRelease.payment_details?.map(
                                                        (
                                                            details: PaymentDetails,
                                                            index: number
                                                        ) => {
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {index +
                                                                            1}
                                                                        .
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            details.lan_no
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <span className="badge bg-label-primary me-1">
                                                                            {formatDateToDDMMYYYY(
                                                                                details.receipt_date
                                                                            )}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            details.uno_receipt_no
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        ₹{" "}
                                                                        {
                                                                            details.receipt_amount
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            );
                                                        }
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <hr className="my-4 mx-n4" />
                                <div className="row justify-content-end">
                                    <div className="col-sm-8">
                                        {userData?.role?.rights?.includes(
                                            "add_repo_release"
                                        ) &&
                                            repoRelease?.status !=
                                                "approved_l2" && (
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        navigate(
                                                            "/edit-repo-release/" +
                                                                repoId
                                                        );
                                                    }}
                                                >
                                                    EDIT
                                                </button>
                                            )}
                                        &nbsp;&nbsp;
                                        {userData?.role?.rights?.includes(
                                            "approve_repo_release"
                                        ) &&
                                            repoRelease.status?.indexOf(
                                                "approved_l2"
                                            ) < 0 && (
                                                <>
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                            dispatch(
                                                                updateRepoReleaseStatus(
                                                                    {
                                                                        status: "approved",
                                                                        _id: repoId as string,
                                                                    }
                                                                )
                                                            );

                                                            navigate(
                                                                "/repo-release-listing"
                                                            );
                                                        }}
                                                    >
                                                        APPROVE
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        onClick={() => {
                                                            setIsOpen(true);
                                                        }}
                                                    >
                                                        QUERY
                                                    </button>
                                                </>
                                            )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <h4 className="py-3 mb-1">Logs</h4>
            <div className="row">
                {logs.map((log: RepoReleaseLogs) => {
                    return (
                        <div className="col-md-3 col-sm-6 col-12 mb-4">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="mb-0">
                                        {formatDateToDDMMYYYYWithTime(
                                            log.createdAt
                                        )}
                                    </h6>
                                    <p className="mb-0">{log?.uid?.name}</p>
                                    <p className="mb-0">
                                        {getStatusText(log?.status)}
                                    </p>
                                    <p className="mb-0">{log.remarks}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ViewRepoRelease;
