import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import ErrorResponse from '../../interfaces/ErrorResponse';
import OtsApproval from '../../interfaces/ots_approval';
import { OtsApprovalLogs } from '../../interfaces/ots_approval_logs';

export interface OtsApprovalState {
  items: OtsApproval[];
  loading: boolean | null;
  error: string | null;
  otsApproval: OtsApproval,
  added: boolean | null;
  logs: OtsApprovalLogs[];
}

interface searchParams{
  _id?: String
}

interface statusParams{
  status: 'approved' | 'rejected' | 'accepted',
  _id: string,
  remarks?:string 
}

interface fetchOtsApprovalParams{
  regions?: string,
  export?:boolean
}

export const fetchOtsApproval = createAsyncThunk('/fetchOtsApproval', async (params: fetchOtsApprovalParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/ots-approval', {params: params});
    if(params.export){
      downloadCSV(response.data);
    }
    else{
      return response.data as OtsApproval[];
    }
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleOtsApproval = createAsyncThunk('/fetchSingleOtsApproval', async (params:searchParams, { rejectWithValue }) => {
  try {
    const response = await api.get('/ots-approval',{params:params});
    return response.data as OtsApproval[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addOtsApproval = createAsyncThunk('/addOtsApproval', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else if (Array.isArray(value)) {
            formData.append(key, JSON.stringify(value));
        } 
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/ots-approval', formData);
    return response.data as OtsApproval;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateOtsApproval = createAsyncThunk('/updateOtsApproval', async (values:any, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        const value = values[key];
        if(value == null){
          continue;
        }
        else if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value));
        }
        else{
          formData.append(key, value);
        }
      }
    }

    const response = await api.post('/ots-approval/update', formData);
    return response.data as OtsApproval;
  } catch (error) {
    console.log(error);
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateOtsApprovalStatus = createAsyncThunk('/updateOtsApprovalStatus', async (values:statusParams, { rejectWithValue }) => {
  try {
    const response = await api.put('/ots-approval/update-status', values);
    return response.data as OtsApproval;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchOtsApprovalLogs = createAsyncThunk('/fetchOtsApprovalLogs', async (id:string, { rejectWithValue }) => {
  try {
    const response = await api.get('/ots-approval/logs/' + id);
    return response.data as OtsApprovalLogs[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

function downloadCSV(csvContent:any) {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'OTS Approval.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}


const otsApprovalSlice = createSlice({
  name: 'ots-approval',
  initialState: {
    items: [],
    loading: null,
    error: null,
    otsApproval: {} as OtsApproval,
    added: null,
    logs: [],
    remarksUpdated: null
  } as OtsApprovalState,
  reducers: {
    setOtsApproval: (state,action) =>{
      state.otsApproval = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOtsApproval.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOtsApproval.fulfilled, (state, action) => {
        state.loading = false;
        if(action.payload){
          state.items = action.payload;
        }
      })
      .addCase(fetchOtsApproval.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(addOtsApproval.fulfilled, (state, action)=>{
        state.items.unshift(action.payload);
      }).addCase(addOtsApproval.rejected, (state, action) => {
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
        alert(state.error);
      }).addCase(fetchSingleOtsApproval.fulfilled, (state, action)=>{
        state.otsApproval = action.payload[0];
      }).addCase(updateOtsApprovalStatus.fulfilled, (state,action)=>{
        let updatedOtsApproval = action.payload;
        state.items = state.items.map((otsApproval: OtsApproval) =>
          otsApproval._id === updatedOtsApproval._id ? updatedOtsApproval : otsApproval
        );
      }).addCase(updateOtsApproval.fulfilled, (state,action)=>{
        let updatedOtsApproval = action.payload;
        state.items = state.items.map((otsApproval: OtsApproval) =>
          otsApproval._id === updatedOtsApproval._id ? updatedOtsApproval : otsApproval
        );
      }).addCase(fetchOtsApprovalLogs.fulfilled, (state,action)=>{
        state.logs = action.payload;
      });
  },
});

export const { setOtsApproval } = otsApprovalSlice.actions;
export default otsApprovalSlice.reducer;