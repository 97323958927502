import React, { Dispatch, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import RequireAuth from "./AuthGuard";
import Dashboard from "../screens/home/Dashboard";
import Login from "../screens/auth/Login";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { setToken } from "../redux/reducers/user";
import RepoListing from "../screens/repo/RepoListing";
import AddRepo from "../screens/repo/AddRepo";
import ViewRepo from "../screens/repo/ViewRepo";
import YardList from "../screens/masters/yard/YardList";
import RepoAgencyList from "../screens/masters/agency/RepoAgencyList";
import LanList from "../screens/masters/lan/repo-surrender/LanList";
import YardAdd from "../screens/masters/yard/YardAdd";
import RepoAgencyAdd from "../screens/masters/agency/RepoAgencyAdd";
import LanAdd from "../screens/masters/lan/repo-surrender/LanAdd";
import UserList from "../screens/masters/user/UserList";
import UserAdd from "../screens/masters/user/UserAdd";
import RepoAgencyView from "../screens/masters/agency/RepoAgencyView";
import LanView from "../screens/masters/lan/repo-surrender/LanView";
import YardView from "../screens/masters/yard/YardView";
import UserView from "../screens/masters/user/UserView";
import ChangePassword from "../screens/auth/ChangePassword";
import LoginSuccess from "../screens/auth/LoginSuccess";
import AddRepoRelease from "../screens/repo-release/AddRepoRelease";
import ViewRepoRelease from "../screens/repo-release/ViewRepoRelease";
import RepoReleaseListing from "../screens/repo-release/RepoReleaseListing";
import LocalBidListing from "../screens/local-bid/LocalBidListing";
import AddLocalBid from "../screens/local-bid/AddLocalBid";
import ViewLocalBid from "../screens/local-bid/ViewLocalBid";
import OtsApprovalListing from "../screens/ots-approval/OtsApprovalListing";
import AddOtsApproval from "../screens/ots-approval/AddOtsApproval";
import ViewOtsApproval from "../screens/ots-approval/ViewOtsApproval";
import OtsClosureListing from "../screens/ots-closure/OtsClosureListing";
import AddOtsClosure from "../screens/ots-closure/AddOtsClosure";
import ViewOtsClosure from "../screens/ots-closure/ViewOtsClosure";
import AIWISaleListing from "../screens/aiwi-sale/AIWISaleListing";
import AddAIWISale from "../screens/aiwi-sale/AddAIWISale";
import ViewAIWISale from "../screens/aiwi-sale/ViewAIWISale";
import LocalBidLanAdd from "../screens/masters/lan/local-bid/LocalBidLanAdd";
import LocalBidLanView from "../screens/masters/lan/local-bid/LocalBidLanView";
import LocalBidLanList from "../screens/masters/lan/local-bid/LocalBidLanList";
import OtsApprovalLanAdd from "../screens/masters/lan/ots-approval/OtsApprovalLanAdd";
import OtsApprovalLanView from "../screens/masters/lan/ots-approval/OtsApprovalLanView";
import OtsApprovalLanList from "../screens/masters/lan/ots-approval/OtsApprovalLanList";
import AiwiLanAdd from "../screens/masters/lan/aiwi/AiwiLanAdd";
import AiwiLanView from "../screens/masters/lan/aiwi/AiwiLanView";
import AiwiLanList from "../screens/masters/lan/aiwi/AiwiLanList";
import LegalRequestLanList from "../screens/masters/lan/legal-request/LegalRequestLanList";
import LegalRequestLanAdd from "../screens/masters/lan/legal-request/LegalRequestLanAdd";
import LegalRequestLanView from "../screens/masters/lan/legal-request/LegalRequestLanView";
import LegalRequestListing from "../screens/legal-request/LegalRequestListing";
import AddLegalRequest from "../screens/legal-request/AddLegalRequest";
import ViewLegalRequest from "../screens/legal-request/ViewLegalRequest";

const AppRouter: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const token = useSelector((state: any) => state.user.token);
    const isMobile = useMediaQuery({ maxWidth: 768 });

    useEffect(() => {
        dispatch(setToken(localStorage.getItem("token")));
    });

    const NotFound: React.FC = () => (
        <div>
            <h2>404 - Not Found</h2>
            {/* You can add more content or a link to the home page */}
        </div>
    );

    return (
        <div
            className={
                token && !isMobile && "container flex-grow-1 container-p-y"
            }
            style={!isMobile ? { marginTop: token && "30px" } : {}}
        >
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <Dashboard />
                        </RequireAuth>
                    }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/login/success" element={<LoginSuccess />} />
                <Route
                    path="/repo-listing"
                    element={
                        <RequireAuth>
                            <RepoListing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/add-repo"
                    element={
                        <RequireAuth>
                            <AddRepo />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/view-repo/:repoId"
                    element={
                        <RequireAuth>
                            <ViewRepo />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit-repo/:repoId"
                    element={
                        <RequireAuth>
                            <AddRepo />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/repo-release-listing"
                    element={
                        <RequireAuth>
                            <RepoReleaseListing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/add-repo-release"
                    element={
                        <RequireAuth>
                            <AddRepoRelease />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/view-repo-release/:repoId"
                    element={
                        <RequireAuth>
                            <ViewRepoRelease />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit-repo-release/:repoId"
                    element={
                        <RequireAuth>
                            <AddRepoRelease />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/local-bid-listing"
                    element={
                        <RequireAuth>
                            <LocalBidListing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/add-local-bid"
                    element={
                        <RequireAuth>
                            <AddLocalBid />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/view-local-bid/:bidId"
                    element={
                        <RequireAuth>
                            <ViewLocalBid />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit-local-bid/:bidId"
                    element={
                        <RequireAuth>
                            <AddLocalBid />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/ots-approval-listing"
                    element={
                        <RequireAuth>
                            <OtsApprovalListing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/add-ots-approval"
                    element={
                        <RequireAuth>
                            <AddOtsApproval />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/view-ots-approval/:otsId"
                    element={
                        <RequireAuth>
                            <ViewOtsApproval />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit-ots-approval/:otsId"
                    element={
                        <RequireAuth>
                            <AddOtsApproval />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/ots-closure-listing"
                    element={
                        <RequireAuth>
                            <OtsClosureListing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/add-ots-closure"
                    element={
                        <RequireAuth>
                            <AddOtsClosure />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/view-ots-closure/:otsId"
                    element={
                        <RequireAuth>
                            <ViewOtsClosure />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit-ots-closure/:otsId"
                    element={
                        <RequireAuth>
                            <AddOtsClosure />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/aiwi-sale-listing"
                    element={
                        <RequireAuth>
                            <AIWISaleListing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/add-aiwi-sale"
                    element={
                        <RequireAuth>
                            <AddAIWISale />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/view-aiwi-sale/:aiwiSaleId"
                    element={
                        <RequireAuth>
                            <ViewAIWISale />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/edit-aiwi-sale/:aiwiSaleId"
                    element={
                        <RequireAuth>
                            <AddAIWISale />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/legal-request-listing"
                    element={
                        <RequireAuth>
                            <LegalRequestListing />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/add-legal-request"
                    element={
                        <RequireAuth>
                            <AddLegalRequest />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/view-legal-request/:legalId"
                    element={
                        <RequireAuth>
                            <ViewLegalRequest />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/master/yard"
                    element={
                        <RequireAuth>
                            <YardList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/add-yard"
                    element={
                        <RequireAuth>
                            <YardAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/view-yard/:yardId"
                    element={
                        <RequireAuth>
                            <YardView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/edit-yard/:yardId"
                    element={
                        <RequireAuth>
                            <YardAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/add-agency"
                    element={
                        <RequireAuth>
                            <RepoAgencyAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/edit-agency/:agencyId"
                    element={
                        <RequireAuth>
                            <RepoAgencyAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/view-agency/:agencyId"
                    element={
                        <RequireAuth>
                            <RepoAgencyView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/agency"
                    element={
                        <RequireAuth>
                            <RepoAgencyList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/lan"
                    element={
                        <RequireAuth>
                            <LanList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/add-lan"
                    element={
                        <RequireAuth>
                            <LanAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/view-lan/:lanId"
                    element={
                        <RequireAuth>
                            <LanView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/edit-lan/:lanId"
                    element={
                        <RequireAuth>
                            <LanAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/local-bid-lan"
                    element={
                        <RequireAuth>
                            <LocalBidLanList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/add-local-bid-lan"
                    element={
                        <RequireAuth>
                            <LocalBidLanAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/view-local-bid-lan/:lanId"
                    element={
                        <RequireAuth>
                            <LocalBidLanView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/edit-local-bid-lan/:lanId"
                    element={
                        <RequireAuth>
                            <LocalBidLanAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/ots-approval-lan"
                    element={
                        <RequireAuth>
                            <OtsApprovalLanList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/add-ots-approval-lan"
                    element={
                        <RequireAuth>
                            <OtsApprovalLanAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/view-ots-approval-lan/:lanId"
                    element={
                        <RequireAuth>
                            <OtsApprovalLanView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/edit-ots-approval-lan/:lanId"
                    element={
                        <RequireAuth>
                            <OtsApprovalLanAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/aiwi-lan"
                    element={
                        <RequireAuth>
                            <AiwiLanList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/add-aiwi-lan"
                    element={
                        <RequireAuth>
                            <AiwiLanAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/view-aiwi-lan/:lanId"
                    element={
                        <RequireAuth>
                            <AiwiLanView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/edit-aiwi-lan/:lanId"
                    element={
                        <RequireAuth>
                            <AiwiLanAdd />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/master/legal-request-lan"
                    element={
                        <RequireAuth>
                            <LegalRequestLanList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/add-legal-request-lan"
                    element={
                        <RequireAuth>
                            <LegalRequestLanAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/view-legal-request-lan/:lanId"
                    element={
                        <RequireAuth>
                            <LegalRequestLanView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/edit-legal-request-lan/:lanId"
                    element={
                        <RequireAuth>
                            <LegalRequestLanAdd />
                        </RequireAuth>
                    }
                />

                <Route
                    path="/master/user"
                    element={
                        <RequireAuth>
                            <UserList />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/add-user"
                    element={
                        <RequireAuth>
                            <UserAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/view-user/:userId"
                    element={
                        <RequireAuth>
                            <UserView />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/master/edit-user/:userId"
                    element={
                        <RequireAuth>
                            <UserAdd />
                        </RequireAuth>
                    }
                />
                <Route
                    path="/change-password"
                    element={
                        <RequireAuth>
                            <ChangePassword />
                        </RequireAuth>
                    }
                />
                <Route path="*" element={<Navigate to="/404" />} />
                <Route path="/404" element={<NotFound />} />
            </Routes>
        </div>
    );
};

export default AppRouter;
