import React, { Dispatch, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import AutocompleteField from "../../components/AutoCompleteField";
import { useDispatch, useSelector } from "react-redux";
import { searchLan, selectLanItems } from "../../redux/reducers/lan";
import Lan from "../../interfaces/lan";
import MaskedInput from "react-input-mask";
import { formatDateToDDMMYYYY, formatDateToYYYYMMDD } from "../../helpers/date";
import { fetchProfile } from "../../redux/reducers/user";
import { User } from "../../interfaces/user";
import { Modal, Button } from "react-bootstrap";
import {
    addAiwiSale,
    fetchSingleAiwiSale,
    setAiwiSale,
    updateAiwiSale,
} from "../../redux/reducers/aiwi-sale";
import AIWISale from "../../interfaces/aiwi_sale";

const AddAIWISale: React.FC = () => {
    const navigate = useNavigate();
    const lanItems = useSelector(selectLanItems);
    const userData: User = useSelector((state: any) => state.user.userData);
    const aiwiSale = useSelector((state: any) => state.aiwiSale.aiwiSale);
    const [modalIsOpen, setIsOpen] = useState(false);

    const [lanNo, setLanNo] = useState("");
    const [receiptDate, setReceiptDate] = useState("");
    const [unoReceiptNo, setUnoReceiptNo] = useState("");
    const [receiptAmount, setReceiptAmount] = useState("");

    const [paymentDetailsArr, setPaymentDetailsArr] = useState<
        PaymentDetails[]
    >([]);

    const dispatch: Dispatch<any> = useDispatch();

    const { aiwiSaleId } = useParams();

    const [initialFormValues, setInitialFormValues] = useState({
        normal_repo_expenses: 0,
        additional_repo_expenses: 0,
        total_repo_expenses: 0,
    });

    useEffect(() => {
        if (aiwiSaleId) {
            dispatch(
                fetchSingleAiwiSale({
                    _id: aiwiSaleId,
                })
            );
        } else {
            dispatch(setAiwiSale({}));
        }
    }, [aiwiSaleId, dispatch]);

    useEffect(() => {
        const values: any = {} as AIWISale;
        Object.keys(aiwiSale as keyof AIWISale).forEach((key) => {
            values[key] = aiwiSale[key];
        });
        values.lrn_date = formatDateToYYYYMMDD(values.lrn_date);
        setPaymentDetailsArr(values.payment_details || []);
        setInitialFormValues(values);
    }, [aiwiSale]);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    const onSubmit = (values: AIWISale) => {
        values.payment_details = paymentDetailsArr;
        if (aiwiSaleId) {
            dispatch(updateAiwiSale(values));
        } else {
            dispatch(addAiwiSale(values));
        }
        navigate("/aiwi-sale-listing");
    };

    const validate = (values: AIWISale) => {
        const errors: Partial<Record<keyof AIWISale, string>> = {};

        const requiredFields: (keyof AIWISale)[] = [
            "lan_no",
            "penal_charges",
            "repo_charges",
            "legal_charges",
            "challan",
            "buyer_quotation",
            "buyer_kyc_pan",
            "buyer_kyc_address",
            "sale_acceptance_letter",
            "sale_loss_approval",
            "surrender_letter",
            "simulation",
        ];

        requiredFields.forEach((field) => {
            if (values[field] === undefined || values[field] === null) {
                errors[field] = "Required";
            }
        });
        return errors;
    };

    const setFormValue = (form: any, key: string, value: any) => {
        form.change(key, value);
    };
    const handleLanInputChange = (value: any) => {
        dispatch(
            searchLan({
                search: value,
                type: "aiwi",
            })
        );
    };

    const onLanSelected = (form: any, value: Lan) => {
        setFormValue(form, "customer_name", value.customer_name);
        setFormValue(form, "lan_no", value.lan_no);
        setFormValue(form, "zone", value.zone);
        setFormValue(form, "region", value.region);
        setFormValue(form, "branch", value.branch);
        setFormValue(form, "portfolio", value.portfolio);
        setFormValue(form, "pos", value.pos);
        setFormValue(form, "other_charges", value.other_charges);
        setFormValue(form, "penal_charges", value.penal_charges);
        setFormValue(form, "repo_charges", value.repo_charges);
        setFormValue(form, "legal_charges", value.legal_charges);
        setFormValue(form, "rc_no", value.rc_no);
        setFormValue(form, "chassis_no", value.chassis_no);
        setFormValue(form, "engine_number", value.engine_number);
        setFormValue(form, "make", value.make);
        setFormValue(form, "model", value.model);
        setFormValue(form, "mfg_year", value.mfg_year);
        setFormValue(form, "revised_focus_band", value.revised_focus_band);
        setFormValue(
            form,
            "total_emi_collectable",
            value.total_emi_collectable
        );
        setFormValue(form, "as_on_date_dpd", value.as_on_date_dpd);
        setFormValue(
            form,
            "total_gross_collection",
            value.total_gross_collection
        );
        setFormValue(form, "sec_17_9", value.sec_17_9 != null ? "Yes" : "No");
        setFormValue(form, "sec_138", value.sec_138?.length > 0 ? "Yes" : "No");
        setFormValue(
            form,
            "lrn_date",
            value.lrn_date ? formatDateToYYYYMMDD(value.lrn_date) : ""
        );
        setFormValue(
            form,
            "arbitration_status",
            value.arbitration_status?.length > 0 ? "Yes" : "No"
        );
    };

    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Payment Receipt</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row g-2">
                            <div className="col mb-3">
                                <label
                                    htmlFor="nameBasic"
                                    className="form-label"
                                >
                                    LAN No
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    id="nameBasic"
                                    className="form-control"
                                    placeholder="Enter LAN No"
                                    value={lanNo}
                                    onChange={(event) => {
                                        setLanNo(event.target.value);
                                    }}
                                />
                            </div>
                            <div className="col mb-3">
                                <label
                                    htmlFor="nameBasic"
                                    className="form-label"
                                >
                                    Receipt date
                                </label>
                                <input
                                    type="date"
                                    id="multicol-birthdate"
                                    className="form-control dob-picker"
                                    placeholder="YYYY-MM-DD"
                                    value={receiptDate}
                                    onChange={(event) => {
                                        setReceiptDate(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row g-2">
                            <div className="col mb-0">
                                <label
                                    htmlFor="emailBasic"
                                    className="form-label"
                                >
                                    UNO Receipt No
                                </label>
                                <input
                                    type="text"
                                    id="nameBasic"
                                    className="form-control"
                                    placeholder="Enter UNO Receipt No"
                                    value={unoReceiptNo}
                                    onChange={(event) => {
                                        setUnoReceiptNo(event.target.value);
                                    }}
                                />
                            </div>
                            <div className="col mb-0">
                                <label
                                    htmlFor="emailBasic"
                                    className="form-label"
                                >
                                    Receipt amount
                                </label>
                                <div className="input-group">
                                    <span className="input-group-text">₹</span>
                                    <input
                                        type="number"
                                        className="form-control"
                                        value={receiptAmount}
                                        onChange={(event) => {
                                            setReceiptAmount(
                                                event.target.value
                                            );
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (
                                                e.key === "-" ||
                                                e.key === "+" ||
                                                e.key === "e"
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (
                                    lanNo &&
                                    receiptAmount &&
                                    receiptDate &&
                                    unoReceiptNo
                                ) {
                                    const details: PaymentDetails = {
                                        lan_no: lanNo,
                                        receipt_amount: parseInt(receiptAmount),
                                        receipt_date: new Date(receiptDate),
                                        uno_receipt_no: unoReceiptNo,
                                    };
                                    setPaymentDetailsArr([
                                        ...paymentDetailsArr,
                                        details,
                                    ]);
                                    setLanNo("");
                                    setReceiptAmount("");
                                    setReceiptDate("");
                                    setUnoReceiptNo("");
                                    setIsOpen(false);
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h4 className="py-3 mb-4">Add New Entry</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <Form
                                initialValues={initialFormValues}
                                onSubmit={onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Search LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={
                                                                aiwiSaleId ==
                                                                null
                                                                    ? false
                                                                    : true
                                                            }
                                                            component={
                                                                AutocompleteField
                                                            }
                                                            name="search_lan_no"
                                                            icon="ti ti-hash"
                                                            placeholder="Search LAN No"
                                                            aria-label="Search LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            suggestions={
                                                                lanItems
                                                            }
                                                            renderItem={(
                                                                item: any
                                                            ) => (
                                                                <div className="suggestion-item">
                                                                    {
                                                                        item.lan_no
                                                                    }
                                                                </div>
                                                            )}
                                                            getKey={(
                                                                item: any
                                                            ) => item._id}
                                                            onInputChange={
                                                                handleLanInputChange
                                                            }
                                                            onSelected={(
                                                                value: any
                                                            ) => {
                                                                onLanSelected(
                                                                    form,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lan_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="LAN No"
                                                            aria-label="LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lan_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Customer Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="customer_name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Customer Name"
                                                            aria-label="Customer Name"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="customer_name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Zone
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="zone"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Zone"
                                                            aria-label="Zone"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="zone"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Region
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map-pin"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="region"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Region"
                                                            aria-label="Region"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="region"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Branch
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-smart-home"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="branch"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Branch"
                                                            aria-label="Branch"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="branch"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Portfolio
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="portfolio"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Portfolio"
                                                            aria-label="Portfolio"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="portfolio"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    POS
                                                </label>
                                                <div className="col-sm-2">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="pos"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="POS"
                                                            aria-label="POS"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="pos"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Penal Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="penal_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="penal_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Repo Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="repo_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="repo_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <label
                                                    className="col col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Legal Charges
                                                </label>
                                                <div className="col">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="legal_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="legal_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Other Charges
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            initialValue={0}
                                                            disabled={true}
                                                            component="input"
                                                            name="other_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Other Charges"
                                                            aria-label="Other Charges"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="other_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    RC No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="rc_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="RC No"
                                                            aria-label="RC No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="rc_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Chassis No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-file-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="chassis_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Chassis No"
                                                            aria-label="Chassis No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="chassis_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Engine Number
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-engine"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="engine_number"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Engine Number"
                                                            aria-label="Engine Number"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="engine_number"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Make
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-layers-intersect"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="make"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Make"
                                                            aria-label="Make"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="make"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Model
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-star"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="model"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Model"
                                                            aria-label="Model"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="model"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Year of Manufacturing
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>

                                                        <Field
                                                            component="input"
                                                            name="mfg_year"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Year of Manufacturing"
                                                            aria-label="Year of Manufacturing"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        >
                                                            {({ input }) => (
                                                                <MaskedInput
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    className="form-control"
                                                                    {...input}
                                                                    mask="9999"
                                                                    placeholder="YYYY"
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Revised Focus Band
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="revised_focus_band"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Revised Focus Band"
                                                            aria-label="Revised Focus Band"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total EMI Collectable
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_emi_collectable"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total EMI Collectable"
                                                            aria-label="Total EMI Collectable"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    As on date DPD
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="as_on_date_dpd"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="As on date DPD"
                                                            aria-label="As on date DPD"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total Gross Collection
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_gross_collection"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total Gross Collection"
                                                            aria-label="Total Gross Collection"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    LRN Date
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lrn_date"
                                                            type="date"
                                                            id="multicol-birthdate"
                                                            className="form-control dob-picker"
                                                            placeholder="YYYY-MM-DD"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lrn_date"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Sec 17/ Sec 9 available:
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="sec_17_9"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Sec 138 available
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="sec_138"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Any litigation against case
                                                    available
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="arbitration_status"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-5">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    {" "}
                                                    AIWI Sale Ticketing
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="row row-gap-4">
                                                        {aiwiSaleId && (
                                                            <>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.challan as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Receipt
                                                                                                or
                                                                                                Deposit
                                                                                                Challan
                                                                                                copy
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.buyer_quotation as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Buyer
                                                                                                Quotation
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.buyer_kyc_pan as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Buyer
                                                                                                KYC
                                                                                                –
                                                                                                PAN
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.buyer_kyc_address as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Buyer
                                                                                                KYC
                                                                                                –
                                                                                                Address
                                                                                                Proof
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.sale_acceptance_letter as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Sale
                                                                                                Acceptance
                                                                                                Letter
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.surrender_letter as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Sale
                                                                                                Intimation
                                                                                                letter
                                                                                                to
                                                                                                customer
                                                                                                with
                                                                                                postal
                                                                                                acknowledgement
                                                                                                or
                                                                                                Surrender
                                                                                                letter
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.sale_loss_approval as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Sale
                                                                                                Loss
                                                                                                Approval
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.simulation as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Simulation
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <label
                                                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                                                        htmlFor="multicol-birthdate"
                                                                    ></label>
                                                                    <div className="col-sm-12">
                                                                        <div className="row row-gap-4">
                                                                            <div className="col-md-12">
                                                                                <a
                                                                                    href={
                                                                                        aiwiSale.valuation as string
                                                                                    }
                                                                                    target="_blank"
                                                                                    className="d-flex align-items-center"
                                                                                >
                                                                                    <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                        <i className="ti ti-file-text ti-sm"></i>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                        <div className="me-2">
                                                                                            <h6 className="mb-0">
                                                                                                Valuation
                                                                                            </h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Receipt or
                                                                    Deposit
                                                                    Challan
                                                                    copy*
                                                                </label>
                                                                <Field name="challan">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="challan"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Buyer
                                                                    Quotation*
                                                                </label>
                                                                <br />
                                                                <Field name="buyer_quotation">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="buyer_quotation"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Buyer KYC –
                                                                    PAN *
                                                                </label>
                                                                <br />
                                                                <Field name="buyer_kyc_pan">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="buyer_kyc_pan"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Buyer KYC –
                                                                    Address
                                                                    Proof*
                                                                </label>
                                                                <br />
                                                                <Field name="buyer_kyc_address">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="buyer_kyc_address"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Sale
                                                                    Acceptance
                                                                    Letter *
                                                                </label>
                                                                <br />
                                                                <Field name="sale_acceptance_letter">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="sale_acceptance_letter"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Sale
                                                                    Intimation
                                                                    letter to
                                                                    customer
                                                                    with postal
                                                                    acknowledgement
                                                                    or Surrender
                                                                    letter *
                                                                </label>
                                                                <br />
                                                                <Field name="surrender_letter">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="surrender_letter"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Sale Loss
                                                                    Approval *
                                                                </label>
                                                                <br />
                                                                <Field name="sale_loss_approval">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="sale_loss_approval"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Simulation *
                                                                </label>
                                                                <br />
                                                                <Field name="simulation">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="simulation"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Valuation *
                                                                </label>
                                                                <br />
                                                                <Field name="valuation">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <Field
                                                                    name="valuation"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Payment Receipting Detail
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="table-responsive text-nowrap">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        Sr no
                                                                    </th>
                                                                    <th>
                                                                        LAN no
                                                                    </th>
                                                                    <th>
                                                                        Receipt
                                                                        date
                                                                    </th>
                                                                    <th>
                                                                        UNO
                                                                        Receipt
                                                                        No
                                                                    </th>
                                                                    <th>
                                                                        Receipt
                                                                        amount
                                                                    </th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {paymentDetailsArr.map(
                                                                    (
                                                                        details: PaymentDetails,
                                                                        index: number
                                                                    ) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>
                                                                                    {index +
                                                                                        1}

                                                                                    .
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        details.lan_no
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <span className="badge bg-label-primary me-1">
                                                                                        {formatDateToDDMMYYYY(
                                                                                            details.receipt_date
                                                                                        )}
                                                                                    </span>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        details.uno_receipt_no
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    ₹
                                                                                    {
                                                                                        details.receipt_amount
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-icon btn-danger"
                                                                                        onClick={() => {
                                                                                            const updatedDetails =
                                                                                                [
                                                                                                    ...paymentDetailsArr,
                                                                                                ];
                                                                                            updatedDetails.splice(
                                                                                                index,
                                                                                                1
                                                                                            );
                                                                                            setPaymentDetailsArr(
                                                                                                updatedDetails
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <span className="ti ti-x"></span>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    }
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn btn-icon btn-primary mt-2"
                                                        style={{
                                                            float: "right",
                                                        }}
                                                        onClick={() => {
                                                            if (
                                                                paymentDetailsArr.length <
                                                                10
                                                            ) {
                                                                setLanNo(
                                                                    form.getState()
                                                                        .values
                                                                        .lan_no
                                                                );
                                                                setIsOpen(true);
                                                            }
                                                        }}
                                                    >
                                                        <span className="ti ti-plus"></span>
                                                    </button>
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />

                                            <div className="row justify-content-end">
                                                <div className="col-sm-9">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        SAVE DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default AddAIWISale;
