import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
} from "../../helpers/date";
import { Modal, Button } from "react-bootstrap";
import { User } from "../../interfaces/user";
import LegalRequest from "../../interfaces/legal_request";
import { fetchSingleLegalRequest } from "../../redux/reducers/legal-request";

const ViewLegalRequest: React.FC = () => {
    let dispatch: Dispatch<any> = useDispatch();
    const { legalId } = useParams();
    const navigate = useNavigate();
    let legalRequest: LegalRequest = useSelector(
        (state: any) => state.legalRequest.legalRequest
    );
    const userData: User = useSelector((state: any) => state.user.userData);

    useEffect(() => {
        dispatch(fetchSingleLegalRequest({ _id: legalId }));
    }, [legalId]);

    return (
        <div>
            <h4 className="py-3 mb-4">Legal Request Record</h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <form>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        LAN No :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.lan_no}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Borrower Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.borrower_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Borrower Address :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.borrower_address}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Borrower Contact :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.borrower_contact}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Gurantor Name :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.gurantor_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Gurantor Address :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.gurantor_address}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Gurantor Contact :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.gurantor_contact}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Vehicle Availability :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {
                                                    legalRequest.vehicle_availability
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Legal Request :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.legal_request}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Priority as per OPN POS :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.priority}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Remarks :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.remarks}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Created At :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {formatDateToDDMMYYYYWithTime(
                                                    legalRequest.createdAt
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label
                                        className="col-sm-4 col-form-label text-sm-end text-primary"
                                        htmlFor="basic-default-name"
                                    >
                                        Created By :
                                    </label>
                                    <div className="col-sm-8">
                                        <div className="d-flex justify-content-start align-items-center mt-1">
                                            <div className="me-2">
                                                <i className="ti ti-hash"></i>
                                            </div>
                                            <p className="mb-0 fw-normal">
                                                {legalRequest.createdBy?.name}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewLegalRequest;
