import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../interfaces/user";
import Yard from "../../../interfaces/yard";
import {
    exportYard,
    importBulkYard,
    searchYard,
    setYardImported,
} from "../../../redux/reducers/yard";
import { formatDateToDDMMYYYY } from "../../../helpers/date";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const YardList: React.FC = () => {
    const userData: User = useSelector((state: any) => state.user.userData);
    const yardItems: Yard[] = useSelector((state: any) => state.yard.items);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [importing, setImporting] = useState(false);
    const [yardFile, setYardFile] = useState(null);
    const yardImported: boolean | null = useSelector(
        (state: any) => state.yard.imported
    );
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();

    useEffect(() => {
        dispatch(
            searchYard({
                search: "",
            })
        );
    }, [dispatch]);

    useEffect(() => {
        if (yardImported === true) {
            setImporting(false);
            alert("Yard Data Imported Successfully!");
            setIsOpen(false);
            window.location.reload();
        } else if (yardImported === null) {
            setImporting(false);
            dispatch(setYardImported(false));
        }
    }, [yardImported]);

    const navigateToAdd = () => {
        navigate("/master/add-yard");
    };
    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Bulk Upload Yard</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="file"
                            accept=".csv"
                            className="form-control"
                            onChange={(event: any) => {
                                setYardFile(event.target.files[0]);
                            }}
                        />
                        <span>only .csv files supported</span>
                        <br />
                        {importing && <span>Importing...</span>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setImporting(true);
                                dispatch(importBulkYard({ file: yardFile }));
                            }}
                        >
                            Upload
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="card">
                <div className="card-header-elements m-4 ms-auto">
                    {userData?.role?.rights.includes("add_yard") && (
                        <button
                            style={{ marginRight: 5 }}
                            type="button"
                            onClick={() => {
                                setIsOpen(true);
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Bulk Upload
                        </button>
                    )}
                    {userData?.role?.rights.includes("add_yard") && (
                        <button
                            type="button"
                            onClick={navigateToAdd}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Add New Entry
                        </button>
                    )}
                    {userData?.role?.rights.includes("add_yard") && (
                        <button
                            style={{ marginRight: 5 }}
                            type="button"
                            onClick={() => {
                                dispatch(exportYard());
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="fa-solid fa-file-export me-2"></span>
                            Export
                        </button>
                    )}
                </div>
                <div className="table-responsive text-nowrap">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Rent</th>
                                <th>Contact Name</th>
                                <th>Contact Number</th>
                                <th>Address</th>
                                <th>Address 2</th>
                                <th>Area</th>
                                <th>City</th>
                                <th>State</th>
                                <th>Pincode</th>
                                <th>Created Date</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {yardItems.map((yard: Yard, index: number) => {
                                return (
                                    <tr key={yard._id}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <Link
                                                to={`/master/view-yard/${yard._id}`}
                                            >
                                                {yard.code}
                                            </Link>
                                        </td>
                                        <td>{yard.name}</td>
                                        <td>{yard.rent?.toString()}</td>
                                        <td>{yard.contact_name}</td>
                                        <td>{yard.contact_number}</td>
                                        <td>{yard.address}</td>
                                        <td>{yard.address_2}</td>
                                        <td>{yard.area}</td>
                                        <td>{yard.city}</td>
                                        <td>{yard.state}</td>
                                        <td>{yard.pincode}</td>
                                        <td>
                                            {formatDateToDDMMYYYY(
                                                yard.createdDate
                                            )}
                                        </td>
                                        <td>
                                            {yard.is_active
                                                ? "Active"
                                                : "In Active"}
                                        </td>
                                        <td>
                                            <div className="dropdown">
                                                <button
                                                    type="button"
                                                    className="btn p-0 dropdown-toggle hide-arrow"
                                                    data-bs-toggle="dropdown"
                                                >
                                                    <i className="ti ti-dots-vertical"></i>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {userData?.role?.rights.includes(
                                                        "add_yard"
                                                    ) && (
                                                        <Link
                                                            to={`/master/edit-yard/${yard._id}`}
                                                            className="dropdown-item"
                                                        >
                                                            <i className="ti ti-pencil me-1"></i>{" "}
                                                            Edit
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default YardList;
