import React, { Dispatch, useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { useNavigate, useParams } from "react-router-dom";
import Repo from "../../interfaces/repo";
import AutocompleteField from "../../components/AutoCompleteField";
import { useDispatch, useSelector } from "react-redux";
import { searchLan, selectLanItems } from "../../redux/reducers/lan";
import Lan from "../../interfaces/lan";
import { searchYard, selectYardItems } from "../../redux/reducers/yard";
import Yard from "../../interfaces/yard";
import { searchAgency, selectAgencyItems } from "../../redux/reducers/agency";
import Agency from "../../interfaces/agency";
import {
    addRepo,
    fetchRepoLogs,
    fetchSingleRepo,
    setRepo,
    updateRepo,
} from "../../redux/reducers/repo";
import MaskedInput from "react-input-mask";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
    formatDateToYYYYMMDD,
} from "../../helpers/date";
import { fetchProfile } from "../../redux/reducers/user";
import { User } from "../../interfaces/user";
import { RepoLogs } from "../../interfaces/repo_logs";

const AddRepo: React.FC = () => {
    const navigate = useNavigate();
    const lanItems = useSelector(selectLanItems);
    const yardItems = useSelector(selectYardItems);
    const agencyItems = useSelector(selectAgencyItems);
    const userData: User = useSelector((state: any) => state.user.userData);

    const dispatch: Dispatch<any> = useDispatch();
    const repo = useSelector((state: any) => state.repo.repo);
    let logs: RepoLogs[] = useSelector((state: any) => state.repo.logs);

    const { repoId } = useParams();

    const [initialFormValues, setInitialFormValues] = useState({
        normal_repo_expenses: 0,
        additional_repo_expenses: 0,
        total_repo_expenses: 0,
    });

    useEffect(() => {
        if (repoId) {
            dispatch(
                fetchSingleRepo({
                    _id: repoId,
                })
            );
        } else {
            dispatch(setRepo({}));
        }
    }, [repoId, dispatch]);

    useEffect(() => {
        if (repoId) {
            dispatch(fetchRepoLogs(repoId));
        }
    }, [dispatch, repoId]);

    useEffect(() => {
        const values: any = {} as Repo;
        Object.keys(repo as keyof Repo).forEach((key) => {
            values[key] = repo[key];
        });
        values.lrn_date = formatDateToYYYYMMDD(values.lrn_date);
        values.repo_date = formatDateToYYYYMMDD(values.repo_date);
        values.surrender_date = formatDateToYYYYMMDD(values.surrender_date);
        values.yard_rent = values.yard_rent || "0";
        values.rc_location
            ? (values.is_original_rc = "y")
            : (values.is_original_rc = "n");

        setInitialFormValues(values);
    }, [repo]);

    useEffect(() => {
        dispatch(fetchProfile());
    }, [dispatch]);

    const onSubmit = (values: Repo) => {
        if (repoId) {
            dispatch(updateRepo(values));
        } else {
            dispatch(addRepo(values));
        }
        navigate("/repo-listing");
    };

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    const validate = (values: Repo) => {
        const errors: Partial<Record<keyof Repo, string>> = {};

        const requiredFields: (keyof Repo)[] = [
            "lan_no",
            "type",
            "via_sec",
            "is_empanelled_yard",
            "is_empanelled_repo_agency",
        ];

        requiredFields.forEach(
            (field) => !values[field] && (errors[field] = "Required")
        );

        if (values.is_lrn_upload === "y") {
            (["lrn"] as (keyof Repo)[]).forEach(
                (field) => !values[field] && (errors[field] = "Required")
            );
        }
        if (values.type === "repo") {
            (
                [
                    "pre_police_intimation",
                    "post_police_intimation",
                    "inventory",
                ] as (keyof Repo)[]
            ).forEach(
                (field) => !values[field] && (errors[field] = "Required")
            );
        } else if (values.type === "surrender") {
            (["inventory", "surrender_letter"] as (keyof Repo)[]).forEach(
                (field) => !values[field] && (errors[field] = "Required")
            );
        }

        const typeFields: Record<string, keyof Repo> = {
            repo: "repo_date",
            surrender: "surrender_date",
        };
        const empanelledYardFields: (keyof Repo)[] = [
            "empanelled_yard",
            "parking_yard_address",
        ];

        typeFields[values.type] &&
            !values[typeFields[values.type]] &&
            (errors[typeFields[values.type]] = "Required");

        values.is_original_rc === "y" &&
            !values.rc_location &&
            (errors.rc_location = "Required");

        values.is_empanelled_yard === "n" &&
            empanelledYardFields.forEach(
                (field) => !values[field] && (errors[field] = "Required")
            );

        values.is_empanelled_yard === "n" &&
            (
                [
                    "yard_contact_name",
                    "yard_contact_number",
                    "yard_rent",
                ] as (keyof Repo)[]
            ).forEach(
                (field) => !values[field] && (errors[field] = "Required")
            );

        values.is_empanelled_repo_agency === "y" &&
            !values.repo_agency_name &&
            (errors.repo_agency_name = "Required");

        values.is_additional_repo_expenses === "y" &&
            !values.additional_repo_expenses &&
            (errors.additional_repo_expenses = "Required");

        !values.normal_repo_expenses &&
            (errors.normal_repo_expenses = "Required");
        return errors;
    };

    const setFormValue = (form: any, key: string, value: any) => {
        form.change(key, value);
    };
    const handleLanInputChange = (value: any) => {
        dispatch(
            searchLan({
                search: value,
            })
        );
    };

    const handleEmpanelledYardChange = (form: any, value: any) => {
        setFormValue(form, "is_empanelled_yard", value);
        if (value === "n") {
            setFormValue(form, "empanelled_yard", "");
            setFormValue(form, "parking_yard_address", "");
            setFormValue(form, "parking_yard_code", "");
            setFormValue(form, "yard_contact_name", "");
            setFormValue(form, "yard_contact_number", "");
            setFormValue(form, "yard_rent", "");
        }
    };

    const handleEmpanelledAgencyChange = (form: any, value: any) => {
        setFormValue(form, "is_empanelled_repo_agency", value);
        if (value === "n") {
            setFormValue(form, "repo_agency_name", "");
        }
    };

    const onLanSelected = (form: any, value: Lan) => {
        setFormValue(form, "customer_name", value.customer_name);
        setFormValue(form, "lan_no", value.lan_no);
        setFormValue(form, "zone", value.zone);
        setFormValue(form, "region", value.region);
        setFormValue(form, "branch", value.branch);
        setFormValue(form, "portfolio", value.portfolio);
        setFormValue(form, "pos", value.pos);
        setFormValue(form, "other_charges", value.other_charges);
        setFormValue(form, "rc_no", value.rc_no);
        setFormValue(form, "chassis_no", value.chassis_no);
        setFormValue(form, "engine_number", value.engine_number);
        setFormValue(form, "make", value.make);
        setFormValue(form, "model", value.model);
        setFormValue(form, "mfg_year", value.mfg_year);
        setFormValue(form, "revised_focus_band", value.revised_focus_band);
        setFormValue(
            form,
            "total_emi_collectable",
            value.total_emi_collectable
        );
        setFormValue(form, "as_on_date_dpd", value.as_on_date_dpd);
        setFormValue(
            form,
            "total_gross_collection",
            value.total_gross_collection
        );
        setFormValue(form, "sec_17_9", value.sec_17_9 != null ? "Yes" : "No");
        setFormValue(form, "sec_138", value.sec_138?.length > 0 ? "Yes" : "No");
        setFormValue(
            form,
            "lrn_date",
            value.lrn_date ? formatDateToYYYYMMDD(value.lrn_date) : ""
        );
        setFormValue(
            form,
            "arbitration_status",
            value.arbitration_status?.length > 0 ? "Yes" : "No"
        );
    };

    const handleYardInputChange = (value: any) => {
        dispatch(
            searchYard({
                search: value,
            })
        );
    };

    const handleAgencyInputChange = (value: any) => {
        dispatch(
            searchAgency({
                search: value,
                is_active: true,
            })
        );
    };

    const onYardSelected = (form: any, value: Yard) => {
        setFormValue(form, "empanelled_yard", value.name);
        setFormValue(
            form,
            "parking_yard_address",
            `${value.address},${value.address_2},${value.area},${value.city},${value.state},${value.pincode}`
        );
        setFormValue(form, "parking_yard_code", value.code);
        setFormValue(form, "yard_contact_name", value.contact_name);
        setFormValue(form, "yard_contact_number", value.contact_number);
        setFormValue(form, "yard_rent", value.rent || "0");
    };

    const onAgencySelected = (form: any, value: Agency) => {
        setFormValue(form, "repo_agency_name", value.name);
    };

    const handleRepoAmountChange = (form: any, key: string, value: number) => {
        form.change(key, value);

        const normalRepoExpenses =
            parseInt(form.getState().values.normal_repo_expenses) || 0;
        const additionalRepoExpenses =
            parseInt(form.getState().values.additional_repo_expenses) || 0;

        const totalValue = normalRepoExpenses + additionalRepoExpenses;

        form.change("total_repo_expenses", isNaN(totalValue) ? 0 : totalValue);
    };

    const validateParkingYardName = (value: string) => {
        if (!value) {
            return "Required";
        }
        // Check if the value is purely numeric
        if (/^\d+$/.test(value)) {
            return "Parking yard name cannot be purely numeric";
        }
        return undefined;
    };

    const validateParkingYardAddress = (value: string) => {
        if (!value) {
            return "Required";
        }
        // Check if the value is purely numeric
        if (/^\d+$/.test(value)) {
            return "Parking yard address cannot be purely numeric";
        }
        return undefined;
    };

    const validateParkingYardContact = (value: string) => {
        if (!value) {
            return "Required";
        }
        // Check if the value is purely numeric
        if (/^\d+$/.test(value)) {
            return "Parking yard contact person cannot be purely numeric";
        }
        return undefined;
    };

    const validateRepoAgencyName = (value: string) => {
        if (!value) {
            return "Required";
        }
        // Check if the value is purely numeric
        if (/^\d+$/.test(value)) {
            return "Repo agency name cannot be purely numeric";
        }
        return undefined;
    };

    return (
        <div>
            <h4 className="py-3 mb-4">
                {repoId ? "Edit Entry" : "Add New Entry"}{" "}
            </h4>
            <div className="row">
                <div className="col-xxl">
                    <div className="card mb-4">
                        <div className="card-body">
                            <Form
                                initialValues={initialFormValues}
                                onSubmit={onSubmit}
                                validate={validate}
                                render={({
                                    handleSubmit,
                                    form,
                                    submitting,
                                    pristine,
                                }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Search LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={
                                                                repoId == null
                                                                    ? false
                                                                    : true
                                                            }
                                                            component={
                                                                AutocompleteField
                                                            }
                                                            name="search_lan_no"
                                                            icon="ti ti-hash"
                                                            placeholder="Search LAN No"
                                                            aria-label="Search LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            suggestions={
                                                                lanItems
                                                            } // Provide suggestions from Redux state
                                                            renderItem={(
                                                                item: any
                                                            ) => (
                                                                <div className="suggestion-item">
                                                                    {
                                                                        item.lan_no
                                                                    }
                                                                </div>
                                                            )} // Customize how each suggestion is rendered
                                                            getKey={(
                                                                item: any
                                                            ) => item._id} // Adjust this based on your Lan interface
                                                            onInputChange={
                                                                handleLanInputChange
                                                            } // Provide the callback prop
                                                            onSelected={(
                                                                value: any
                                                            ) => {
                                                                onLanSelected(
                                                                    form,
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    {/* <Field name="lan_no" render={({ meta }) => (
                                            <div className="error">
                                                {meta.error && meta.touched ? <span>{meta.error}</span> : null}
                                            </div>
                                        )} /> */}
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    LAN No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lan_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="LAN No"
                                                            aria-label="LAN No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lan_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Customer Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="customer_name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Customer Name"
                                                            aria-label="Customer Name"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="customer_name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Zone
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="zone"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Zone"
                                                            aria-label="Zone"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="zone"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Region
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-map-pin"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="region"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Region"
                                                            aria-label="Region"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="region"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Branch
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-smart-home"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="branch"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Branch"
                                                            aria-label="Branch"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="branch"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Portfolio
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-report-analytics"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="portfolio"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Portfolio"
                                                            aria-label="Portfolio"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="portfolio"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    POS
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="pos"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="POS"
                                                            aria-label="POS"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="pos"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Other Charges
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            initialValue={0}
                                                            disabled={true}
                                                            component="input"
                                                            name="other_charges"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Other Charges"
                                                            aria-label="Other Charges"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="other_charges"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    RC No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="rc_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="RC No"
                                                            aria-label="RC No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="rc_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Chassis No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-file-certificate"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="chassis_no"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Chassis No"
                                                            aria-label="Chassis No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="chassis_no"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Engine Number
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-engine"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="engine_number"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Engine Number"
                                                            aria-label="Engine Number"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="engine_number"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Make
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-layers-intersect"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="make"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Make"
                                                            aria-label="Make"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="make"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Model
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-star"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="model"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Model"
                                                            aria-label="Model"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="model"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Year of Manufacturing
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>

                                                        <Field
                                                            component="input"
                                                            name="mfg_year"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Year of Manufacturing"
                                                            aria-label="Year of Manufacturing"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        >
                                                            {({ input }) => (
                                                                <MaskedInput
                                                                    disabled={
                                                                        true
                                                                    }
                                                                    className="form-control"
                                                                    {...input}
                                                                    mask="9999"
                                                                    placeholder="YYYY"
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Revised Focus Band
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="revised_focus_band"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Revised Focus Band"
                                                            aria-label="Revised Focus Band"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total EMI Collectable
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_emi_collectable"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total EMI Collectable"
                                                            aria-label="Total EMI Collectable"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    As on date DPD
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="as_on_date_dpd"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="As on date DPD"
                                                            aria-label="As on date DPD"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Total Gross Collection
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-calendar-stats"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_gross_collection"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total Gross Collection"
                                                            aria-label="Total Gross Collection"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    LRN Date
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="lrn_date"
                                                            type="date"
                                                            id="multicol-birthdate"
                                                            className="form-control dob-picker"
                                                            placeholder="YYYY-MM-DD"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="lrn_date"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Sec 17/ Sec 9 available:
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="sec_17_9"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Sec 138 available
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="sec_138"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Any litigation against case
                                                    available
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="arbitration_status"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder=""
                                                            aria-label=""
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        ></Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Is it a Repo Vehicle or
                                                    Surrender Vehicle
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="form-check form-check-inline mt-2">
                                                        <Field
                                                            component="input"
                                                            name="type"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio1"
                                                            value="repo"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio1"
                                                        >
                                                            Repo
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            component="input"
                                                            name="type"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio2"
                                                            value="surrender"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio2"
                                                        >
                                                            Surrender
                                                        </label>
                                                    </div>
                                                    <Field
                                                        name="type"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Via Sec 17/ Sec 9
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="form-check form-check-inline mt-2">
                                                        <Field
                                                            component="input"
                                                            name="via_sec"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio1"
                                                            value="Yes"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio1"
                                                        >
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            component="input"
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="via_sec"
                                                            id="inlineRadio2"
                                                            value="No"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio2"
                                                        >
                                                            No
                                                        </label>
                                                    </div>
                                                    <Field
                                                        name="via_sec"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />

                                            <div className="row mb-1">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Do you want to upload your
                                                    LRN
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="form-check form-check-inline mt-2">
                                                        <Field
                                                            component="input"
                                                            name="is_lrn_upload"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio1"
                                                            value="y"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio1"
                                                        >
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            component="input"
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="is_lrn_upload"
                                                            id="inlineRadio2"
                                                            value="n"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio2"
                                                        >
                                                            no
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-5">
                                                <label className="col-sm-3 col-form-label"></label>
                                                <div className="col-sm-9">
                                                    <div className="row row-gap-4">
                                                        {form.getState().values
                                                            .is_lrn_upload ===
                                                            "y" && (
                                                            <div className="col-md-4">
                                                                <div className="fallback">
                                                                    <label className="col-form-label">
                                                                        Upload
                                                                        LRN
                                                                    </label>
                                                                    <Field name="lrn">
                                                                        {({
                                                                            input,
                                                                        }) => (
                                                                            <input
                                                                                type="file"
                                                                                onChange={(
                                                                                    event
                                                                                ) =>
                                                                                    input.onChange(
                                                                                        event
                                                                                            .target
                                                                                            .files?.[0]
                                                                                    )
                                                                                }
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </div>

                                                                <Field
                                                                    name="lrn"
                                                                    render={({
                                                                        meta,
                                                                    }) => (
                                                                        <div className="error">
                                                                            {meta.error ? (
                                                                                <span>
                                                                                    {
                                                                                        meta.error
                                                                                    }
                                                                                </span>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="col-md-4">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Pre Police
                                                                    Intimation
                                                                </label>
                                                                <Field name="pre_police_intimation">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <Field
                                                                name="pre_police_intimation"
                                                                render={({
                                                                    meta,
                                                                }) => (
                                                                    <div className="error">
                                                                        {meta.error ? (
                                                                            <span>
                                                                                {
                                                                                    meta.error
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Post Police
                                                                    Intimation
                                                                </label>
                                                                <Field name="post_police_intimation">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <Field
                                                                name="post_police_intimation"
                                                                render={({
                                                                    meta,
                                                                }) => (
                                                                    <div className="error">
                                                                        {meta.error ? (
                                                                            <span>
                                                                                {
                                                                                    meta.error
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Inventory
                                                                    Upload
                                                                </label>
                                                                <Field name="inventory">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <Field
                                                                name="inventory"
                                                                render={({
                                                                    meta,
                                                                }) => (
                                                                    <div className="error">
                                                                        {meta.error ? (
                                                                            <span>
                                                                                {
                                                                                    meta.error
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="fallback">
                                                                <label className="col-form-label">
                                                                    Surrender
                                                                    Letter
                                                                </label>
                                                                <Field name="surrender_letter">
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            type="file"
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                input.onChange(
                                                                                    event
                                                                                        .target
                                                                                        .files?.[0]
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <Field
                                                                name="surrender_letter"
                                                                render={({
                                                                    meta,
                                                                }) => (
                                                                    <div className="error">
                                                                        {meta.error ? (
                                                                            <span>
                                                                                {
                                                                                    meta.error
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {repoId && (
                                                <>
                                                    <div className="row mb-1">
                                                        <label
                                                            className="col-sm-4 col-form-label text-sm-end text-primary"
                                                            htmlFor="multicol-birthdate"
                                                        >
                                                            Uploaded LRN :
                                                        </label>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <label className="col-sm-4 col-form-label"></label>
                                                        <div className="col-sm-8">
                                                            <div className="row row-gap-4">
                                                                <div className="col-md-4">
                                                                    {repo.lrn && (
                                                                        <a
                                                                            href={repo.lrn?.toString()}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className="d-flex align-items-center"
                                                                        >
                                                                            <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                <i className="ti ti-file-text ti-sm"></i>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                <div className="me-2">
                                                                                    <h6 className="mb-0">
                                                                                        LRN
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    {repo.pre_police_intimation && (
                                                                        <a
                                                                            href={repo.pre_police_intimation?.toString()}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className="d-flex align-items-center"
                                                                        >
                                                                            <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                <i className="ti ti-file-text ti-sm"></i>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                <div className="me-2">
                                                                                    <h6 className="mb-0">
                                                                                        Pre
                                                                                        Police
                                                                                        Intimation
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    {repo.post_police_intimation && (
                                                                        <a
                                                                            href={repo.post_police_intimation?.toString()}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className="d-flex align-items-center"
                                                                        >
                                                                            <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                <i className="ti ti-file-text ti-sm"></i>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                <div className="me-2">
                                                                                    <h6 className="mb-0">
                                                                                        Post
                                                                                        Police
                                                                                        Intimation
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    {repo.inventory && (
                                                                        <a
                                                                            href={repo.inventory?.toString()}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className="d-flex align-items-center"
                                                                        >
                                                                            <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                <i className="ti ti-file-text ti-sm"></i>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                <div className="me-2">
                                                                                    <h6 className="mb-0">
                                                                                        Inventory
                                                                                        Upload
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <div className="col-md-4">
                                                                    {repo.surrender_letter && (
                                                                        <a
                                                                            href={repo.surrender_letter?.toString()}
                                                                            target="_blank"
                                                                            rel="noreferrer"
                                                                            className="d-flex align-items-center"
                                                                        >
                                                                            <div className="badge bg-label-primary p-2 me-3 rounded">
                                                                                <i className="ti ti-file-text ti-sm"></i>
                                                                            </div>
                                                                            <div className="d-flex justify-content-between w-100 flex-wrap gap-2">
                                                                                <div className="me-2">
                                                                                    <h6 className="mb-0">
                                                                                        Surrender
                                                                                        Letter
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <hr className="my-4 mx-n4" />
                                            {form.getState().values.type ===
                                                "repo" && (
                                                <div className="row mb-3">
                                                    <label
                                                        className="col-sm-3 col-form-label text-sm-end"
                                                        htmlFor="multicol-birthdate"
                                                    >
                                                        Date of Repo
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-merge">
                                                            <Field
                                                                component="input"
                                                                name="repo_date"
                                                                type="date"
                                                                id="multicol-birthdate"
                                                                className="form-control dob-picker"
                                                                placeholder="YYYY-MM-DD"
                                                            />
                                                        </div>
                                                        <Field
                                                            name="repo_date"
                                                            render={({
                                                                meta,
                                                            }) => (
                                                                <div className="error">
                                                                    {meta.error &&
                                                                    meta.touched ? (
                                                                        <span>
                                                                            {
                                                                                meta.error
                                                                            }
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            {form.getState().values.type ===
                                                "surrender" && (
                                                <div className="row mb-3">
                                                    <label
                                                        className="col-sm-3 col-form-label text-sm-end"
                                                        htmlFor="multicol-birthdate"
                                                    >
                                                        Surrender Date
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <Field
                                                            component="input"
                                                            name="surrender_date"
                                                            type="date"
                                                            id="multicol-birthdate"
                                                            className="form-control dob-picker"
                                                            placeholder="YYYY-MM-DD"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="surrender_date"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            )}

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-1">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Original RC Book
                                                    Availability
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="form-check form-check-inline mt-2">
                                                        <Field
                                                            component="input"
                                                            name="is_original_rc"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio1"
                                                            value="y"
                                                        />
                                                        <label className="form-check-label">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            component="input"
                                                            name="is_original_rc"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio2"
                                                            value="n"
                                                        />
                                                        <label className="form-check-label">
                                                            no
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {form.getState().values
                                                .is_original_rc === "y" && (
                                                <div className="row mb-1">
                                                    <label
                                                        className="col-sm-3 col-form-label text-sm-end"
                                                        htmlFor="multicol-birthdate"
                                                    >
                                                        RC book lying at Branch
                                                        or Stockyard
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="form-check form-check-inline mt-2">
                                                            <Field
                                                                component="input"
                                                                name="rc_location"
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="inlineRadio1"
                                                                value="branch"
                                                            />
                                                            <label className="form-check-label">
                                                                Branch
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <Field
                                                                component="input"
                                                                name="rc_location"
                                                                className="form-check-input"
                                                                type="radio"
                                                                id="inlineRadio2"
                                                                value="stockyard"
                                                            />
                                                            <label className="form-check-label">
                                                                Stockyard
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {form.getState().values
                                                .is_original_rc !== "y" && (
                                                <>
                                                    {form.change(
                                                        "rc_location",
                                                        undefined
                                                    )}
                                                </>
                                            )}

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-1">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Is Stockyard Empanelled
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="form-check form-check-inline mt-2">
                                                        <Field
                                                            component="input"
                                                            className="form-check-input"
                                                            name="is_empanelled_yard"
                                                            type="radio"
                                                            id="inlineRadio1"
                                                            value="y"
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                handleEmpanelledYardChange(
                                                                    form,
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                        <label className="form-check-label">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            component="input"
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="is_empanelled_yard"
                                                            id="inlineRadio2"
                                                            value="n"
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                handleEmpanelledYardChange(
                                                                    form,
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                        <label className="form-check-label">
                                                            no
                                                        </label>
                                                    </div>
                                                    <Field
                                                        name="is_empanelled_yard"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {form.getState().values
                                                .is_empanelled_yard === "y" && (
                                                <div className="row mb-1">
                                                    <label className="col-sm-3 col-form-label text-sm-end">
                                                        Search Parking Yard Name
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-merge">
                                                            <Field
                                                                component={
                                                                    AutocompleteField
                                                                }
                                                                name="search_empanelled_yard"
                                                                placeholder="Search Parking Yard Name"
                                                                aria-label="Search Parking Yard Name"
                                                                aria-describedby="basic-icon-default-fullname2"
                                                                suggestions={
                                                                    yardItems
                                                                } // Provide suggestions from Redux state
                                                                renderItem={(
                                                                    item: any
                                                                ) => (
                                                                    <div className="suggestion-item">
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                )} // Customize how each suggestion is rendered
                                                                getKey={(
                                                                    item: any
                                                                ) => item._id} // Adjust this based on your Lan interface
                                                                onInputChange={
                                                                    handleYardInputChange
                                                                } // Provide the callback prop
                                                                onSelected={(
                                                                    value: any
                                                                ) =>
                                                                    onYardSelected(
                                                                        form,
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <br />
                                            <div className="row mb-1">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Parking Yard Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={
                                                                form.getState()
                                                                    .values
                                                                    .is_empanelled_yard !==
                                                                "n"
                                                            }
                                                            component="input"
                                                            name="empanelled_yard"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Parking Yard Name"
                                                            aria-label="Model"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            validate={
                                                                validateParkingYardName
                                                            }
                                                        />
                                                    </div>
                                                    <Field
                                                        name="empanelled_yard"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-1">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Parking Yard Address
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <Field
                                                            disabled={
                                                                form.getState()
                                                                    .values
                                                                    .is_empanelled_yard !==
                                                                "n"
                                                            }
                                                            component="input"
                                                            name="parking_yard_address"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Address"
                                                            aria-label="Model"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            validate={
                                                                validateParkingYardAddress
                                                            }
                                                        />
                                                    </div>
                                                    <Field
                                                        name="parking_yard_address"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {form.getState().values
                                                .is_empanelled_yard === "y" && (
                                                <div className="row mb-1">
                                                    <label
                                                        className="col-sm-3 col-form-label text-sm-end"
                                                        htmlFor="multicol-birthdate"
                                                    >
                                                        Parking Yard Code
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-merge">
                                                            <Field
                                                                disabled={
                                                                    form.getState()
                                                                        .values
                                                                        .is_empanelled_yard !==
                                                                    "n"
                                                                }
                                                                component="input"
                                                                name="parking_yard_code"
                                                                className="form-control"
                                                                id="basic-icon-default-fullname"
                                                                placeholder="Code"
                                                                aria-label="Model"
                                                                aria-describedby="basic-icon-default-fullname2"
                                                            />
                                                        </div>
                                                        <Field
                                                            name="parking_yard_code"
                                                            render={({
                                                                meta,
                                                            }) => (
                                                                <div className="error">
                                                                    {meta.error &&
                                                                    meta.touched ? (
                                                                        <span>
                                                                            {
                                                                                meta.error
                                                                            }
                                                                        </span>
                                                                    ) : null}
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            )}

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Yard Contact Person
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={
                                                                form.getState()
                                                                    .values
                                                                    .is_empanelled_yard !==
                                                                "n"
                                                            }
                                                            component="input"
                                                            name="yard_contact_name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Yard Contact Person"
                                                            aria-label="Yard Contact Person"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            validate={
                                                                validateParkingYardContact
                                                            }
                                                        />
                                                    </div>
                                                    <Field
                                                        name="yard_contact_name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Yard Contact Person Contact
                                                    No
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-phone"></i>
                                                        </span>
                                                        <Field
                                                            name="yard_contact_number"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Contact No"
                                                            aria-label="Contact No"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        >
                                                            {({ input }) => (
                                                                <MaskedInput
                                                                    disabled={
                                                                        form.getState()
                                                                            .values
                                                                            .is_empanelled_yard !==
                                                                        "n"
                                                                    }
                                                                    className="form-control"
                                                                    {...input}
                                                                    mask="9999999999"
                                                                    placeholder="9999999999"
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <Field
                                                        name="yard_contact_number"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Yard Rent per day
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            // disabled={
                                                            //     form.getState()
                                                            //         .values
                                                            //         .is_empanelled_yard !==
                                                            //     "n"
                                                            // }
                                                            type="number"
                                                            initialValue={"0"}
                                                            component="input"
                                                            name="yard_rent"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Yard Rent per day"
                                                            aria-label="Yard Rent per day"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="yard_rent"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-1">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Is Repo Agency Empanelled
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="form-check form-check-inline mt-2">
                                                        <Field
                                                            component="input"
                                                            name="is_empanelled_repo_agency"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio1"
                                                            value="y"
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                handleEmpanelledAgencyChange(
                                                                    form,
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                        <label className="form-check-label">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            component="input"
                                                            className="form-check-input"
                                                            name="is_empanelled_repo_agency"
                                                            type="radio"
                                                            id="inlineRadio2"
                                                            value="n"
                                                            onChange={(
                                                                event: any
                                                            ) => {
                                                                handleEmpanelledAgencyChange(
                                                                    form,
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                        <label className="form-check-label">
                                                            no
                                                        </label>
                                                    </div>
                                                    <Field
                                                        name="is_empanelled_repo_agency"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {form.getState().values
                                                .is_empanelled_repo_agency ===
                                                "y" && (
                                                <div className="row mb-3">
                                                    <label
                                                        className="col-sm-3 col-form-label text-sm-end"
                                                        htmlFor="basic-default-name"
                                                    >
                                                        Search Repo Agency Name
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-merge">
                                                            <Field
                                                                component={
                                                                    AutocompleteField
                                                                }
                                                                name="search_repo_agency_name"
                                                                icon="ti ti-building"
                                                                placeholder="Search Repo Agency Name"
                                                                aria-label="Search Repo Agency Name"
                                                                aria-describedby="basic-icon-default-fullname2"
                                                                suggestions={
                                                                    agencyItems
                                                                } // Provide suggestions from Redux state
                                                                renderItem={(
                                                                    item: any
                                                                ) => (
                                                                    <div className="suggestion-item">
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </div>
                                                                )} // Customize how each suggestion is rendered
                                                                getKey={(
                                                                    item: any
                                                                ) => item._id} // Adjust this based on your Lan interface
                                                                onInputChange={
                                                                    handleAgencyInputChange
                                                                } // Provide the callback prop
                                                                onSelected={(
                                                                    value: any
                                                                ) =>
                                                                    onAgencySelected(
                                                                        form,
                                                                        value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Repo Agency Name
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <Field
                                                            disabled={
                                                                form.getState()
                                                                    .values
                                                                    .is_empanelled_repo_agency !==
                                                                "n"
                                                            }
                                                            component="input"
                                                            name="repo_agency_name"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Repo Agency Name"
                                                            aria-label="Repo Agency Name"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                            validate={
                                                                validateRepoAgencyName
                                                            }
                                                        />
                                                    </div>
                                                    <Field
                                                        name="repo_agency_name"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>

                                            <hr className="my-4 mx-n4" />
                                            <div className="row mb-1">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="multicol-birthdate"
                                                >
                                                    Is there any additional Repo
                                                    Expenses
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="form-check form-check-inline mt-2">
                                                        <Field
                                                            component="input"
                                                            name="is_additional_repo_expenses"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio1"
                                                            value="y"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio1"
                                                        >
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field
                                                            component="input"
                                                            name="is_additional_repo_expenses"
                                                            className="form-check-input"
                                                            type="radio"
                                                            id="inlineRadio2"
                                                            value="n"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="inlineRadio2"
                                                        >
                                                            no
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {form.getState().values
                                                .is_additional_repo_expenses ===
                                                "y" && (
                                                <>
                                                    <div className="row mb-3">
                                                        <label
                                                            className="col-sm-3 col-form-label text-sm-end"
                                                            htmlFor="basic-default-name"
                                                        >
                                                            Additonal repo
                                                            expenses amount
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <div className="input-group input-group-merge">
                                                                <span
                                                                    id="basic-icon-default-fullname2"
                                                                    className="input-group-text"
                                                                >
                                                                    <i className="ti ti-cash"></i>
                                                                </span>
                                                                <Field
                                                                    component="input"
                                                                    name="additional_repo_expenses"
                                                                    className="form-control"
                                                                    id="basic-icon-default-fullname"
                                                                    placeholder="Additon repo expenses amount"
                                                                    aria-label="Additon repo expenses amount"
                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                >
                                                                    {({
                                                                        input,
                                                                    }) => (
                                                                        <input
                                                                            className="form-control"
                                                                            {...input}
                                                                            type="number"
                                                                            onChange={(
                                                                                event: any
                                                                            ) => {
                                                                                handleRepoAmountChange(
                                                                                    form,
                                                                                    "additional_repo_expenses",
                                                                                    event
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <Field
                                                                name="additional_repo_expenses"
                                                                render={({
                                                                    meta,
                                                                }) => (
                                                                    <div className="error">
                                                                        {meta.error &&
                                                                        meta.touched ? (
                                                                            <span>
                                                                                {
                                                                                    meta.error
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">
                                                        <label
                                                            className="col-sm-3 col-form-label text-sm-end"
                                                            htmlFor="basic-default-name"
                                                        >
                                                            Reason for
                                                            additional repo
                                                            expenses
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <div className="input-group input-group-merge">
                                                                <span
                                                                    id="basic-icon-default-fullname2"
                                                                    className="input-group-text"
                                                                >
                                                                    <i className="ti ti-cash"></i>
                                                                </span>
                                                                <Field
                                                                    component="input"
                                                                    name="additional_expenses_reason"
                                                                    className="form-control"
                                                                    id="basic-icon-default-fullname"
                                                                    placeholder="Reason for additional repo expenses"
                                                                    aria-label="Reasonof additional repo expenses"
                                                                    aria-describedby="basic-icon-default-fullname2"
                                                                />
                                                            </div>
                                                            <Field
                                                                name="additional_expenses_reason"
                                                                render={({
                                                                    meta,
                                                                }) => (
                                                                    <div className="error">
                                                                        {meta.error &&
                                                                        meta.touched ? (
                                                                            <span>
                                                                                {
                                                                                    meta.error
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {form.getState().values
                                                .is_additional_repo_expenses !==
                                                "y" && (
                                                <>
                                                    {form.change(
                                                        "additional_repo_expenses",
                                                        undefined
                                                    )}
                                                    {form.change(
                                                        "additional_expenses_reason",
                                                        undefined
                                                    )}
                                                </>
                                            )}

                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Normal Repo Expenses
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            name="normal_repo_expenses"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Normal repo Expenses"
                                                            aria-label="Normal repo Expenses"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        >
                                                            {({ input }) => (
                                                                <input
                                                                    className="form-control"
                                                                    {...input}
                                                                    type="number"
                                                                    onChange={(
                                                                        event: any
                                                                    ) => {
                                                                        handleRepoAmountChange(
                                                                            form,
                                                                            "normal_repo_expenses",
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <Field
                                                        name="normal_repo_expenses"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Total Repo Expenses
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            disabled={true}
                                                            component="input"
                                                            name="total_repo_expenses"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Total Repo Expenses"
                                                            aria-label="Total Repo Expenses"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="total_repo_expenses"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <label
                                                    className="col-sm-3 col-form-label text-sm-end"
                                                    htmlFor="basic-default-name"
                                                >
                                                    Remarks
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-merge">
                                                        <span
                                                            id="basic-icon-default-fullname2"
                                                            className="input-group-text"
                                                        >
                                                            <i className="ti ti-cash"></i>
                                                        </span>
                                                        <Field
                                                            component="textarea"
                                                            name="repo_remarks"
                                                            className="form-control"
                                                            id="basic-icon-default-fullname"
                                                            placeholder="Remarks"
                                                            aria-label="Remarks"
                                                            aria-describedby="basic-icon-default-fullname2"
                                                        />
                                                    </div>
                                                    <Field
                                                        name="repo_remarks"
                                                        render={({ meta }) => (
                                                            <div className="error">
                                                                {meta.error &&
                                                                meta.touched ? (
                                                                    <span>
                                                                        {
                                                                            meta.error
                                                                        }
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            {form.getState().values
                                                .assigned_by && (
                                                <>
                                                    <hr className="my-4 mx-n4" />
                                                    <div className="row mb-3">
                                                        <label
                                                            className="col-sm-3 col-form-label text-sm-end"
                                                            htmlFor="basic-default-name"
                                                        >
                                                            Status :
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <div className="input-group input-group-merge">
                                                                <span
                                                                    style={{
                                                                        marginTop:
                                                                            "8px",
                                                                    }}
                                                                >
                                                                    {getStatusText(
                                                                        repo.status
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label
                                                            className="col-sm-3 col-form-label text-sm-end"
                                                            htmlFor="basic-default-name"
                                                        >
                                                            Assigned By :
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <div className="input-group input-group-merge">
                                                                <span
                                                                    style={{
                                                                        marginTop:
                                                                            "8px",
                                                                    }}
                                                                >
                                                                    {
                                                                        repo
                                                                            .assigned_by
                                                                            .name
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <label
                                                            className="col-sm-3 col-form-label text-sm-end"
                                                            htmlFor="basic-default-name"
                                                        >
                                                            Remarks :
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <div className="input-group input-group-merge">
                                                                <span
                                                                    style={{
                                                                        marginTop:
                                                                            "8px",
                                                                    }}
                                                                >
                                                                    {
                                                                        repo.remarks
                                                                    }
                                                                </span>
                                                            </div>
                                                            <Field
                                                                name="remarks"
                                                                render={({
                                                                    meta,
                                                                }) => (
                                                                    <div className="error">
                                                                        {meta.error &&
                                                                        meta.touched ? (
                                                                            <span>
                                                                                {
                                                                                    meta.error
                                                                                }
                                                                            </span>
                                                                        ) : null}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            <hr className="my-4 mx-n4" />
                                            {(form.getState().values.status ==
                                                "rejected_l1" ||
                                                form.getState().values.status ==
                                                    "rejected_l2" ||
                                                form.getState().values
                                                    .maker_remarks?.length >
                                                    0) && (
                                                <div className="row mb-3">
                                                    <label
                                                        className="col-sm-3 col-form-label text-sm-end"
                                                        htmlFor="basic-default-name"
                                                    >
                                                        Maker Remarks
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-merge">
                                                            <span
                                                                id="basic-icon-default-fullname2"
                                                                className="input-group-text"
                                                            >
                                                                <i className="ti ti-cash"></i>
                                                            </span>
                                                            <Field
                                                                disabled={
                                                                    userData
                                                                        .role
                                                                        .name !==
                                                                    "maker"
                                                                }
                                                                component="input"
                                                                name="maker_remarks"
                                                                className="form-control"
                                                                id="basic-icon-default-fullname"
                                                                placeholder="Maker Remarks"
                                                                aria-label="Maker Remarks"
                                                                aria-describedby="basic-icon-default-fullname2"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            <hr className="my-4 mx-n4" />

                                            <div className="row justify-content-end">
                                                <div className="col-sm-9">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                                                        SAVE DETAILS
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            />
                        </div>
                    </div>
                    {repoId && (
                        <>
                            <h4 className="py-3 mb-1">Audit Trail</h4>
                            <div className="row">
                                {logs.map((log: RepoLogs) => {
                                    return (
                                        <div className="col-md-3 col-sm-6 col-12 mb-4">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h6 className="mb-0">
                                                        {formatDateToDDMMYYYYWithTime(
                                                            log.createdAt
                                                        )}
                                                    </h6>
                                                    <p className="mb-0">
                                                        {log?.uid?.name}
                                                    </p>
                                                    <p className="mb-0">
                                                        {getStatusText(
                                                            log?.status
                                                        )}
                                                    </p>
                                                    <p className="mb-0">
                                                        {log.remarks}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className="content-backdrop fade"></div>
        </div>
    );
};
export default AddRepo;
