import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/instance';
import ErrorResponse from '../../interfaces/ErrorResponse';
import Agency from '../../interfaces/agency';

interface AgencyState {
  items: Agency[];
  loading: boolean | null;
  error: string | null;
  suggestions:Array<string>;
  agency: Agency;
  added: boolean | null,
  imported:boolean | null
}

export interface searchParam{
    search?: string,
    _id?:string,
    is_active?:boolean
}


export const searchAgency = createAsyncThunk('/searchAgency', async (params:searchParam, { rejectWithValue }) => {
  try {
    const response = await api.get('/master/repo-agency',{params: params});
    return response.data as Agency[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const addAgency = createAsyncThunk('/addAgency', async (values:Agency, { rejectWithValue }) => {
  try {
    const response = await api.post('/master/repo-agency',values);
    return response.data as Agency;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const updateAgency = createAsyncThunk('/updateAgency', async (values:Agency, { rejectWithValue }) => {
  try {
    const response = await api.put('/master/repo-agency',values);
    return response.data as Agency;
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

export const fetchSingleAgency = createAsyncThunk('/fetchSingleAgency', async (params:searchParam, { rejectWithValue }) => {
  try {
    const response = await api.get('/master/repo-agency',{params: params});
    return response.data as Agency[];
  } catch (error) {
    return rejectWithValue({
      message: (error as any)?.response?.data || 'An error occurred',
    });
  }
});

interface bulkAgencyParams{
  file:any
}

export const importBulkAgency = createAsyncThunk('/importBulkAgency', async (value:bulkAgencyParams, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    formData.append('repo', value.file);
    const response = await api.post('/master/import-repo-agency', formData);
    return response.data as any;
  } catch (error:any) {
    return rejectWithValue(error.response.data.error);
  }
});

export const exportRepoAgency = createAsyncThunk(
  'exportRepoAgency',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get('/master/export-repo-agency', {
        responseType: 'arraybuffer',
      });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'repo_agency_data.xlsx');
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      return { success: true };
    } catch (error:any) {
      console.error('Error downloading RepoAgency data:', error);
      return rejectWithValue({
        message: error.response?.data || 'An error occurred while downloading RepoAgency data',
      });
    }
  }
);

const agencySlice = createSlice({
  name: 'agency',
  initialState: {
    items: [],
    loading: null,
    error: null,
    suggestions:[],
    agency:{} as Agency,
    added: null,
    imported: null
  } as AgencyState,
  reducers: {
    setAgency:(state,action) =>{
        state.agency = action.payload;
    },
    clearAgencyList:(state) =>{
      state.items = [];
    },
    setAgencyAdded:(state,action)=>{
      state.added = action.payload
    },
    setAgencyImported:(state,action) =>{
      state.imported = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchAgency.pending, (state) => {
        state.loading = true;
      })
      .addCase(searchAgency.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(searchAgency.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as ErrorResponse | undefined)?.message || 'An error occurred';
      })
      .addCase(fetchSingleAgency.fulfilled, (state, action) => {
        state.loading = false;
        state.agency = action.payload[0];
      })
      .addCase(addAgency.fulfilled, (state, action) => {
        state.items.unshift(action.payload);
        state.added = true;
      })
      .addCase(updateAgency.fulfilled, (state, action) => {
        state.added = true;
      })
      .addCase(importBulkAgency.fulfilled, (state, action) => {
        state.imported = true;
      }).addCase(importBulkAgency.rejected, (state, action) => {
        state.imported = null;
        alert(action.payload || 'Invalid records found');
      })
  },
});

export const { setAgency, clearAgencyList, setAgencyAdded, setAgencyImported } = agencySlice.actions;
export const selectAgencyItems = (state:{agency:AgencyState}) => state.agency.items;
export default agencySlice.reducer;
