import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Link, useNavigate } from "react-router-dom";
import {
    fetchExportRepos,
    fetchRepos,
    getRepoItems,
    updateRepoStatus,
} from "../../redux/reducers/repo";
import Repo from "../../interfaces/repo";
import { User } from "../../interfaces/user";
import {
    formatDateToDDMMYYYY,
    formatDateToDDMMYYYYWithTime,
} from "../../helpers/date";
import * as XLSX from "xlsx";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import { fetchRegions } from "../../redux/reducers/lan";
import InfiniteScroll from "react-infinite-scroll-component";

const RepoListing: React.FC = () => {
    const navigate = useNavigate();
    const dispatch: Dispatch<any> = useDispatch();
    const [page, setPage] = useState(1);
    const repoItems = useSelector(getRepoItems);
    const exportRepoItems: Repo[] = useSelector(
        (state: any) => state.repo.exportItems
    );
    const userData: User = useSelector((state: any) => state.user.userData);
    const regions: string[] = useSelector((state: any) => state.lan.regions);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [remark, setRemark] = useState("");
    const [selectedRepo, setRepo] = useState({} as Repo);

    const navigateToAdd = () => {
        navigate("/add-repo");
    };

    const fetchRepoData = async () => {
        dispatch(fetchRepos({ page: page }));
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        fetchRepoData();
        dispatch(fetchRegions());
    }, [dispatch]);

    const handleRegionChange = (values: any) => {
        let regions = values.map((value: any) => value.value);
        regions = regions.join(",");

        dispatch(fetchRepos({ regions: regions }));
    };

    useEffect(() => {
        if (exportRepoItems.length > 0) {
            exportData();
        }
    }, [exportRepoItems]);

    const exportData = () => {
        const customHeaders = [
            "LAN No",
            "Customer Name",
            "Zone",
            "Region",
            "Branch",
            "Portfolio",
            "POS",
            "Other Charges",
            "RC No",
            "Chassis No",
            "Engine Number",
            "Make",
            "Model",
            "Year of Manufacturing",
            "Sec 17/ Sec 9 available",
            "Sec 138 available",
            "Any litigation against case available",
            "Is it a Repo Vehicle or Surrender Vehicle",
            "Via Sec 17/ Sec 9",
            "LRN Date",
            "Date of Repo",
            "Date of Surrender",
            "Original RC Book Availability",
            "RC book lying at Branch or Stockyard",
            "Is Stockyard Empanelled",
            "Parking Yard Name",
            "Parking Yard Address",
            "Parking Yard Code",
            "Yard Contact Person",
            "Yard Contact Person Contact No",
            "Yard Rent per day",
            "Is Repo Agency Empanelled",
            "Repo Agency Name",
            "Is there any additional Repo Expenses",
            "Reason for additional repo expenses",
            "Normal Repo Expenses",
            "Total Repo Expenses",
            "Revised Focus Band",
            "Total EMI Collectable",
            "As on date DPD",
            "Total Gross Collection",
            "LRN",
            "Pre police initimation",
            "Post police intimation",
            "Inventory",
            "Surrender letter",
            "Checked By",
            "Checker Approved Date",
            "Checker Rejected Date",
            "Approved By",
            "Approver Approved Date",
            "Approver Rejected Date",
            "Status",
            "Remarks",
            "Repo Remarks",
            "Created By",
            "Created Date",
        ];

        type Flatten<T> = {
            [K in keyof T]: T[K] extends object ? Flatten<T[K]> : T[K];
        };

        const headerKeys: (keyof Flatten<Repo>)[] = [
            "lan_no",
            "customer_name",
            "zone",
            "region",
            "branch",
            "portfolio",
            "pos",
            "other_charges",
            "rc_no",
            "chassis_no",
            "engine_number",
            "make",
            "model",
            "mfg_year",
            "sec_17_9",
            "sec_138",
            "arbitration_status",
            "type",
            "via_sec",
            "lrn_date",
            "repo_date",
            "surrender_date",
            "is_original_rc",
            "rc_location",
            "is_empanelled_yard",
            "empanelled_yard",
            "parking_yard_address",
            "parking_yard_code",
            "yard_contact_name",
            "yard_contact_number",
            "yard_rent",
            "is_empanelled_repo_agency",
            "repo_agency_name",
            "is_additional_repo_expenses",
            "additional_expenses_reason",
            "normal_repo_expenses",
            "additional_repo_expenses",
            "revised_focus_band",
            "total_emi_collectable",
            "as_on_date_dpd",
            "total_gross_collection",
            "lrn",
            "pre_police_intimation",
            "post_police_intimation",
            "inventory",
            "surrender_letter",
            "checker",
            "checkerApprovedDate",
            "checkerRejectedDate",
            "approver",
            "approverApprovedDate",
            "approverRejectedDate",
            "status",
            "remarks",
            "repo_remarks",
            "createdBy",
            "createdAt",
        ];

        const headerRow = customHeaders;
        const exportArray = exportRepoItems.map((item) => {
            const modifiedItem = {
                ...item,
                lrn_date: formatDateToDDMMYYYY(item.lrn_date) as any,
                repo_date: formatDateToDDMMYYYY(item.repo_date) as any,
                createdAt: formatDateToDDMMYYYYWithTime(item.createdAt) as any,
                status: getStatusText(item.status),
                createdBy: item.createdBy?.name,
                checker:
                    item.checkerApproved?.name || item.checkerRejected?.name,
                approver:
                    item.approverApproved?.name || item.approverRejected?.name,
                checkerApprovedDate: formatDateToDDMMYYYYWithTime(
                    item.checkerApproved?.createdAt
                ),
                checkerRejectedDate: formatDateToDDMMYYYYWithTime(
                    item.checkerRejected?.createdAt
                ),
                approverApprovedDate: formatDateToDDMMYYYYWithTime(
                    item.approverApproved?.createdAt
                ),
                approverRejectedDate: formatDateToDDMMYYYYWithTime(
                    item.approverRejected?.createdAt
                ),
                surrender_date: formatDateToDDMMYYYY(
                    item.surrender_date
                ) as any,
                is_original_rc: item.rc_location ? "y" : "n",
            };

            return headerKeys.map((key) => modifiedItem[key]);
        });
        const ws = XLSX.utils.aoa_to_sheet([headerRow, ...exportArray]);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Repo Entries");
        XLSX.writeFile(wb, "LAN Data Export - " + new Date() + ".xlsx");
    };

    const getStatusText = (status: string): string => {
        if (status === "approved_l1") {
            return "Checker approved";
        } else if (status === "approved_l2") {
            return "Approver approved";
        }
        if (status === "rejected_l1") {
            return "Checker rejected";
        } else if (status === "rejected_l2") {
            return "Approver rejected";
        }
        return status;
    };

    return (
        <>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={modalIsOpen}
                    onHide={() => {
                        setIsOpen(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Reject Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Remark"
                            onChange={(event: any) => {
                                setRemark(event.target.value);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setIsOpen(false);
                            }}
                        >
                            Close
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                if (remark.trim().length > 0) {
                                    dispatch(
                                        updateRepoStatus({
                                            status: "rejected",
                                            remarks: remark,
                                            _id: selectedRepo._id,
                                        })
                                    );

                                    setIsOpen(false);
                                }
                            }}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div
                className="modal show"
                style={{ display: "block", position: "initial" }}
            >
                <Modal
                    show={showConfirmModal}
                    onHide={() => {
                        setShowConfirmModal(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Approve Entry</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Do you want to approve the entry?</Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => {
                                setShowConfirmModal(false);
                            }}
                        >
                            No
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                dispatch(
                                    updateRepoStatus({
                                        status: "approved",
                                        _id: selectedRepo._id,
                                    })
                                );

                                setShowConfirmModal(false);
                            }}
                        >
                            Yes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className="card">
                <div className="card-header-elements m-4 ms-auto">
                    <Select
                        onChange={handleRegionChange}
                        isMulti
                        closeMenuOnSelect={false}
                        options={regions.map((region) => ({
                            value: region,
                            label: region,
                        }))}
                        placeholder="Filter Regions"
                    />
                    {userData?.role?.rights.includes("add_repo") && (
                        <button
                            type="button"
                            onClick={navigateToAdd}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="tf-icon ti ti-plus ti-xs me-1"></span>
                            Add New Entry
                        </button>
                    )}
                    {userData?.role?.rights.includes("view_repo") && (
                        <button
                            type="button"
                            onClick={() => {
                                dispatch(fetchExportRepos({ export: true }));
                            }}
                            className="btn btn-primary waves-effect waves-light"
                        >
                            <span className="fa-solid fa-file-export me-2"></span>
                            Export
                        </button>
                    )}
                </div>
                <div
                    className="table-responsive text-nowrap"
                    style={{ overflowX: "auto" }}
                >
                    <InfiniteScroll
                        dataLength={repoItems.length}
                        next={fetchRepoData}
                        hasMore={true}
                        loader={<h4></h4>}
                    >
                        <table className="table table-striped freezeHead">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>LAN No</th>
                                    <th>Creation Date</th>
                                    <th>Customer Name</th>
                                    <th>Branch</th>
                                    <th>Repo/Surrender</th>
                                    <th>LRN Date</th>
                                    <th>Portfolio</th>
                                    <th>Region</th>
                                    {/* <th>Via Sec 17/ Sec 19</th> */}
                                    {/* <th>Original RC Availability</th> */}
                                    {/* <th>Branch or Stockyard</th> */}
                                    <th>Empanelled Yard</th>
                                    <th>Empanelled Repo Agency</th>
                                    <th>Repo Agency Name</th>
                                    <th>Additonal repo expenses amount</th>
                                    <th>Reason of Additonal repo expenses</th>
                                    <th>Normal repo expenses</th>
                                    <th>Total repo expenses</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {repoItems.map((repo: Repo, index: number) => {
                                    return (
                                        <tr key={repo._id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link
                                                    to={`/view-repo/${repo._id}`}
                                                >
                                                    {repo.lan_no}
                                                </Link>
                                            </td>
                                            <td>
                                                {formatDateToDDMMYYYYWithTime(
                                                    repo.createdAt
                                                )}
                                            </td>
                                            <td>{repo.customer_name}</td>
                                            <td>{repo.branch}</td>
                                            <th>{repo.type}</th>
                                            <td>
                                                {formatDateToDDMMYYYY(
                                                    repo.lrn_date
                                                )}
                                            </td>
                                            <td>{repo.portfolio}</td>
                                            <td>{repo.region}</td>
                                            {/* <td>{repo.via_sec}</td> */}
                                            {/* <td>
                                            {repo.rc_location?.length > 0
                                                ? "Yes"
                                                : "No"}
                                        </td> */}
                                            {/* <td>{repo.rc_location || "-"}</td> */}
                                            <td>
                                                {repo.is_empanelled_yard === "y"
                                                    ? "Yes"
                                                    : "No"}
                                            </td>
                                            <td>
                                                {repo.is_empanelled_repo_agency ===
                                                "y"
                                                    ? "Yes"
                                                    : "No"}
                                            </td>
                                            <td>{repo.repo_agency_name}</td>
                                            <td>
                                                {repo.additional_repo_expenses?.toString() ||
                                                    "-"}
                                            </td>
                                            <td>
                                                {repo.additional_expenses_reason ||
                                                    "-"}
                                            </td>
                                            <td>
                                                {repo.normal_repo_expenses.toString()}
                                            </td>
                                            <td>
                                                {repo.total_repo_expenses.toString()}
                                            </td>
                                            <td>
                                                {getStatusText(repo.status)}
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        type="button"
                                                        className="btn p-0 dropdown-toggle hide-arrow"
                                                        data-bs-toggle="dropdown"
                                                    >
                                                        <i className="ti ti-dots-vertical"></i>
                                                    </button>
                                                    <div className="dropdown-menu">
                                                        <Link
                                                            to={`/view-repo/${repo._id}`}
                                                            className="dropdown-item"
                                                        >
                                                            <i className="ti ti-pencil me-1"></i>{" "}
                                                            View
                                                        </Link>
                                                        {userData?.role?.rights.includes(
                                                            "add_repo"
                                                        ) &&
                                                            repo.status.indexOf(
                                                                "approved"
                                                            ) < 0 && (
                                                                <Link
                                                                    to={`/edit-repo/${repo._id}`}
                                                                    className="dropdown-item"
                                                                >
                                                                    <i className="ti ti-pencil me-1"></i>{" "}
                                                                    Edit
                                                                </Link>
                                                            )}
                                                        {userData?.role?.rights.includes(
                                                            "approve_repo"
                                                        ) &&
                                                            repo.status.indexOf(
                                                                "approved_l2"
                                                            ) < 0 && (
                                                                <span
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    className="dropdown-item"
                                                                    onClick={() => {
                                                                        setShowConfirmModal(
                                                                            true
                                                                        );
                                                                        setRepo(
                                                                            repo
                                                                        );
                                                                    }}
                                                                >
                                                                    <i className="ti ti-pencil me-1"></i>{" "}
                                                                    Approve
                                                                </span>
                                                            )}
                                                        {userData?.role?.rights.includes(
                                                            "approve_repo"
                                                        ) &&
                                                            repo.status.indexOf(
                                                                "approved_l2"
                                                            ) < 0 && (
                                                                <span
                                                                    style={{
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        setIsOpen(
                                                                            true
                                                                        );
                                                                        setRepo(
                                                                            repo
                                                                        );
                                                                    }}
                                                                    className="dropdown-item"
                                                                >
                                                                    <i className="ti ti-pencil me-1"></i>{" "}
                                                                    Reject
                                                                </span>
                                                            )}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </InfiniteScroll>
                </div>
            </div>
        </>
    );
};

export default RepoListing;
